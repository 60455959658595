import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import AddetailsSkeleton from "../skeleton-screens/AddetailsSkeleton";
import ADModal from '../components/ADModal';
import Adstatus from '../components/Adstatus';


function AddetailsNoEdit() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { adid, status } = state;
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [modalLocFlagged, setModalLocFlagged] = useState(false);
    const [modalLive, setModalLive] = useState(false);

    const [liveLocId, setLiveLocId] = useState('');
    const [liveZipCode, setLiveZipCode] = useState('');
    const [adDetails, setAdDetails] = useState([]);
    const [dataLocationsSelected, setDataLocationsSelected] = useState([]);
    const [dataLocationsRemoved, setDataLocationsRemoved] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [discountMsg, setDiscountMsg] = useState(null);
    const [editLocNote, setEditLocNote] = useState(null);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("Addetailsnoedit-page");
        return function cleanup() {
            document.body.classList.remove("Addetailsnoedit-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const toggleModalFlagged = () => {
        setModalLocFlagged(!modalLocFlagged);
    };

    const toggleModalLive = () => {
        setModalLive(!modalLive);
    };

    const renderLiveView = (locid, zipcode) => {
        if (status == 'active') {
            return (
                <div className="locationItemLiveViewContent" onClick={() => { setLiveLocId(locid); setLiveZipCode(zipcode); setModalLive(true) }}>
                    <i className="nc-icon nc-tv-2" />
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }}> Live</span>
                </div>
            );
        }

    }

    const renderLiveVideo = () => {
        let url = process.env.REACT_APP_LOC_STORE_URL + "/" + liveLocId + '-' + liveZipCode + '.mp4';
        //let url = process.env.REACT_APP_LOC_STORE_URL + "/19_7.webm";
        
        return (
            <video className="adDetailsImgContain" controls>
                <source src={url} type="video/mp4" />
            </video>
        );
    }

    const renderLocations = () => {
        return dataLocationsSelected.map((locData, index) => {
            return (
                <div className="locationItemNoEdit" key={index}>
                    <div className="locationItemContentNoEdit">
                        <div className="locationItemContentTopNoEdit">
                            <span>{locData.type}   ({locData.size}&Prime;)  ({locData.price})</span>
                        </div>
                        <div className="locationItemContentBottomNoEdit">
                            <span>{locData.area}, {locData.city}, {locData.zipcode}</span>
                        </div>
                    </div>
                    <div className="locationItemLiveViewNoEdit">
                        {renderLiveView(locData.locid, locData.zipcode)}
                    </div>
                </div>
            );
        });
    }

    const triggerPayment = () => {
        //navigate('/paymentcf', { state: { adid: adid } });
        navigate('/mycart', { state: { adid: adid } });
    }

    const triggerEditLoc = async () => {
        try {
            setShowSpinner(true);
            let url;
            if (adid != '') {
                url = '/editlocation/' + adid;
            } else {
                throw "Error Occurred. Please re-login";
            }
            const response = await authAxios.get(url);
            if (typeof response.data.error === 'undefined' && typeof response.data.data !== 'undefined') {
                let result = response.data;
                if (result.data) {
                    setShowSpinner(false);
                    navigate('/addetails', { state: { adid: adid, status: 'incomplete' } });                    
                } else {
                    setShowSpinner(false);
                    throw "Error Occurred. Please retry";
                }
            } else {
                setShowSpinner(false);
                throw (response.data.error === undefined) ? 'Server error. Please retry' : response.data.error;
            }
            setShowSpinner(false);
        } catch (error) {
            if (typeof error.message === 'undefined') {
                showModal(error);
            } else {
                showModal(error);
            }
        }
    }

    const renderActionButton = () => {
        if (status == 'pending payment' && dataLocationsSelected.length > 0) {
            return (
                <Button
                    className="btn-round btn-createad"
                    color="primary"
                    onClick={() => triggerPayment()}
                >
                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Proceed to cart&nbsp;</span>
                </Button>

            );
        }
        if (status == 'pending approval' || status == 'active') {
            return (
                <></>
            );
        }

    }

    const renderEditLocButton = () => {
        if (dataLocationsRemoved.length > 0) {
            return (
                    <Button
                        className="btn-round btn-createad"
                        color="primary"
                        onClick={() => triggerEditLoc()}
                    >
                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Edit Locations&nbsp;</span>
                    </Button>
            );
        }
    }

    const renderDesignCost = () => {
        if (adDetails.designcost > 0) {
            return (
                <div className="cost">
                    <div className="costLeft">
                        <span>Design Cost:&nbsp;</span>
                    </div>
                    <div className="costRight">
                        <span>{adDetails.designcost}</span>
                    </div>
                </div>
            );
        }

    }

    const editAd = (adid, status) => {
        navigate('/vceditor', { state: { adid: adid, status: status } });
    };

    const renderEdit = () => {
        if (status == 'active') {
            return (
                <div className="adDetailsEdit">
                    <div className="adDetailsEditLeft">

                        <Button
                            className="btn-round btn-createad"
                            color="primary"
                            onClick={() => editAd(adid, status)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Edit&nbsp;</span>
                        </Button>
                    </div>
                    <div className="adDetailsEditRight"></div>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    const renderRemovedLocationsText = () => {
        if (typeof dataLocationsRemoved !== 'undefined' && dataLocationsRemoved.length > 0) {
            return (
                <Row>
                    <Col className="ml-auto mr-auto">
                        <div className="adDetailsReason" onClick={() => setModalLocFlagged(true)}>
                            <p style={{ textAlign: "justify" }}>
                                <span>Few locations you selected are already running at full capacity as of now. Please &nbsp;</span>
                                <span style={{ textDecorationLine: "underline", fontWeight: "bold", color: "#4d79ff", cursor: 'pointer' }}>click here</span>
                                <span>&nbsp;to see the locations that were removed. Your cost is adjusted accordingly with applicable discount for your chosen duration.</span>
                            </p>

                        </div>
                    </Col>
                </Row>
            );
        }
    };

    const renderRemovedLocations = () => {
        return dataLocationsRemoved.map((locData, index) => {
            return (
                <div className="locationItemSelected" key={index}>
                    <div className="locationItemSelectedContent">
                        <div className="locationItemSelectedtTop">
                            <span>{locData.type}   ({locData.size}&Prime;)  ({locData.price})</span>
                        </div>
                        <div className="locationItemSelectedBottom">
                            <span>{locData.area}, {locData.city}, {locData.zipcode}</span>
                        </div>
                        <div className="locationItemSelectedBottom">
                            <p style={{paddingTop:'3px'}}>
                                <span style={{fontSize: 'small', color: '#767676', fontWeight: '500' }}>Next available date:&nbsp; 
                                    <span style={{fontSize: 'small', color: '#b33c12', fontWeight: '500' }}>{locData.avldate}</span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="locationItemDelete">

                    </div>
                </div>
            );

        });
    };

    const renderThumbnail = () => {
        if (adDetails.adtype == "img") {
            return (
                <img
                    src={adDetails.img ? adDetails.img : adDetails.vista_img}
                    className="adDetailsImgContain"
                />

            );
        }
        if (adDetails.adtype == "video") {
            let srcType = "video/mp4";
            // if (adDetails.img == null) {
            //     srcType = "video/mp4";
            // }
            return (
                <video className="adDetailsImgContain" controls muted>
                    <source src={adDetails.img ? adDetails.img : adDetails.vista_img} type={srcType} />
                </video>

            );
        }
    };

    const renderThumbnailNew = () => {
        if (adDetails.adtype == "img") {
            return (
                <img
                    src={adDetails.new_img ? adDetails.new_img : adDetails.vista_new_img}
                    className="adDetailsImgContain"
                />
            );
        }
        if (adDetails.adtype == "video") {
            let srcType = "video/mp4";
            // if (adDetails.new_img == null) {
            //     srcType = "video/mp4";
            // }
            return (
                <video className="adDetailsImgContain" controls>
                    <source src={adDetails.new_img ? adDetails.new_img : adDetails.vista_new_img} type={srcType} />
                </video>
            );
        }
    };

    const renderPendingApprovalDesign = () => {
        if (adDetails.new_img) {
            return (
                <Row>
                    <Col className="ml-auto mr-auto">
                        <div className="adDetailsContainer">
                            <div className="adDetailsTitle">
                                <p>Below design will be active and displayed on screens after approval</p>
                            </div>
                            <div className="adDetailsImg">
                                {renderThumbnailNew()}
                            </div>
                            <div className="adDetailsImgWidget">
                                <div className="adDetailsImgWidgetWeather">
                                    <span style={{ color: "#ffffff" }}>Mumbai 25&deg;C, Partly Cloudy</span><i style={{ color: "#fff", paddingLeft: "5px", marginTop: "3px" }} className="nc-icon nc-cloud-upload-94" />
                                </div>
                                <div className="adDetailsImgWidgetTime">
                                    <span style={{ color: "#ffffff" }}>Jun 5, 2023 4:00 Pm</span>
                                </div>

                            </div>
                            <div className="adDetailsStatus">
                                <Adstatus status={adDetails.activeedit} />
                            </div>
                        </div>
                    </Col>
                </Row>
            );

        }

    }

    const renderExpiryDate = () => {
        if (status == 'active') {
            return adDetails.expirydate;
        } else {
            return '';
        }        
    }

    const fetchData = async () => {
        try {
            
            setIsLoading(true);
            let url;
            if (adid != '') {
                url = '/locationnoedit/ad/' + adid;
            } else {
                throw "Error Occurred. Please re-login";
            }
            const response = await authAxios.get(url);
            var responseData = [];
            let result = response.data;

            if (result.data) {
                responseData = result.data;

                let adDetailsObject = (responseData.adDetails) ? responseData.adDetails : [];
                let numDays = 0;

                if (adDetailsObject.durationtype == 'days' || adDetailsObject.durationtype == 'weeks' || adDetailsObject.durationtype == 'months') {
                    if (adDetailsObject.duration != '') {
                        numDays = adDetailsObject.duration;
                    }
                    if (adDetailsObject.durationtype == 'weeks' && numDays > 0) {
                        numDays = 7 * numDays;
                    } else if (adDetailsObject.durationtype == 'months' && numDays > 0) {
                        numDays = 30 * numDays;
                    }
                }
                let discmsg = '';
                if (numDays >= 30) {
                    discmsg = '25% discount applied';
                } else if (numDays >= 15) {
                    discmsg = '15% discount applied';
                } else if (numDays >= 7) {
                    discmsg = '10% discount applied';
                } else {
                    discmsg = '';
                }
                if (adDetailsObject.cost <= 0) {
                    discmsg = '';
                }

                setAdDetails(adDetailsObject);
                setDataLocationsSelected(responseData.locations);
                setDataLocationsRemoved(responseData.locationsremoved);
                if (status == 'pending payment' && responseData.locationsremoved.length > 0) {
                    setEditLocNote('Note: On edit locations you need to go through whole approval process again');
                }

                setUserDetails(responseData.userDetails);
                setDiscountMsg(discmsg);

            } else {
                throw "Server error. Unable to fetch data";
            }

            setIsLoading(false);
        } catch (error) {
            
            showModal("Server Error. Please refresh or relogin");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <AddetailsSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            {!isLoading && (
                                <>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="adDetailsContainer">
                                                <div className="adDetailsTitle">
                                                    <h4>{adDetails.name}</h4>
                                                </div>
                                                {renderEdit()}
                                                <div className="adDetailsImg">
                                                    {renderThumbnail()}
                                                </div>
                                                <div className="adDetailsImgWidget">
                                                    <div className="adDetailsImgWidgetWeather">
                                                        <span style={{ color: "#ffffff" }}>Mumbai 25&deg;C, Partly Cloudy</span><i style={{ color: "#fff", paddingLeft: "5px", marginTop: "3px" }} className="nc-icon nc-cloud-upload-94" />
                                                    </div>
                                                    <div className="adDetailsImgWidgetTime">
                                                        <span style={{ color: "#ffffff" }}>Jun 5, 2023 4:00 Pm</span>
                                                    </div>

                                                </div>
                                                <div className="adDetailsStatus">
                                                    <Adstatus status={status} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {renderPendingApprovalDesign()}
                                    {renderRemovedLocationsText()}
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="noEditDuration">
                                                <span style={{ fontWeight: "bold" }}>Duration:&nbsp;&nbsp;</span><span>{adDetails.duration} {adDetails.durationtype}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="noEditDuration">
                                                <span style={{ fontWeight: "bold" }}>Ad expires on <span style={{ fontWeight: "normal" }}>(yyyy-mm-dd)</span>:&nbsp;&nbsp;</span><span>{renderExpiryDate()}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="noEditLocationContainer">
                                                <div style={{ marginBottom: "20px" }}>
                                                    <span style={{ fontWeight: "bold" }}>Locations: </span>
                                                </div>
                                                <div className="noEditLocations">
                                                    {renderLocations()}
                                                    <div className="editLocNote">
                                                        <p><span style={{ color: "#b33c12" }}></span>&nbsp;{editLocNote}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Modal isOpen={modalLocFlagged} centered={true} toggle={toggleModalFlagged}>
                                        <div className="modal-header">
                                            <button
                                                aria-label="Close"
                                                className="close"
                                                type="button"
                                                onClick={toggleModalFlagged}
                                            >
                                                <span aria-hidden={true}>×</span>
                                            </button>
                                            <h5
                                                className="modal-title text-center"
                                                id="exampleModalLabel"
                                            >
                                                Occupied Locations
                                            </h5>
                                        </div>
                                        <div className="modal-body">
                                            <div style={{ marginBottom: "5px" }}>
                                                <span>Belows locations are at full capacity now and removed from your selection</span>
                                            </div>
                                            {renderRemovedLocations()}
                                        </div>
                                    </Modal>
                                    <Modal isOpen={modalLive} centered={true} toggle={toggleModalLive} size="xl">
                                        <div className="modal-header">
                                            <button
                                                aria-label="Close"
                                                className="close"
                                                type="button"
                                                onClick={toggleModalLive}
                                            >
                                                <span aria-hidden={true}>×</span>
                                            </button>
                                            <h5
                                                className="modal-title text-center"
                                                id="exampleModalLabel"
                                            >
                                                Live feed - gets updated every 15 minutes. <br/>Outside business hours when displays could be turned off you might experience a distorted feed.
                                            </h5>
                                        </div>
                                        <div className="modal-body">
                                            <div className="adDetailsContainer">
                                                <div className="adDetailsImg">
                                                    {renderLiveVideo()}
                                                </div>
                                            </div>

                                        </div>
                                    </Modal>
                                    <div className="AddetailsSubmitContainer">
                                        <div className="submitContainerLeft">
                                            {renderActionButton()}
                                            {renderEditLocButton()}
                                        </div>
                                        <div className="submitContainerRight">
                                            <div className="impressions">
                                                <div className="impressionsLeft">
                                                    <span>Estimated <br />Impressions:&nbsp;</span>
                                                </div>
                                                <div className="impressionsRight">
                                                    <span>{adDetails.estimatedImpressions} - {adDetails.estimatedImpressions * 6}</span>
                                                </div>
                                            </div>
                                            <div className="cost">
                                                <div className="costLeft">
                                                    <span>Subtotal:&nbsp;</span>
                                                </div>
                                                <div className="costRight">
                                                    <span>{adDetails.cost}</span>
                                                </div>
                                            </div>
                                            {renderDesignCost()}
                                            <div className="discountMsg">
                                                <span style={{ color: '#44c47d' }}>{discountMsg}</span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />
        </>
    );

}

export default AddetailsNoEdit;