import { motion } from "framer-motion";
import Skeleton from "../components/Skeleton";
import Shimmer from "../components/Shimmer";

const AddetailsSkeleton = ({ ...props }) => {
    return (
        <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="skeleton-wrapper"
            {...props}
        >
        <div className="skeleton-center">
            <h2 className="skeleton-section-title-addetail">
                <Skeleton type="title" />
            </h2>
            <Skeleton type="div" />
                <Skeleton type="text" />
                <Skeleton type="title" />
                
                <Skeleton type="title" />
                
            </div>
            <Shimmer />
        </motion.section>
    );
};
export default AddetailsSkeleton;
