import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../skeleton-screens/HomeSkeleton";
import ADModal from '../components/ADModal';

function VcEditor() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { adid, status } = state;
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const [designId, setDesignId] = useState(null);
    const [lvcMsg, setLvcMsg] = useState('');


    const api = useRef([]);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("CreateAd-page");
        return function cleanup() {
            document.body.classList.remove("CreateAd-page");
        };
    });


    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const savead = async (dataVc) => {
        try {
            
            setIsLoading(true);
            if (!adid) {
                let url = '/ads/createad';
                let data = {
                    data: {
                        url: dataVc.url,
                        format: dataVc.format,
                        designid: dataVc.id,
                        designtype: dataVc.designType,
                        extension: dataVc.extension
                    }
                };
                const response = await authAxios.post(url, data);

                let result = response.data;
                
                if (result?.data) {
                    setShowSpinner(false);
                    if (result.data['status'] == 'error') {
                        throw result.data['reason'];
                    } else {
                        await api.current.close();
                        navigate('/addetails', { state: { adid: result.data['adid'], status: result.data['status'] } });
                    }

                } else {
                    throw "Server error. Please resubmit";
                }
            } else {
                let url = '/ads/updateadeditad';
                let dataPost = '';
                if (status == "active") {
                    dataPost = {
                        data: {
                            adid: adid,
                            url: dataVc.url,
                            format: dataVc.format,
                            newdesignid: dataVc.id,
                            designtype: dataVc.designType,
                            extension: dataVc.extension
                        }
                    };
                } else {
                    dataPost = {
                        data: {
                            adid: adid,
                            url: dataVc.url,
                            format: dataVc.format,
                            designid: dataVc.id,
                            designtype: dataVc.designType,
                            extension: dataVc.extension
                        }
                    };
                }

                const response = await authAxios.post(url, dataPost);

                let result = response.data;
                
                if (result.data == 'success') {
                    if (status == "active") {
                        setShowSpinner(false);
                        await api.current.close();
                        navigate('/addetailsnoedit', { state: { adid: adid, status: status } });
                    } else {
                        setShowSpinner(false);
                        await api.current.close();
                        
                        navigate('/addetails', { state: { adid: adid, status: status } });
                    }

                } else {
                    throw (response.data.error === undefined) ? 'Server error. Please resubmit' : response.data.error;
                }
            }

            setIsLoading(false);
        } catch (error) {
            
            await api.current.close();
            if (typeof error.message === 'undefined') {
                showModal(error);
            } else {
                showModal(error);
            }
            setIsLoading(false);
        }
    }

    const errorEditor = (data) => {
        
    }

    const fetchData = async () => {
        
        try {
            setIsLoading(true);
            const responseUser = await authAxios.get('/user');
            let resultUser = responseUser.data;
            let vcky = null;
            let vcprefix = null;
            let designid = null;
            let allowAccess = 'yes';
            
            if (resultUser?.data) {
                vcky = atob(resultUser?.data?.vck);
                vcprefix = resultUser?.data?.vcprefix;
                allowAccess = resultUser?.data?.allow;
            } else {
                throw "Server error. Unable to fetch data";
            }
            
            if (adid) {
                let url = '/ad/' + adid;
                const response = await authAxios.get(url);
                var responseData = '';
                let result = response.data;
                
                if (result.data) {
                    designid = result.data.designid;
                    if (result.data.status == 'active') {
                        allowAccess = 'yes';
                    }
                    setDesignId(designid);
                } else {
                    throw "Server error. Unable to fetch data";
                }
            }
            setIsLoading(false);
            
            if (allowAccess == 'yes') {
                let ap = launchEditor(designid, vcky, vcprefix);
            } else {
                setLvcMsg("We aplogize for the inconvenience. Your access to graphic editor is blocked. Please contact us at support@adkewl.com or +919618455334 to get unblocked.");
            }
            
        } catch (error) {
            showModal(error);
            setIsLoading(false);
        }

    };

    useEffect(() => {
        fetchData();
        // return async () => {
        //     if (api.current) {
        //         await api.current.close();
        //     }
        // }
    }, []);


    const launchEditor = async (designid, vck, vcuid) => {
        
            if (!adid) {
                const apid = await window.VistaCreateEditor.init({
                    apiKey: vck,
                    designType: 'fbEventCoverHC',
                    user: {
                        externalUserId: vcuid
                    },
                    projectName: 'Adkewl',
                    onPublishAction: savead,
                    onWarningAction: errorEditor
                });
                api.current = apid;
                
                //api.current.close();
                
            } else {
                if (status == "active") {
                    
                    const apid = await window.VistaCreateEditor.init({
                        apiKey: vck,
                        designIdToCopy: designid,
                        designType: 'fbEventCoverHC',
                        user: {
                            externalUserId: vcuid
                        },
                        projectName: 'Adkewl',
                        onPublishAction: savead,
                        onWarningAction: errorEditor
                    });
                    api.current = apid;
                    
                } else {
                    const apid = await window.VistaCreateEditor.init({
                        apiKey: vck,
                        designId: designid,
                        designType: 'fbEventCoverHC',
                        user: {
                            externalUserId: vcuid
                        },
                        projectName: 'Adkewl',
                        onPublishAction: savead,
                        onWarningAction: errorEditor
                    });
                    api.current = apid;
                    
                }

            }
    };

    const closeEditor = () => {
        
        //api.current.close();
        navigate('/home', { replace: true });
    };



    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <HomeSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            <Row>
                                {!isLoading && (
                                    <Col className="ml-auto mr-auto">
                                        {/* <div style={{zIndex: '10001', position: 'fixed', top: '5px', left: '10px'}}>
                                            <Button
                                                className="btn-round"
                                                color="primary"
                                                onClick={() => closeEditor()}
                                            >
                                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Close&nbsp;</span><i className="nc-icon nc-simple-remove"></i>
                                            </Button>
                                            
                                        </div> */}
                                        <div style={{ marginTop: '50px', textAlign:"justify"}}>
                                            <p>
                                                {lvcMsg}
                                            </p>
                                        </div>
                                        <div style={{ marginTop: '100px' }}>
                                            <Button
                                                className="btn-round"
                                                color="primary"
                                                onClick={() => closeEditor()}
                                            >
                                                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Home&nbsp;</span>
                                            </Button>
                                        </div>
                                        {/* {launchEditor()} */}
                                    </Col>

                                )}
                            </Row>
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default VcEditor;
