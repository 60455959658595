import React, { createContext, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const AxiosContext = createContext();
const { Provider } = AxiosContext;

const AxiosProvider = ({ children }) => {
    const authContext = useContext(AuthContext);

    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    const publicAxios = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    authAxios.interceptors.request.use(
        config => {
            if (!config.headers.Authorization) {
                config.headers.Authorization = `Bearer ${authContext.getAccessToken()}`;
            }

            return config;
        },
        error => {
            return Promise.reject(error);
        },
    );

    const refreshAuthLogic = failedRequest => {
        const data = {
            data: {
                type: 'web',
            }
        };
        const options = {
            method: 'POST',
            data,
            url: process.env.REACT_APP_API_BASE_URL + "/newtoken",
            withCredentials: true,
            headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' }
        };
        
        return axios(options)
            .then(async tokenRefreshResponse => {
                failedRequest.response.config.headers.Authorization =
                    'Bearer ' + tokenRefreshResponse.data.data.accesstoken;

                authContext.setAuthState({
                    ...authContext.authState,
                    accessToken: tokenRefreshResponse.data.data.accesstoken,
                });
                let obj = {
                    accessToken: tokenRefreshResponse.data.data.accesstoken,
                    authenticate: true
                };
                localStorage.setItem('adyantraat', JSON.stringify(obj));

                
                return Promise.resolve();
            })
            .catch(e => {
                
                authContext.setAuthState({
                    accessToken: null,
                });
                authContext.logout();
            });
    };

    createAuthRefreshInterceptor(authAxios, refreshAuthLogic, {});

    return (
        <Provider
            value={{
                authAxios,
                publicAxios,
            }}>
            {children}
        </Provider>
    );
};

export { AxiosContext, AxiosProvider };