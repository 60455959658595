import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    CustomInput,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../../AuthContext';
import { AxiosContext } from '../../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../../skeleton-screens/HomeSkeleton";
import ADModal from '../../components/ADModal';
import Select from 'react-select';

function Admin() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);


    const [dataAdUserDetails, setDataAdUserDetails] = useState([]);
    const [dataStatusDropdown, setDataStatusDropdown] = useState([]);
    const [valueStatusDropdown, setValueStatusDropdown] = useState(null);
    const [dataPhoneDropdown, setDataPhoneDropdown] = useState([]);
    const [valuePhoneDropdown, setValuePhoneDropdown] = useState(null);
    const [dataEmailDropdown, setDataEmailDropdown] = useState([]);
    const [valueEmailDropdown, setValueEmailDropdown] = useState(null);
    let dataAdsFromServer = useRef([]);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("Admin-page");
        return function cleanup() {
            document.body.classList.remove("Admin-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const setvaluePhoneDropdownFunc = (value) => {
        setValuePhoneDropdown(value);
    }

    const setvalueEmailDropdownFunc = (value) => {
        setValueEmailDropdown(value);
    }

    const setvalueStatusDropdownFunc = (value) => {
        setValueStatusDropdown(value);
    }

    const clearDropdowns = () => {
        setValuePhoneDropdown(null);
        setValueEmailDropdown(null);
        setValueStatusDropdown(null);
        setDataAdUserDetails(dataAdsFromServer.current);
    }

    const navigateToAddetails = (Adid, Status) => {
        navigate('/adminad', { state: { adid: Adid, status: Status } });
    };

    const goToAdminDesign = () => {
        navigate('/admindesign');
    }

    const goToUnblockLaunchVc = () => {
        navigate('/adminunblockvc');
    }

    const searchAds = (filter) => {
        let data = dataAdsFromServer.current.filter(item => {
            for (let key in filter) {
                if (filter[key] !== null && filter[key] == 'pending approval' && item['new_designid'] !== null) {
                    return true;
                }
                if (filter[key] !== null && item[key] != filter[key]) {
                    return false;
                }

            }
            return true;
            //item.city == filter.city
        });

        setDataAdUserDetails(data);
    }

    const convertDate = (dataDate) => {
        if (dataDate) {
            const utcDate = new Date(dataDate);
            const myLocalDate = new Date(Date.UTC(
                utcDate.getFullYear(),
                utcDate.getMonth(),
                utcDate.getDate(),
                utcDate.getHours(),
                utcDate.getMinutes()
            ));
            return myLocalDate.toLocaleString("en-IN");
        } else {
            return '';
        }
        
    }

    const renderAds = () => {
        return dataAdUserDetails.map((adData, index) => {
            
            return (
                <div className="AdItem" key={adData.adid}>
                    <div className="AdItemContentTop">
                        <div className="AdItemContentTopItems">
                            <span style={{ fontWeight: "bold" }}>Ad Name: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.name} </span>
                        </div>
                        <div className="AdItemContentTopItems">
                            <span style={{ fontWeight: "bold" }}>Status: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.status} </span>
                        </div>
                        <div className="AdItemContentTopItems">
                            <span style={{ fontWeight: "bold" }}>Created Date: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{convertDate(adData.created)} </span>
                        </div>
                        <div className="AdItemContentTopItems">
                            <span style={{ fontWeight: "bold" }}>Modified Date: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{convertDate(adData.modified)} </span>
                        </div>
                    </div>
                    <div className="AdItemContentMiddle">
                        <div className="AdItemContentMiddleItems">
                            <span style={{ fontWeight: "bold" }}>User: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.firstname}&nbsp;{adData.lastname} </span>
                        </div>
                        <div className="AdItemContentMiddleItems">
                            <span style={{ fontWeight: "bold" }}>Email: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.email} </span>
                        </div>
                        <div className="AdItemContentMiddleItems">
                            <span style={{ fontWeight: "bold" }}>Phone: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.phone} </span>
                        </div>
                        <div className="AdItemContentMiddleItems">
                            <span style={{ fontWeight: "bold" }}></span>
                        </div>
                    </div>
                    <div className="AdItemContentBottom">
                        <div className="AdItemContentBottomItemsLeft">
                            <span style={{ fontWeight: "normal", fontSize: 10 }}>{index + 1} </span>
                        </div>
                        <div className="AdItemContentBottomItemsRight">
                            <Button
                                className="btn-round"
                                color="primary"
                                type="button"
                                onClick={() => navigateToAddetails(adData.adid, adData.status)}
                            >
                                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; View... &nbsp;&nbsp;</span>
                            </Button>
                        </div>

                    </div>
                </div>

            );
        });
    };
    const fetchData = async () => {
        try {
            
            setIsLoading(true);
            let url = '/adminads';

            const response = await authAxios.get(url);
            var responseData = [];
            let result = response.data;
            
            if (result.data) {
                responseData = result.data;
                setDataEmailDropdown(responseData.dateEmailDropdown);
                setDataPhoneDropdown(responseData.dataPhoneDropdown);
                setDataStatusDropdown(responseData.dataStatusDropdown);
                dataAdsFromServer.current = responseData.aduserDetails;
                setDataAdUserDetails(responseData.aduserDetails);

            } else {
                throw "Server error. Unable to fetch data";
            }

            setIsLoading(false);
        } catch (error) {
            showModal("Server Error. Please refresh or relogin");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <HomeSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            {!isLoading && (
                                <Row>
                                    <Col className="ml-auto mr-auto">
                                        <div className="AdminHomeContainer">
                                            <div className="AdminHomeFilterDropdowns">
                                                <Select
                                                    className="reactSelectDropdown"
                                                    classNamePrefix="reactSelectDropdownData"
                                                    value={valueEmailDropdown}
                                                    onChange={setvalueEmailDropdownFunc}
                                                    options={dataEmailDropdown}
                                                    isSearchable={true}
                                                    placeholder="Email"
                                                />
                                                <Select
                                                    className="reactSelectDropdown"
                                                    classNamePrefix="reactSelectDropdownData"
                                                    value={valuePhoneDropdown}
                                                    onChange={setvaluePhoneDropdownFunc}
                                                    options={dataPhoneDropdown}
                                                    isSearchable={true}
                                                    placeholder="Phone"
                                                />
                                                <Select
                                                    className="reactSelectDropdown"
                                                    classNamePrefix="reactSelectDropdownData"
                                                    value={valueStatusDropdown}
                                                    onChange={setvalueStatusDropdownFunc}
                                                    options={dataStatusDropdown}
                                                    isSearchable={true}
                                                    placeholder="Status"
                                                />
                                            </div>
                                            <div className="AdminHomeFilterButtons">
                                                <div style={{ width: "20%" }}>
                                                    <Button
                                                        className="btn-round btn-createad"
                                                        color="primary"
                                                        onClick={() => searchAds({
                                                            "email": valueEmailDropdown ? valueEmailDropdown.value : null,
                                                            "phone": valuePhoneDropdown ? valuePhoneDropdown.value : null,
                                                            "status": valueStatusDropdown ? valueStatusDropdown.value : null
                                                        })}
                                                    >
                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Filter&nbsp;</span>
                                                    </Button>
                                                </div>
                                                <div style={{ width: "20%", marginLeft: "10px" }}>
                                                    <Button
                                                        className="btn-round btn-createad"
                                                        color="primary"
                                                        onClick={() => clearDropdowns()}
                                                    >
                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Clear&nbsp;</span>
                                                    </Button>
                                                </div>

                                            </div>
                                            <div className="AdminHomeCreateDesignButton">
                                                <Button
                                                    className="btn-round"
                                                    color="primary"
                                                    onClick={() => goToAdminDesign()}
                                                >
                                                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Create Design For User&nbsp;</span>
                                                </Button>
                                            </div>
                                            <div className="AdminHomeUnblockButton">
                                                <Button
                                                    className="btn-round"
                                                    color="primary"
                                                    onClick={() => goToUnblockLaunchVc()}
                                                >
                                                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Unblock Launch Editor&nbsp;</span>
                                                </Button>
                                            </div>
                                            <div className="AdminAdDetails">
                                                {renderAds()}
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default Admin;
