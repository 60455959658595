/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Container
} from "reactstrap";

// core components

function LoggedinHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header-other"
        style={{
          backgroundImage:
            "url(" + require("../../assets/img/adkewl-banner-bkgd-dark.png") + ")",
        }}
        data-parallax={true}
        ref={pageHeader}
      />
    </>
  );
}

export default LoggedinHeader;
