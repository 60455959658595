/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function LoggedinFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          
          <div className="credits loggedinCredits">
            <span className="copyright">
              © {new Date().getFullYear()}, Adkewl Digital Signage Pvt. Ltd.
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default LoggedinFooter;
