import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, json } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../../AuthContext';
import { AxiosContext } from '../../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import AddetailsSkeleton from "../../skeleton-screens/AddetailsSkeleton";
import ADModal from '../../components/ADModal';
import Adstatus from '../../components/Adstatus';

function AdminAd() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const { state } = useLocation();
    const { adid, status } = state;
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [modalButtonStatus, setModalButtonStatus] = useState('');

    const [estimatedImpressions, setEstimatedImpressions] = useState(0);
    const [cost, setCost] = useState(0);
    const [estimatedImpressionsReCalculated, setEstimatedImpressionsReCalculated] = useState(0);
    const [costReCalculated, setCostReCalculated] = useState(0);
    const [adDetails, setAdDetails] = useState([]);
    const [dataLocationsSelected, setDataLocationsSelected] = useState([]);
    const [dataLocationsFlagged, setDataLocationsFlagged] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [pmtDetails, setPmtDetails] = useState([]);
    const [pmtHistory, setPmtHistory] = useState([]);
    const [reason, setReason] = useState(''),
        onReasonInput = ({ target: { value } }) => setReason(value);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    let estimatedImpressionsServer = useRef([]);
    let costServer = useRef([]);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("AdminAd-page");
        return function cleanup() {
            document.body.classList.remove("AdminAd-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg, status) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
        setModalButtonStatus(status);
    };

    const handleModal = () => {
        if (modalButtonStatus == "success") {
            showModal('','');
            navigate('/admin');
        } else {
            showModal('','');
        }
    }

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const renderThumbnail = (url) => {
        if (adDetails.adtype == "img") {
            return (
                <img
                    src={url}
                    className="adDetailsImgContain"
                />
            );
        }
        if (adDetails.adtype == "video") {
            return (
                <video className="adDetailsImgContain" controls>
                    <source src={url} type="video/mp4" />
                </video>
            );
        }
    };

    const renderPendingApprovalDesign = () => {
        if (adDetails.new_img) {
            return (
                <Row>
                    <Col className="ml-auto mr-auto">
                        <div className="adDetailsContainer">
                            <div className="adDetailsTitle">
                                <p>Below design will be active and displayed on screens after approval</p>
                            </div>
                            <div className="adDetailsImg">
                                {renderThumbnail(adDetails.new_img)}
                            </div>
                            <div className="adDetailsImgWidget">
                                <div className="adDetailsImgWidgetWeather">
                                    <span style={{ color: "#ffffff" }}>Mumbai 25&deg;C, Partly Cloudy</span><i style={{ color: "#fff", paddingLeft: "5px", marginTop: "3px" }} className="nc-icon nc-cloud-upload-94" />
                                </div>
                                <div className="adDetailsImgWidgetTime">
                                    <span style={{ color: "#ffffff" }}>Jun 5, 2023 4:00 Pm</span>
                                </div>

                            </div>
                            <div className="adDetailsStatus">
                                <Adstatus status={adDetails.activeedit} />
                            </div>
                        </div>
                    </Col>
                </Row>
            );

        }

    }

    const renderActionButton = () => {
        if (status == 'pending approval' || (status == 'active' && adDetails.new_designid !== null)) {
            return (
                <>
                    <Button
                        className="btn-round"
                        color="primary"
                        onClick={() => handleSubmit('approve')}
                    >
                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Approve&nbsp;</span>
                    </Button>


                    <Button
                        className="btn-round"
                        color="primary"
                        onClick={() => handleSubmit('notapprove')}
                    >
                        <span style={{ color: '#d73925', textTransform: 'none' }}>&nbsp;Not Approved&nbsp;</span>
                    </Button>
                </>

            );
        } else {
            return (
                <></>
            );
        }

    }

    const convertDate = (dataDate) => {
        if (dataDate) {
            const utcDate = new Date(dataDate);
            const myLocalDate = new Date(Date.UTC(
                utcDate.getFullYear(),
                utcDate.getMonth(),
                utcDate.getDate(),
                utcDate.getHours(),
                utcDate.getMinutes()
            ));
            return myLocalDate.toLocaleString("en-IN");
        } else {
            return '';
        }
        
    }

    const renderPaymentHistory = () => {
        if (pmtHistory) {
            return pmtHistory.map((pmtData, index) => {
                return (
                    <div className="AdItem" key={index}>
                        <div className="AdItemContentTop">
                            <div className="AdItemContentTopItems">
                                <span style={{ fontWeight: "bold" }}>AdOrderId: &nbsp;</span>
                                <span style={{ fontWeight: "normal" }}>{pmtData?.adorderid} </span>
                            </div>
                            <div className="AdItemContentTopItems">
                                <span style={{ fontWeight: "bold" }}>CfOrderId: &nbsp;</span>
                                <span style={{ fontWeight: "normal" }}>{pmtData?.cforderid}</span>
                            </div>
                            <div className="AdItemContentTopItems">
                                <span style={{ fontWeight: "bold" }}>Status: &nbsp;</span>
                                <span style={{ fontWeight: "normal" }}>{pmtData?.status}</span>
                            </div>
                        </div>
                        <div className="AdItemContentMiddle">
                            <div className="AdItemContentMiddleItems">
                                <span style={{ fontWeight: "bold" }}>Amount: &nbsp;</span>
                                <span style={{ fontWeight: "normal" }}>{pmtData?.amt}</span>
                            </div>
                            <div className="AdItemContentMiddleItems">
                                <span style={{ fontWeight: "bold" }}>Created: &nbsp;</span>
                                <span style={{ fontWeight: "normal" }}>{convertDate(pmtData?.created)}</span>
                            </div>
                            <div className="AdItemContentMiddleItems">
                                <span style={{ fontWeight: "bold" }}>Modified: &nbsp;</span>
                                <span style={{ fontWeight: "normal" }}>{convertDate(pmtData?.modified)}</span>
                            </div>
                        </div>
                    </div>

                );
            });
        }

    };

    const handleSubmit = async (submitType) => {
        try {
            setShowSpinner(true);
            let url = '/adminadsubmit';
            let data = {
                data: {
                    adid: adid,
                    status: status,
                    cost: cost,
                    impressions: estimatedImpressions,
                    reason: reason,
                    locationsflagged: dataLocationsFlagged,
                    type:submitType
                }
            };
            
            const response = await authAxios.post(url, data);

            let result = response.data;
            
            if (result.data) {
                setShowSpinner(false);
                if (result.data['status'] == "success") {                    
                    showModal("Updated Successfully", "success");
                } else if(result.data['status'] == "error") {
                    throw result.data['reason'];
                }
                
            } else {
                throw "Server error. Please resubmit";
            }
        } catch (error) {
            if (typeof error.message === 'undefined') {
                showModal(error, "fail");
            } else {
                showModal(error.message, "fail");
            }
            setShowSpinner(false);
        }
    };

    const calculator = () => {
        let numLocations = dataLocationsFlagged.length;
        let numDays = 0;
        if (adDetails.duration != '') {
            numDays = adDetails.duration;
        }
        if (adDetails.durationtype == "weeks") {
            numDays = 7 * numDays;
        } else if (adDetails.durationtype == "months") {
            numDays = 30 * numDays;
        }
        let impressions = numLocations * 420 * numDays;
        let locTotal = dataLocationsFlagged.reduce((n, { price }) => n + price, 0)
        
        let actualcostT = locTotal * numDays;
        let costCalc = actualcostT;
        
        if (numDays >= 30) {
            costCalc = actualcostT - (actualcostT * 0.25);
        } else if (numDays >= 15) {
            costCalc = actualcostT - (actualcostT * 0.15);
        } else if (numDays >= 7) {
            costCalc = actualcostT - (actualcostT * 0.1);
        } else {}

        let newImpressions = estimatedImpressionsServer.current - impressions;
        let newCost = costServer.current - costCalc;
        setEstimatedImpressions(newImpressions);
        setCost(newCost);
    };

    const recalculateCost = (locationsSelected, adDet) => {
        let numLocations = locationsSelected.length;
        let numDays = 0;
        if (adDet.duration != '') {
            numDays = adDet.duration;
        }
        if (adDet.durationtype == "weeks") {
            numDays = 7 * numDays;
        } else if (adDet.durationtype == "months") {
            numDays = 30 * numDays;
        }
        let impressions = numLocations * 420 * numDays;
        let locTotal = locationsSelected.reduce((n, { price }) => n + price, 0)
        
        let actualcostT = locTotal * numDays;
        let costT = actualcostT;
        
        if (numDays >= 30) {
            costT = actualcostT - (actualcostT * 0.25);
        } else if (numDays >= 15) {
            costT = actualcostT - (actualcostT * 0.15);
        } else if (numDays >= 7) {
             costT = actualcostT - (actualcostT * 0.1);
        } else {}
        
        setEstimatedImpressionsReCalculated(impressions);
        setCostReCalculated(costT);
    };

    const onCheckChanged = (id) => {
        const data = dataLocationsSelected;
        let dataFlagged = dataLocationsFlagged;

        const index = data.findIndex(x => x.locid === id);
        data[index].checked = !data[index].checked;

        if (data[index].checked) {
            const checkLocId = obj => obj.locid == data[index].locid;
            if (!dataFlagged.some(checkLocId)) {
                dataFlagged.push(data[index]);
            }
        }

        if (!data[index].checked) {
            let indexDataSelected = dataFlagged.findIndex(x => x.locid === id);
            dataFlagged.splice(indexDataSelected, 1);
        }

        
        setDataLocationsSelected(data);
        setDataLocationsFlagged(dataFlagged);
        calculator();
        forceUpdate();

    };

    const renderLocations = () => {
        return dataLocationsSelected.map((locData, index) => {
            return (
                <div className="locationItem" key={index}>
                    {(status == 'pending approval') && (
                        <div className="locationItemCheckbox">
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" checked={locData.checked} onChange={() => onCheckChanged(locData.locid)} /><span className="form-check-sign" />
                                </Label>
                            </FormGroup>
                        </div>
                    )}
                    <div className="locationItemContent">
                        <div className="locationItemContentTop">
                            <span>{locData.type}   ({locData.grade})  ({locData.size}&Prime;)  ({locData.price})</span>
                        </div>
                        <div className="locationItemContentBottom">
                            <span>{locData.area}, {locData.city}, {locData.zipcode}</span>
                        </div>
                        <div className="locationItemContentBottom">
                            <span style={{ fontWeight: "bold" }}>Criteria: &nbsp;</span>
                            <span>{locData.criteria}</span>
                        </div>
                        <div className="locationItemContentBottom">
                            <span style={{ fontWeight: "bold" }}>Status: &nbsp;</span>
                            <span>{locData.status}</span>
                        </div>
                        <div className="locationItemContentBottom">
                            <span style={{ fontWeight: "bold" }}>Loc Id: &nbsp;</span>
                            <span>{locData.locid}</span>
                        </div>
                    </div>
                </div>
            );
        });
    };

    const fetchData = async () => {
        try {
            
            setIsLoading(true);
            let url;
            if (adid != '') {
                url = '/adminadlocs/' + adid;
            } else {
                throw "Error Occurred. Please re-login";
            }
            const response = await authAxios.get(url);
            var responseData = [];
            let result = response.data;

            if (result.data) {
                responseData = result.data;
                
                setAdDetails(responseData.adDetails);
                setEstimatedImpressions(responseData.adDetails.estimatedImpressions);
                setCost(responseData.adDetails.cost);
                setDataLocationsSelected(responseData.locations);
                setUserDetails(responseData.userDetails);
                setUserRoles(responseData.roles);
                setPmtDetails(responseData.pmtDetails);
                setPmtHistory(responseData.pmtHistory);
                estimatedImpressionsServer.current = responseData.adDetails.estimatedImpressions;
                costServer.current = responseData.adDetails.cost;
                recalculateCost(responseData.locations, responseData.adDetails);

            } else {
                throw "Server error. Unable to fetch data";
            }

            setIsLoading(false);
        } catch (error) {
            
            showModal("Server Error. Please refresh or relogin", "fail");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <AddetailsSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            {!isLoading && (
                                <>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="adDetailsContainer">
                                                <div className="adDetailsTitle">
                                                    <h4>{adDetails.name}</h4>
                                                </div>
                                                <div className="adDetailsImg">
                                                    {renderThumbnail(adDetails.img)}
                                                </div>
                                                <div className="adDetailsImgWidget">
                                                    <div className="adDetailsImgWidgetWeather">
                                                        <span style={{ color: "#ffffff" }}>Mumbai 25&deg;C, Partly Cloudy</span><i style={{ color: "#fff", paddingLeft: "5px", marginTop: "3px" }} className="nc-icon nc-cloud-upload-94" />
                                                    </div>
                                                    <div className="adDetailsImgWidgetTime">
                                                        <span style={{ color: "#ffffff" }}>Jun 5, 2023 4:00 Pm</span>
                                                    </div>

                                                </div>
                                                <div className="adDetailsStatus">
                                                    <Adstatus status={status} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {renderPendingApprovalDesign()}
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="centerTextDivider">
                                                <div className="centerTextDividerLine" />
                                                <div className="centerTextDividerText">
                                                    <span>Ad Details</span>
                                                </div>
                                                <div className="centerTextDividerLine" />
                                            </div>
                                            <div className="AdItem">
                                                <div className="AdItemContentTop">
                                                    <div className="AdItemContentTopItems">
                                                        <span style={{ fontWeight: "bold" }}>Duration: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.duration} {adDetails?.durationtype} </span>
                                                    </div>
                                                    <div className="AdItemContentTopItems">
                                                        <span style={{ fontWeight: "bold" }}>DesignId: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.designid} </span>
                                                    </div>
                                                    <div className="AdItemContentTopItems">
                                                        <span style={{ fontWeight: "bold" }}>Expiry: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.expirydate} </span>
                                                    </div>
                                                </div>
                                                <div className="AdItemContentMiddle">
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Ad Id: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adid}</span>
                                                    </div>
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>New DesignId: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.new_designid}</span>
                                                    </div>
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Ad Type: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.adtype} </span>
                                                    </div>
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Design Type: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.designtype} </span>
                                                    </div>
                                                </div>
                                                <div className="AdItemContentMiddle">
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Extension: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.extension}</span>
                                                    </div>
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Currency: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.currency} </span>
                                                    </div>
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Created: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{convertDate(adDetails?.created)} </span>
                                                    </div>
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Modified: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{convertDate(adDetails?.modified)} </span>
                                                    </div>
                                                </div>
                                                <div className="AdItemContentBottom">
                                                    <div className="AdItemContentBottomItemsFull">
                                                        <span style={{ fontWeight: "bold" }}>Img: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.img} </span>
                                                    </div>

                                                </div>
                                                <div className="AdItemContentBottom">
                                                    <div className="AdItemContentBottomItemsFull">
                                                        <span style={{ fontWeight: "bold" }}>New Img: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.new_img} </span>
                                                    </div>

                                                </div>
                                                <div className="AdItemContentBottom">
                                                    <div className="AdItemContentBottomItemsFull">
                                                        <span style={{ fontWeight: "bold" }}>Reason: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{adDetails?.reason} </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="centerTextDivider">
                                                <div className="centerTextDividerLine" />
                                                <div className="centerTextDividerText">
                                                    <span>User Details</span>
                                                </div>
                                                <div className="centerTextDividerLine" />
                                            </div>
                                            <div className="AdItem">
                                                <div className="AdItemContentTop">
                                                    <div className="AdItemContentTopItems">
                                                        <span style={{ fontWeight: "bold" }}>Name: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{userDetails?.firstname}, {userDetails?.lastname} </span>
                                                    </div>
                                                    <div className="AdItemContentTopItems">
                                                        <span style={{ fontWeight: "bold" }}>Email: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{userDetails?.email}</span>
                                                    </div>
                                                    <div className="AdItemContentTopItems">
                                                        <span style={{ fontWeight: "bold" }}>Phone: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{userDetails?.phone}</span>
                                                    </div>
                                                </div>
                                                <div className="AdItemContentBottom">
                                                    <div className="AdItemContentBottomItemsFull">
                                                        <span style={{ fontWeight: "bold" }}>Roles: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{userRoles.join(", ")}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="centerTextDivider">
                                                <div className="centerTextDividerLine" />
                                                <div className="centerTextDividerText">
                                                    <span>Payment Details</span>
                                                </div>
                                                <div className="centerTextDividerLine" />
                                            </div>
                                            <div className="AdItem">
                                                <div className="AdItemContentTop">
                                                    <div className="AdItemContentTopItems">
                                                        <span style={{ fontWeight: "bold" }}>AdOrderId: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{pmtDetails?.adorderid} </span>
                                                    </div>
                                                </div>
                                                <div className="AdItemContentMiddle">
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>CfOrderId: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{pmtDetails?.cforderid}</span>
                                                    </div>
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Status: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{pmtDetails?.status}</span>
                                                    </div>
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Amount: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{pmtDetails?.amt}</span>
                                                    </div>
                                                </div>
                                                <div className="AdItemContentMiddle">
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Created: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{convertDate(pmtDetails?.created)}</span>
                                                    </div>
                                                    <div className="AdItemContentMiddleItems">
                                                        <span style={{ fontWeight: "bold" }}>Modified: &nbsp;</span>
                                                        <span style={{ fontWeight: "normal" }}>{convertDate(pmtDetails?.modified)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="centerTextDivider">
                                                <div className="centerTextDividerLine" />
                                                <div className="centerTextDividerText">
                                                    <span>Payment History</span>
                                                </div>
                                                <div className="centerTextDividerLine" />
                                            </div>
                                            {renderPaymentHistory()}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="centerTextDivider">
                                                <div className="centerTextDividerLine" />
                                                <div className="centerTextDividerText">
                                                    <span>Locations</span>
                                                </div>
                                                <div className="centerTextDividerLine" />
                                            </div>
                                            {renderLocations()}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div style={{ marginTop: "10px", marginBottom: "50px" }}>
                                                <FormGroup row>
                                                    <Label
                                                        for="exampleText"
                                                        sm={2}
                                                    >
                                                        Reason:
                                                    </Label>
                                                    <Col sm={10}>
                                                        <Input
                                                            id="reason"
                                                            name="reason"
                                                            type="textarea"
                                                            rows="5"
                                                            value={reason}
                                                            onChange={onReasonInput}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="AddetailsSubmitContainer">
                                        <div className="submitContainerLeftAdminAd">
                                            {renderActionButton()}
                                        </div>
                                        <div className="submitContainerRight">
                                            <div className="impressions">
                                                <div className="impressionsLeft">
                                                    <span>Estimated <br />Impressions:&nbsp;</span>
                                                </div>
                                                <div className="impressionsRight">
                                                    <span>{estimatedImpressions} - {estimatedImpressions * 6}</span>
                                                </div>
                                            </div>
                                            <div className="cost">
                                                <div className="costLeft">
                                                    <span>Subtotal:&nbsp;</span>
                                                </div>
                                                <div className="costRight">
                                                    <span>{cost}</span>
                                                </div>

                                            </div>
                                            <div className="cost">
                                                <div className="costLeft">
                                                    <span>Design Cost:&nbsp;</span>
                                                </div>
                                                <div className="costRight">
                                                    <span>{adDetails.designcost}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="submitContainerRight">
                                            <div className="impressions">
                                                <div className="impressionsLeft">
                                                    <span> Admin Estimated <br />Impressions:&nbsp;</span>
                                                </div>
                                                <div className="impressionsRight">
                                                    <span>{estimatedImpressionsReCalculated} - {estimatedImpressionsReCalculated * 6}</span>
                                                </div>
                                            </div>
                                            <div className="cost">
                                                <div className="costLeft">
                                                    <span>Admin Subtotal:&nbsp;</span>
                                                </div>
                                                <div className="costRight">
                                                    <span>{costReCalculated}</span>
                                                </div>

                                            </div>
                                            <div className="cost">
                                                <div className="costLeft">
                                                    <span>Design Cost:&nbsp;</span>
                                                </div>
                                                <div className="costRight">
                                                    <span>{adDetails.designcost}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />
        </>
    );

}

export default AdminAd;