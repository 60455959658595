import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
//import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import AddetailsSkeleton from "../skeleton-screens/AddetailsSkeleton";
import ADModal from '../components/ADModal';



function Paymentcf() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { adid, discountcodeid, newcost } = state;
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalButtonStatus, setModalButtonStatus] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("Paymentcf-page");
        return function cleanup() {
            document.body.classList.remove("Paymentcf-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };

    const showModal = (msg, status) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
        setModalButtonStatus(status);
    };

    const handleModal = () => {
        if (modalButtonStatus == "success") {
            showModal('','');
            navigate('/home', { replace: true });
        } else {
            showModal('','');
            navigate('/mycart', { state: { adid: adid } });
        }
    }

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const success = async (dataPmt) => {
        if (dataPmt.order && dataPmt.order.status == "PAID") {
            try {
                
                setShowSpinner(true);
                let url;
                let data;
                if (adid != '') {
                    url = '/cfptverify';
                    data = {
                        data: {
                            adorderid: dataPmt.order.orderId,
                            adid: adid
                        }
                    };
                    const response = await authAxios.post(url, data);
                    let result = response.data;
                    if (result.data && result.data.status == "success") {
                        setShowSpinner(false);
                        showModal("Payment is successful. Your ad campaign is active now.", "success");
                        //this.changeResponseText("Payment is successful!!");
                    } else {
                        setShowSpinner(false);
                        showModal("Payment Failed. Please try again", "fail");
                    }
                } else {
                    throw "Error Occurred. Please re-login";
                }


            } catch (error) {
                
                showModal("Error occurred. Please contact us for payment verification", "fail");
                setShowSpinner(false);
            }
        } else {
            //order is still active
            showModal("Order is ACTIVE and not paid yet", "");
        }
    };

    const failure = (data) => {
        
        showModal("Payment Failed. " + data.order.message + "  " + data.transaction.txMsg, "fail");        
    }

    const generateToken = async () => {
        try {
            setShowSpinner(true);
            let url;
            let data;
            if (adid != '') {
                url = '/cfpt';
                data = {
                    data: {
                        adid: adid,
                        discountcodeid: discountcodeid,
                        newcost: newcost
                    }
                };
                const response = await authAxios.post(url, data);
                let result = response.data;
                
                if (result.data) {
                    setShowSpinner(false);
                    return result.data;
                } else {
                    throw "Server error. Please resubmit";
                }
            } else {
                throw "Error Occurred. Please re-login";
            }


        } catch (error) {
            
            showModal("Server Error. Please refresh or relogin", null);
            setShowSpinner(false);
        }
    };

    const launchCfDropCheckout = async () => {
        const cfTokenRespone = await generateToken();
        
        
        if (cfTokenRespone?.paymentsessionid && cfTokenRespone?.paymentsessionid != "") {
            
            let parent = document.getElementById("drop_in_container");
            //let cashfree = new cashfreeSandbox.Cashfree(cfTokenRespone.paymentsessionid);
            let cashfree = new cashfreeProd.Cashfree(cfTokenRespone.paymentsessionid);
            const dropConfig = {
                components: [
                    "order-details",
                    "card",
                    "netbanking",
                    "upi"
                ],
                onSuccess: success,
                onFailure: failure,
                "style": {
                    "backgroundColor": "#ffffff",
                    "color": "#11385b",
                    "fontFamily": "Lato",
                    "fontSize": "14px",
                    "errorColor": "#ff0000",
                    "theme": "light", //(or dark)
                }
            };
    
            cashfree.drop(parent, dropConfig);
        } else {
            showModal("Server Error. Please refresh or relogin", null);
        }        
    }


    useEffect(() => {
        launchCfDropCheckout();
    }, []);

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <AddetailsSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            {!isLoading && (
                                <>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div id="drop_in_container" style={{ marginTop: "100px", overflow: "hidden", height: "900px" }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />
        </>
    );


}

export default Paymentcf;