import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './AuthContext';
import { AxiosProvider } from './AxiosContext';
import App from "./App";

// styles
//import './css/styles.css';
import "bootstrap/scss/bootstrap.scss";
//import "./assets/scss/paper-kit.scss";
//import "./assets/css/bootstrap.min.css";
import "./assets/css/paper-kit.css";
import "./assets/css/slicebox.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <AxiosProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </AxiosProvider>
  </AuthProvider>
);
