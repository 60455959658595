import { motion } from "framer-motion";

const Shimmer = () => {
  return (
    <motion.div
      initial={{ x: -600 }}
      animate={{ x: 1000 }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        repeatDelay: 1
      }}
      className="shimmer"
    ></motion.div>
  );
};

export default Shimmer;
