import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    CustomInput,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../../AuthContext';
import { AxiosContext } from '../../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../../skeleton-screens/HomeSkeleton";
import ADModal from '../../components/ADModal';
import Select from 'react-select';

function Owner() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [modalLive, setModalLive] = useState(false);

    const [dataLocDetails, setDataLocDetails] = useState([]);
    const [dataCityDropdown, setDataCityDropdown] = useState([]);
    const [valueCityDropdown, setValueCityDropdown] = useState(null);
    const [dataAreaDropdown, setDataAreaDropdown] = useState([]);
    const [valueAreaDropdown, setValueAreaDropdown] = useState(null);
    const [dataTypeDropdown, setDataTypeDropdown] = useState([]);
    const [valueTypeDropdown, setValueTypeDropdown] = useState(null);
    const [liveLocId, setLiveLocId] = useState('');
    const [liveZipCode, setLiveZipCode] = useState('');
    const [role, setRole] = useState('owner');
    const [owned, setOwned] = useState('0');
    const [totalrev, setTotalRev] = useState('0');
    const [totalPmts, setTotalPmts] = useState('0');
    const [totalNoStake, setTotalNoStake] = useState('0');
    let dataLocFromServer = useRef([]);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("Owner-page");
        return function cleanup() {
            document.body.classList.remove("Owner-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const toggleModalLive = () => {
        setModalLive(!modalLive);
    };

    const setvalueCityDropdownFunc = (value) => {
        setValueCityDropdown(value);
    }

    const setvalueAreaDropdownFunc = (value) => {
        setValueAreaDropdown(value);
    }

    const setvalueTypeDropdownFunc = (value) => {
        setValueTypeDropdown(value);
    }

    const clearDropdowns = () => {
        setValueCityDropdown(null);
        setValueAreaDropdown(null);
        setValueTypeDropdown(null);
        setDataLocDetails(dataLocFromServer.current);
    }

    const triggerOwnerLocationAds = (locid) => {
        navigate('/ownerlocad', { state: { locid: locid } });
    };

    const searchLocations = (filter) => {
        let data = dataLocFromServer.current.filter(item => {
            for (let key in filter) {
                if (filter[key] !== null && item[key] != filter[key])
                    return false;
            }
            return true;
            //item.city == filter.city
        });

        setDataLocDetails(data);
    }

    const renderLiveView = (locid, zipcode) => {
        return (
            <div className="locationItemLiveViewContent" onClick={() => { setLiveLocId(locid); setLiveZipCode(zipcode); setModalLive(true) }}>
                <i className="nc-icon nc-tv-2" />
                <span style={{ textDecoration: 'underline' }}> Live</span>
            </div>
        );
    }

    const renderLiveVideo = () => {
        let url = process.env.REACT_APP_LOC_STORE_URL + "/" + liveLocId + '-' + liveZipCode + '.mp4';
        
        return (
            <video className="adDetailsImgContain" controls>
                <source src={url} type="video/mp4" />
            </video>
        );
    }

    const renderViewMoreBtn = (locid) => {
        if (role == 'superadmin') {
            return (
                <div style={{ marginTop: '20px' }} onClick={() => { triggerOwnerLocationAds(locid) }}>
                    <span style={{ color: '#344e86', textTransform: 'none' }}>&nbsp;&nbsp; View ads... &nbsp;&nbsp;</span>
                </div>
            );
        }
    };

    const renderLocations = () => {
        return dataLocDetails.map((locData, index) => {
            return (
                <div className="locationItemNoEdit" key={index}>
                    <div className="locationItemContentNoEdit">
                        <div className="locationItemContentTopNoEdit">
                            <span>{locData.type}   ({locData.grade})  ({locData.size}&Prime;)  (img-{locData.priceimg}) (video-{locData.pricevideo})</span>
                        </div>
                        <div className="locationItemContentTopNoEdit">
                            <span>{locData.area}, {locData.city}, {locData.zipcode}</span>
                        </div>
                        <div className="locationItemContentTopNoEdit">
                            <span>Criteria: {locData.criteria}</span>
                        </div>
                        {renderLocRevenue(locData)}
                        <div className="locationItemContentTopNoEdit">
                            <p style={{paddingTop:'3px'}}>
                                <span style={{fontSize: 'small', color: '#767676', fontWeight: '500' }}>Next available date if full:&nbsp; 
                                    <span style={{fontSize: 'small', color: '#b33c12', fontWeight: '500' }}>{locData.avldate}</span>
                                </span>
                            </p>
                        </div>
                        <div className="locationItemContentBottomNoEdit">
                            <span style={{ fontWeight: "normal", fontSize: 10 }}>{index + 1} </span>
                        </div>
                    </div>
                    <div className="locationItemLiveViewNoEdit">
                        {renderLiveView(locData.locid, locData.zipcode)}
                        {renderViewMoreBtn(locData.locid)}
                    </div>
                </div>
            );
        });
    }

    const renderLocRevenue = (locData) => {
        if (role == 'superadmin') {
            return (
                <>
                    <div className="locationItemContentTopNoEdit">
                            <span>Total revenue: {locData.totaladmin}</span>
                        </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="locationItemContentTopNoEdit">
                            <span>Total revenue: {locData.totalowner}</span>
                        </div>
                </>
            );
        }
    };

    const renderRevStats = () => {
        if (role == 'superadmin') {
            return (
                <>
                    <h4>Total revenue with owner stakes deducted: {totalrev}</h4>
                    <h4>Total revenue with no stakes deducted: {totalNoStake}</h4>
                    <h4>Total Payments: {totalPmts}</h4>
                </>
            );
        } else {
            return (
                <>
                    <h4>Total revenue on all owned locations: {totalrev}</h4>
                </>
            );
        }
    }


    const fetchData = async () => {
        try {
            
            setIsLoading(true);
            let url = '/ownerlocs';

            const response = await authAxios.get(url);
            var responseData = [];
            let result = response.data;
            
            if (result.data) {
                responseData = result.data;
                setDataTypeDropdown(responseData.locTypeDropdown);
                setDataAreaDropdown(responseData.locAreaDropdown);
                setDataCityDropdown(responseData.locCityDropdown);
                dataLocFromServer.current = responseData.locations;
                setDataLocDetails(responseData.locations);
                setRole(responseData.role);
                setOwned(responseData.numowned);
                setTotalRev(responseData.totalrevenue);
                if (responseData.role == 'superadmin') {
                    setTotalNoStake(responseData.totalwithoutstake);
                    setTotalPmts(responseData.totalpayments);
                }

            } else {
                throw "Server error. Unable to fetch data";
            }

            setIsLoading(false);
        } catch (error) {
            showModal("Server Error. Please refresh or relogin");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <HomeSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            {!isLoading && (
                                <Row>
                                    <Col className="ml-auto mr-auto">
                                        <div className="AdminHomeContainer">
                                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column" }}>
                                                <h4>Number of Locations Owned: {owned}</h4>
                                                {renderRevStats()}
                                            </div>
                                            <div className="AdminHomeFilterDropdowns">
                                                <Select
                                                    className="reactSelectDropdown"
                                                    classNamePrefix="reactSelectDropdownData"
                                                    value={valueCityDropdown}
                                                    onChange={setvalueCityDropdownFunc}
                                                    options={dataCityDropdown}
                                                    isSearchable={true}
                                                    placeholder="City"
                                                />
                                                <Select
                                                    className="reactSelectDropdown"
                                                    classNamePrefix="reactSelectDropdownData"
                                                    value={valueAreaDropdown}
                                                    onChange={setvalueAreaDropdownFunc}
                                                    options={dataAreaDropdown}
                                                    isSearchable={true}
                                                    placeholder="Area"
                                                />
                                                <Select
                                                    className="reactSelectDropdown"
                                                    classNamePrefix="reactSelectDropdownData"
                                                    value={valueTypeDropdown}
                                                    onChange={setvalueTypeDropdownFunc}
                                                    options={dataTypeDropdown}
                                                    isSearchable={true}
                                                    placeholder="Type"
                                                />
                                            </div>
                                            <div className="AdminHomeFilterButtons">
                                                <div style={{ width: "20%" }}>
                                                    <Button
                                                        className="btn-round btn-createad"
                                                        color="primary"
                                                        onClick={() => searchLocations({
                                                            "city": valueCityDropdown ? valueCityDropdown.value : null,
                                                            "area": valueAreaDropdown ? valueAreaDropdown.value : null,
                                                            "type": valueTypeDropdown ? valueTypeDropdown.value : null
                                                        })}
                                                    >
                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Filter&nbsp;</span>
                                                    </Button>
                                                </div>
                                                <div style={{ width: "20%", marginLeft: "10px" }}>
                                                    <Button
                                                        className="btn-round btn-createad"
                                                        color="primary"
                                                        onClick={() => clearDropdowns()}
                                                    >
                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Clear&nbsp;</span>
                                                    </Button>
                                                </div>

                                            </div>
                                            <div className="AdminAdDetails">
                                                {renderLocations()}
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Container>
                    </div>
                    <Modal isOpen={modalLive} centered={true} toggle={toggleModalLive} size="xl">
                        <div className="modal-header">
                            <button
                                aria-label="Close"
                                className="close"
                                type="button"
                                onClick={toggleModalLive}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                            <h5
                                className="modal-title text-center"
                                id="exampleModalLabel"
                            >
                                Live feed - gets updated every 15 minutes
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="adDetailsContainer">
                                <div className="adDetailsImg">
                                    {renderLiveVideo()}
                                </div>
                            </div>

                        </div>
                    </Modal>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default Owner;
