import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Modal,
    Row,
    Col,
    Spinner
} from "reactstrap";

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import DemoFooter from "../components/Footers/DemoFooter.js";
import { Formik } from 'formik';
import * as yup from 'yup';
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import ADModal from '../components/ADModal';
import ReCAPTCHA from "react-google-recaptcha";

function Resetpin() {
    const navigate = useNavigate();
    const auth = React.useContext(AuthContext);
    const { publicAxios } = React.useContext(AxiosContext);
    const [modalMsg, setModalMsg] = React.useState('');
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [modalButtonType, setModalButtonType] = React.useState('');
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [showOtp, setShowOtp] = React.useState(false);
    const [showPin, setShowPin] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const [phoneEditable, setPhoneEditable] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailEditable, setEmailEditable] = React.useState(false);
    const captchaRef = useRef(null);

    const formRef = React.createRef();
    const signUpValidationSchema = yup.object().shape({
        phoneNumber: yup
            .string()
            .when('email', {
                is: (email) => !email || email.length === 0,
                then: yup.string().test(
                    "maxDigits",
                    "Phone number must be 10 digits",
                    (number) => String(number).length === 10
                )
                    .required('Phone number or email is required')
            }),
        email: yup
            .string()
            .email("Please enter valid email")
            .when('phoneNumber', {
                is: (phoneNumber) => !phoneNumber || phoneNumber.length === 0,
                then: yup.string().required('Phone number or email is required')
            }),
        otp: yup
            .string().test(
                "maxDigits",
                "OTP must be 5 digits",
                (number) => String(number).length === 5
            ),
        pin: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(4, 'Pin must be 4 digits')
            .max(4, 'Pin must be 4 digits')
            .required('Pin is required'),
        confirmPin: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .oneOf([yup.ref('pin')], 'Pin do not match')
            .required('Confirm pin is required'),
    }, ['phoneNumber', 'email'])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("resetpin");
        return function cleanup() {
            document.body.classList.remove("resetpin");
        };
    });

    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth < 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg, type = null) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
        setModalButtonType(type);
    };

    const handleModal = () => {
        if (modalButtonType == "success") {
            showModal('', '');
            navigate('/login', { replace: true });
        } else {
            showModal('', '');
        }
    };

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const showOtpUi = (values, handleChange, handleBlur, touched, errors, setFieldValue) => {
        if (showOtp) {
            return (

                <Form className="register-form">
                    <hr />
                    <InputGroup className="form-group-with-border">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="nc-icon nc-mobile" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            id="otp"
                            placeholder="Enter Otp"
                            type="number"
                            value={values.otp}
                            onChange={handleChange('otp')}
                            onBlur={handleBlur('otp')}
                        />
                    </InputGroup>
                    {errors.otp && touched.otp && (
                        <div className="input-feedback">{errors.otp}</div>
                    )}

                    <div className="btncenter">
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleSubmitForm(values, 'verifyotp', setFieldValue)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Verify&nbsp;</span>
                        </Button>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleCancel('otpcancel', setFieldValue)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Cancel&nbsp;</span>
                        </Button>
                    </div>
                </Form>
            );
        }

    }

    const showPinUi = (values, handleChange, handleBlur, touched, errors, setFieldValue) => {
        if (showPin) {
            return (
                <Form className="register-form">
                    <hr />
                    <InputGroup className="form-group-with-border">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="nc-icon nc-key-25" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            id="pin"
                            placeholder="4 digit new pin"
                            type="password"
                            inputMode="numeric"
                            maxLength="4"
                            value={values.pin}
                            onChange={handleChange('pin')}
                            onBlur={handleBlur('pin')}
                        />
                    </InputGroup>
                    {errors.pin && touched.pin && (
                        <div className="input-feedback">{errors.pin}</div>
                    )}

                    <InputGroup className="form-group-with-border">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="nc-icon nc-key-25" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            id="confirmPin"
                            placeholder="Confirm pin"
                            type="password"
                            inputMode="numeric"
                            maxLength="4"
                            value={values.confirmPin}
                            onChange={handleChange('confirmPin')}
                            onBlur={handleBlur('confirmPin')}
                        />
                    </InputGroup>
                    {errors.confirmPin && touched.confirmPin && (
                        <div className="input-feedback">{errors.confirmPin}</div>
                    )}

                    <div className="btncenter">
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleSubmitForm(values, 'changepin', setFieldValue)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Verify&nbsp;</span>
                        </Button>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleCancel('pincancel', setFieldValue)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Cancel&nbsp;</span>
                        </Button>
                    </div>
                </Form>
            );
        }

    }

    const handleCancel = (type, setFieldValue) => {

        if (type == 'otpcancel') {
            setFieldValue('otp', '');
            setShowOtp(false);
            setPhoneEditable(false);
            setEmailEditable(false);
            setPhone('');
            setEmail('');
        }

        if (type == 'pincancel') {
            setFieldValue('pin', '');
            setFieldValue('confirmPin', '');
            setShowPin(false);
            setShowOtp(false);
            setPhoneEditable(false);
            setEmailEditable(false);
            setPhone('');
            setEmail('');
        }
    };

    const handleSubmitForm = async (values, type, setFieldValue = null) => {
        try {

            let url = '/resetpin';
            let data = '';

            if (type == 'useridsubmit') {
                formRef.current.setTouched({ phoneNumber: true, email: true });
                let res = await formRef.current.validateForm();

                if (res.phoneNumber === undefined && res.email === undefined) {
                    setShowSpinner(true);
                    const token = await captchaRef.current.executeAsync();
                    captchaRef.current.reset();

                    const captchadata = {
                        token: token
                    };
                    const captchaResponse = await publicAxios.post('/verifycaptcha', captchadata, {
                        headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' }
                    });

                    let captchaResult = captchaResponse.data;
                    setShowSpinner(false);
                    if (captchaResult?.data == 'human' || captchaResult?.data == 'no token') {
                        data = {
                            data: {
                                phone: values.phoneNumber,
                                email: values.email,
                                type: 'sendotp'
                            }
                        };
                    }

                }
            }

            if (type == 'verifyotp') {
                formRef.current.setTouched({ otp: true });
                let res = await formRef.current.validateForm();
                if (res.otp === undefined) {
                    setShowSpinner(true);
                    const tokenu = await captchaRef.current.executeAsync();
                    captchaRef.current.reset();

                    const captchadatau = {
                        token: tokenu
                    };
                    const captchaResponseu = await publicAxios.post('/verifycaptcha', captchadatau, {
                        headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' }
                    });

                    let captchaResultu = captchaResponseu.data;
                    setShowSpinner(false);
                    if (captchaResultu?.data == 'human' || captchaResultu?.data == 'no token') {
                        data = {
                            data: {
                                phone: phone,
                                email: email,
                                type: 'verifyotp',
                                otp: values.otp
                            }
                        };
                    }

                }
            }

            if (type == 'changepin') {
                formRef.current.setTouched({ pin: true, confirmPin: true });
                let res = await formRef.current.validateForm();
                if (res.pin === undefined && res.confirmPin === undefined) {
                    data = {
                        data: {
                            type: 'changepin',
                            pin: values.pin,
                            phone: phone,
                            email: email
                        }
                    };
                }
            }


            if (data) {
                setShowSpinner(true);
                const response = await publicAxios.post(url, data, {
                    headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' }
                });

                if (typeof response.data.error === 'undefined' && typeof response.data.data !== 'undefined') {
                    let result = response.data;
                    if (type == 'useridsubmit') {
                        setShowOtp(true);
                        setShowPin(false);
                        setPhoneEditable(true);
                        setEmailEditable(true);
                        setPhone(values.phoneNumber);
                        setEmail(values.email);

                        setShowSpinner(false);
                        showModal("OTP is sent to your Id", null);
                    }

                    if (type == 'verifyotp') {
                        setFieldValue('otp', '');
                        setShowOtp(false);
                        setShowPin(true);
                        setPhoneEditable(true);
                        setEmailEditable(true);
                        showModal("Otp verified successfully!!", null);
                        setShowSpinner(false);
                    }

                    if (type == 'changepin') {
                        setShowPin(false);
                        setShowSpinner(false);
                        showModal("Updated pin successfully!!", "success");
                    }

                } else {
                    setShowSpinner(false);
                    throw (response.data.error === undefined) ? 'Server error. Please re-submit' : response.data.error;
                }

            } else {
                throw "Input error occurred. Please check the fields and resubmit";
            }


        } catch (error) {

            if (typeof error.message === 'undefined') {
                showModal(error, null);
            } else {
                showModal(error.message, null);
            }

        }
    };

    return (
        <>
            <IndexNavbar />
            <div
                className="page-header-other"
                style={{
                    backgroundImage:
                        "url(" + require("../assets/img/adkewl-banner-bkgd-dark.png") + ")",
                }}
                data-parallax={true}
                ref={pageHeader}
            />
            <div className="main">
                <div className="homeNavSection text-center">
                    <Container>
                        <Formik
                            validationSchema={signUpValidationSchema}
                            enableReinitialize={true}
                            initialValues={{
                                email: '',
                                phoneNumber: '',
                                pin: '',
                                confirmPin: '',
                                otp: ''
                            }}
                            innerRef={formRef}
                            validateOnChange={true}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                isValid,
                                setFieldValue,
                                values,
                                touched,
                                errors
                            }) => (
                                <>
                                    <Row>
                                        <Col className="mx-auto" lg="4" md="6">
                                            <Card className="card-register">
                                                <h3 className="title mx-auto">Reset Pin</h3>
                                                <Form className="register-form">
                                                    <InputGroup className="form-group-with-border">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-mobile" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            id="phoneNumber"
                                                            placeholder="Phone Number"
                                                            type="number"
                                                            maxLength="10"
                                                            value={values.phoneNumber}
                                                            onChange={handleChange('phoneNumber')}
                                                            onBlur={handleBlur('phoneNumber')}
                                                            disabled={phoneEditable}
                                                        />
                                                    </InputGroup>
                                                    {errors.phoneNumber && touched.phoneNumber && (
                                                        <div className="input-feedback">{errors.phoneNumber}</div>
                                                    )}
                                                    <div style={{ marginTop: '5px' }}>(or)</div>
                                                    <InputGroup className="form-group-with-border">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-email-85" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            id="email"
                                                            placeholder="Email Address"
                                                            type="email"
                                                            value={values.email}
                                                            onChange={handleChange('email')}
                                                            onBlur={handleBlur('email')}
                                                            disabled={emailEditable}
                                                        />
                                                    </InputGroup>
                                                    {errors.email && touched.email && (
                                                        <div className="input-feedback">{errors.email}</div>
                                                    )}

                                                    <div className="btncenter">
                                                        <Button
                                                            className="btn-round"
                                                            color="primary"
                                                            onClick={() => handleSubmitForm(values, 'useridsubmit', setFieldValue)}
                                                        >
                                                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Submit&nbsp;</span>
                                                        </Button>
                                                    </div>
                                                    <ReCAPTCHA
                                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                                        ref={captchaRef}
                                                        size="invisible"
                                                    />
                                                </Form>
                                                {showOtpUi(values, handleChange, handleBlur, touched, errors, setFieldValue)}
                                                {showPinUi(values, handleChange, handleBlur, touched, errors, setFieldValue)}
                                            </Card>

                                        </Col>
                                    </Row>


                                </>
                            )}
                        </Formik>



                    </Container>
                </div>
                <ADModal
                    isVisible={isModalVisible}
                    msg={modalMsg}
                    children={renderOkButton()}
                />
                {renderSpinner()}
            </div >
            <DemoFooter />

        </>
    );
}

export default Resetpin;
