import React, { useContext, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import IndexHeader from "../components/Headers/IndexHeader.js";
import DemoFooter from "../components/Footers/DemoFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';

function Index() {
  const auth = useContext(AuthContext);
  const { publicAxios } = useContext(AxiosContext);
  const [enqMsg, setEnqMsg] = useState('');
  const [videoLang, setVideoLang] = useState('English');
  const [disablesendmsg, setDisablesendmsg] = useState(false);

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });

  const signUpValidationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(/\w.+/, 'Enter name')
      .min(3, ({ min }) => `Name must be at least ${min} characters`)
      .required('Full name is required'),
    msg: yup
      .string()
      .required('Please enter message'),
    email: yup
      .string()
      .email("Please enter valid email"),
  });

  const setLang = (lang) => {
    setVideoLang(lang);
  }

  const renderVideo = () => {
    if (videoLang == 'English') {
      return (
        <video key={videoLang} className="homeVideoContain" poster={require("../assets/img/adkewl-banner-bkgd.jpg")} controls>
          <source src={require("../assets/img/adkewl-vid-eng.mp4")} type="video/mp4" />
        </video>
      );
    }
    if (videoLang == 'Telugu') {
      return (
        <video key={videoLang} className="homeVideoContain" poster={require("../assets/img/adkewl-banner-bkgd.jpg")} controls>
          <source src={require("../assets/img/adkewl-vid-tel.mp4")} type="video/mp4" />
        </video>
      );
    }
    if (videoLang == 'Hindi') {
      return (
        <video key={videoLang} className="homeVideoContain" poster={require("../assets/img/adkewl-banner-bkgd.jpg")} controls>
          <source src={require("../assets/img/adkewl-vid-hin.mp4")} type="video/mp4" />
        </video>
      );
    }

  }

  const handleSubmit = async (values) => {
    if (values.name && values.msg && values.email) {
      const data = {
        data: {
          user: values.name,
          email: values.email,
          msg: values.msg
        }
      };
      try {
        const response = await publicAxios.post('/sendenquiry', data, {
          headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' }
        });
        let result = response.data;
        
        if (result.data.status == 'success') {
          setEnqMsg("success");
          setDisablesendmsg(true);
        } else {
          setEnqMsg("failed");
        }
      } catch (error) {
        setEnqMsg("failed");
      }
    } else {
      setEnqMsg("failed");
    }
  };

  const renderEnquiryMsg = () => {
    if (enqMsg == "failed") {
      return (<span style={{ color: '#b33c12', textTransform: 'none', fontWeight: 'bolder' }}>Failed to send your message. Please retry</span>);
    } else if (enqMsg == "success") {
      return (<span style={{ color: '#000000', textTransform: 'none', fontWeight: 'bolder' }}>Message sent. We will get back to you soon</span>);
    } else {
      return (<span style={{ color: '#0b1011', textTransform: 'none' }}></span>);
    }
  };
  return (
    <>
      <IndexNavbar />
      <IndexHeader />
      <div className="main">
        <div id="adkewlproduct" name="adkewlproduct" className="section section-light-blue text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="sliceboxRight">
                  <h2 className="title">Let's talk product</h2>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <div className="talkproductLeft">

                  <h5 className="description">
                    An end to end state of the art solution for all your marketing needs in just 3 easy steps.
                    Start your Ad campaigns yourselves with our convinient graphic editor and be visible to millions in minutes.
                  </h5>
                  <div className="description" style={{ marginTop: "25px", marginBottom: "25px" }}>
                    <h5 className="description">
                      <Button
                        className="btn-just-icon btn-sm ml-3"
                        color="info"
                        type="button"
                      >
                        <i className="nc-icon nc-check-2" />
                      </Button>
                      &nbsp;No contracts. Totally up to you, run it for a day or an year
                    </h5>
                    <h5>
                      <Button
                        className="btn-just-icon btn-sm ml-3"
                        color="info"
                        type="button"
                      >
                        <i className="nc-icon nc-check-2" />
                      </Button>
                      &nbsp;Much cheaper than regular signage
                    </h5>
                    <h5>
                      <Button
                        className="btn-just-icon btn-sm ml-3"
                        color="info"
                        type="button"
                      >
                        <i className="nc-icon nc-check-2" />
                      </Button>
                      &nbsp;Watch what's playing in any location anytime
                    </h5>
                    <h5>
                      <Button
                        className="btn-just-icon btn-sm ml-3"
                        color="info"
                        type="button"
                      >
                        <i className="nc-icon nc-check-2" />
                      </Button>
                      &nbsp;Edit Ad while it's playing and see it active in minutes
                    </h5>
                  </div>

                </div>

              </Col>
              <Col className="ml-auto mr-auto" md="6">
                <div className="homeVideocontainer">
                  <div className="homeVideoSelector">
                    <Button
                      className="twitter-sharrre btn-round"
                      color="google-bg"
                      onClick={() => setLang("English")}
                    >
                      English
                    </Button>
                    <Button
                      className="linkedin-sharrre btn-round  ml-2"
                      color="google-bg"
                      onClick={() => setLang("Telugu")}
                    >
                      Telugu
                    </Button>
                    <Button
                      className="facebook-sharrre btn-round ml-2"
                      color="google-bg"
                      onClick={() => setLang('Hindi')}
                    >
                      Hindi
                    </Button>
                  </div>
                  <div className="homeVideo">
                    {renderVideo()}
                  </div>

                </div>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="sliceboxRight">
                  <h4 className="description">
                    Locations through out the country and digital signage in variety of spaces
                  </h4>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <div>
                  <ul id="sb-slider" className="sb-slider">
                    <li>
                      <img src={require("../assets/img/locs/adkewl-ind.png")} alt="Airport" />
                    </li>
                    <li>
                      <img src={require("../assets/img/locs/1.jpg")} alt="Airport" />
                    </li>
                    <li>
                      <img src={require("../assets/img/locs/2.jpg")} alt="Gym" />
                    </li>
                    <li>
                      <img src={require("../assets/img/locs/3.jpg")} alt="Hotel" />
                    </li>
                    <li>
                      <img src={require("../assets/img/locs/4.jpg")} alt="Mall" />
                    </li>
                    <li>
                      <img src={require("../assets/img/locs/5.jpg")} alt="Restaurant" />
                    </li>
                    <li>
                      <img src={require("../assets/img/locs/6.jpg")} alt="Store" />
                    </li>
                    <li>
                      <img src={require("../assets/img/locs/7.jpg")} alt="Theatre" />
                    </li>
                  </ul>

                  <div id="nav-arrows" className="nav-arrows">
                    <a href="#">Next</a>
                    <a href="#">Previous</a>
                  </div>

                  <div id="nav-options" className="nav-options">
                    <span id="navPlay">Play</span>
                    <span id="navPause">Pause</span>
                  </div>
                </div>
              </Col>

            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="letstalksignup">
                    <h4 className="info-title" style={{ marginBottom: "10px" }}>Signup</h4>
                    <h5 className="description">
                      Sign up with basic details
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="letstalksignup">
                    <h4 className="info-title" style={{ marginBottom: "10px" }}>Create Ad</h4>
                    <h5 className="description">
                      Create your own Ad in just a few minutes
                      <br /> Using our graphic editior with millions of templates
                    </h5>
                  </div>

                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="letstalksignup">
                    <h4 className="info-title" style={{ marginBottom: "10px" }}>Start Campaign</h4>
                    <h5 className="description">
                      Set the duration of Ad campaign<br />Select locations of your choice<br />Pay and publish
                    </h5>
                  </div>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="adkewlabout" name="adkewlabout" className="section section-light-blue text-center">
          <a name="page-section" className="collapseA"> placeholder-content (important) </a>
          <Container>
            <h2 className="title">Let's talk about us</h2>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h5 className="description">
                  Adkewl thrives to make digital signage available at affordable prices to every one
                </h5>
                <h5 className="description">
                  Offers a great solution to both big and small scale entities. Easy to use and very convinient to manage your Ad campaigns
                </h5>
                <h5 className="description">
                  Locations all over the country with Led displays ranging from single screens to video walls
                </h5>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="6">
                <a href="https://play.google.com/store/apps/details?id=com.adkewl" target="_blank">
                  <img className="adkewlMobileImg" alt="Adkewl Android" src={require("../assets/img/adkewl-android-red.png")} />
                </a>
              </Col>
              <Col md="6">
                <a href="https://apps.apple.com/app/adkewl/id6642675702" target="_blank">
                  <img className="adkewlMobileImg" alt="Adkewl Apple" src={require("../assets/img/adkewl-apple-red.png")} />
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="adkewlcontact" name="adkewlcontact" className="section section-light-blue landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Keep in touch?</h2>
                <Formik
                  validationSchema={signUpValidationSchema}
                  initialValues={{
                    name: '',
                    email: '',
                    msg: ''
                  }}
                  onSubmit={(values) => { handleSubmit(values) }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    isValid,
                    setFieldValue,
                    setFieldTouched,
                    values,
                    touched,
                    errors
                  }) => (
                    <>
                      <Form className="contact-form">
                        <Row>
                          <Col md="6">
                            <label>Name</label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="nc-icon nc-single-02" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                id="name"
                                placeholder="Name"
                                type="text"
                                value={values.name}
                                onChange={handleChange('name')}
                                onBlur={handleBlur('name')}
                              />
                            </InputGroup>
                            {errors.name && touched.name && (
                              <div className="input-feedback">{errors.name}</div>
                            )}
                          </Col>
                          <Col md="6">
                            <label>Email</label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="nc-icon nc-email-85" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                id="email"
                                placeholder="Email Address"
                                type="email"
                                value={values.email}
                                onChange={handleChange('email')}
                                onBlur={handleBlur('email')}
                              />
                            </InputGroup>
                            {errors.email && touched.email && (
                              <div className="input-feedback">{errors.email}</div>
                            )}
                          </Col>
                        </Row>
                        <label>Message</label>
                        <Input
                          id="msg"
                          placeholder="Your enquiry here..."
                          type="textarea"
                          rows="4"
                          value={values.msg}
                          onChange={handleChange('msg')}
                          onBlur={handleBlur('msg')}
                        />
                        {errors.msg && touched.msg && (
                          <div className="input-feedback">{errors.msg}</div>
                        )}
                        <Row>
                          <Col className="ml-auto mr-auto" md="4">
                            <Button
                              className="btn-fill"
                              color="danger"
                              size="lg"
                              disabled={disablesendmsg}
                              onClick={handleSubmit}
                            >
                              Send Message
                            </Button>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col className="ml-auto mr-auto" md="6">
                            {renderEnquiryMsg()}
                          </Col>
                        </Row>
                      </Form>

                    </>

                  )}
                </Formik>

              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <DemoFooter />
    </>
  );
}

export default Index;
