import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Modal,
    Row,
    Col,
    Spinner
} from "reactstrap";

// core components
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import AddetailsSkeleton from "../skeleton-screens/AddetailsSkeleton";
import ADModal from '../components/ADModal';
import { Formik } from 'formik';
import * as yup from 'yup';

function Account() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteModalMsg, setDeleteModalMsg] = useState('');
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const [userDetails, setUserDetails] = useState([]);
    const [nameEditable, setNameEditable] = useState(false);
    const [nameSubmit, setNameSubmit] = useState(false);
    const [phoneEditable, setPhoneEditable] = useState(false);
    const [phoneSubmit, setPhoneSubmit] = useState(false);
    const [emailEditable, setEmailEditable] = useState(false);
    const [emailSubmit, setEmailSubmit] = useState(false);
    const [formOtpText, setFormOtpText] = useState('');
    const [showOtp, setShowOtp] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [showAddemail, setShowAddemail] = useState(false);
    const [showAddButton, setShowAddButton] = useState(true);
    const [showAddphone, setShowAddphone] = useState(false);
    const [showAddButtonPhone, setShowAddButtonPhone] = useState(true);

    const formRef = React.createRef();

    const signUpValidationSchema = yup.object().shape({
        fullName: yup
            .string()
            .matches(/\w.+/, 'Enter full name')
            .min(3, ({ min }) => `Name must be at least ${min} characters`)
            .required('Full name is required'),
        phoneNumber: yup
            .string().test(
                "maxDigits",
                "Phone number must be 10 digits",
                (number) => String(number).length === 10
            ),
        otp: yup
            .string().test(
                "maxDigits",
                "OTP must be 5 digits",
                (number) => String(number).length === 5
            ),
        email: yup
            .string()
            .email("Please enter valid email")
            .required('email is required'),
        currentpin: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(4, 'Pin must be 4 digits')
            .max(4, 'Pin must be 4 digits')
            .required('Pin is required'),
        newpin: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(4, 'Pin must be 4 digits')
            .max(4, 'Pin must be 4 digits')
            .required('Pin is required'),
        confirmpin: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .oneOf([yup.ref('newpin')], 'Pin do not match')
            .required('Confirm pin is required'),
    })

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("Account-page");
        return function cleanup() {
            document.body.classList.remove("Account-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };


    const showDeleteModal = (msg) => {
        setDeleteModalMsg(msg);
        setIsDeleteModalVisible(!isDeleteModalVisible);
    };

    const handleDeleteModal = () => showDeleteModal('');

    const renderDeleteButtons = () => {
        return (
            <div>
                <Button
                    className="btn-round"
                    color="primary"
                    type="button"
                    onClick={() => handleDeleteAccount()}
                >
                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; Delete &nbsp;&nbsp;</span>
                </Button>
                <Button
                    className="btn-round"
                    color="primary"
                    type="button"
                    onClick={() => handleDeleteModal()}
                >
                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; Cancel &nbsp;&nbsp;</span>
                </Button>
            </div>


        );
    };

    const handleDeleteAccount = async () => {
        try {
            handleDeleteModal();
            setShowSpinner(true);
            let url;
            url = '/deleteaccount';
            const response = await authAxios.post(url);
            let result = response.data;
            if (result.data && result.data.status == "success") {
                setShowSpinner(false);
                localStorage.removeItem('adyantraat');
                auth.logout();
                navigate('/');
            } else {
                setShowSpinner(false);
                showModal("Error occurred. Please try again or contact us");
            }


        } catch (error) {

            showModal("Error occurred. Please try again or contact us");
            setShowSpinner(false);
        }
    }

    const showNameSubmit = (values, isValid) => {
        if (nameSubmit) {
            return (
                <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => handleSubmitForm(values, 'namesubmit', null, isValid)}
                >
                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Submit&nbsp;</span>
                </Button>
            );
        } else { }
    };

    const showNameCancel = (setFieldValue) => {
        if (nameSubmit) {
            return (
                <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => handleCancel('namecancel', setFieldValue)}
                >
                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Cancel&nbsp;</span>
                </Button>
            );
        } else { }
    }

    const nameEdit = () => {
        setNameEditable(!nameEditable);
        setNameSubmit(!nameSubmit);
    };

    const phoneEdit = () => {
        setPhoneEditable(!phoneEditable);
        setPhoneSubmit(!phoneSubmit);
        setEmailEditable(false);
        setEmailSubmit(false);
    };

    const showAddPhoneButton = () => {
        setPhoneSubmit(true);
        setShowAddphone(true);
        setShowAddButtonPhone(false);

    };

    const showPhoneEdit = () => {
        if (userDetails.phone != '') {
            return (
                <>
                    <div className="myAccountEdit">
                        <div className="myAccountEditLeft">
                            <h4 className="title mx-auto">Phone</h4>
                        </div>
                        <div className="myAccountEditRight">
                            <Button
                                className="btn-round btn-createad"
                                color="primary"
                                onClick={() => phoneEdit()}
                            >
                                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Edit&nbsp;</span>
                            </Button>
                        </div>

                    </div>
                </>
            );
        } else { }

    };

    const showLoginPhoneCheckBox = (values, handleChange, handleBlur, touched, errors) => {
        if (userDetails.phone != '') {
            return (
                <>
                    <Form className="register-form">
                        <InputGroup className="form-group-with-border">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-mobile" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="phoneNumber"
                                placeholder="Phone Number"
                                type="number"
                                maxLength="10"
                                value={values.phoneNumber}
                                onChange={handleChange('phoneNumber')}
                                onBlur={handleBlur('phoneNumber')}
                                disabled={!phoneEditable}
                            />
                        </InputGroup>
                        {errors.phoneNumber && touched.phoneNumber && (
                            <div className="input-feedback">{errors.phoneNumber}</div>
                        )}
                    </Form>
                </>
            );
        } else {
            if (showAddButtonPhone) {
                return (
                    <>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => showAddPhoneButton()}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Add Phone&nbsp;</span>
                        </Button>
                    </>
                );
            }
        }

    }

    const showAddPhoneFunc = (values, handleChange, handleBlur, touched, errors) => {
        if (showAddphone) {
            return (
                <>
                    <Form className="register-form">
                        <InputGroup className="form-group-with-border">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-mobile" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="phoneNumber"
                                placeholder="Phone Number"
                                type="number"
                                maxLength="10"
                                value={values.phoneNumber}
                                onChange={handleChange('phoneNumber')}
                                onBlur={handleBlur('phoneNumber')}
                            />
                        </InputGroup>
                        {errors.phoneNumber && touched.phoneNumber && (
                            <div className="input-feedback">{errors.phoneNumber}</div>
                        )}
                    </Form>
                </>
            );
        }
    }

    const showPhoneSubmitCancel = (values, isValid, setFieldValue) => {
        if (phoneSubmit) {
            let typeSubmit = 'phonesubmit';
            let typeCancel = 'phonecancel';
            if (userDetails.phone == '') {
                typeSubmit = 'addphonesubmit';
                typeCancel = 'addphonecancel';
            }
            return (
                <div className="myAccountSubmit">
                    <div>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleSubmitForm(values, typeSubmit, null, isValid)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Submit&nbsp;</span>
                        </Button>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleCancel(typeCancel, setFieldValue)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Cancel&nbsp;</span>
                        </Button>
                    </div>
                </div>
            );
        } else { }
    }

    const emailEdit = () => {
        setEmailEditable(!emailEditable);
        setEmailSubmit(!emailSubmit);
        setPhoneEditable(false);
        setPhoneSubmit(false);
    }

    const showAddEmailButton = () => {
        setEmailSubmit(true);
        setShowAddemail(true);
        setShowAddButton(false);
    };

    const showEmailEdit = () => {
        if (userDetails.email != '') {
            return (
                <>
                    <div className="myAccountEdit">
                        <div className="myAccountEditLeft">
                            <h4 className="title mx-auto">Email</h4>
                        </div>
                        <div className="myAccountEditRight">
                            <Button
                                className="btn-round btn-createad"
                                color="primary"
                                onClick={() => emailEdit()}
                            >
                                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Edit&nbsp;</span>
                            </Button>
                        </div>

                    </div>

                </>
            );
        } else { }

    };

    const showLoginEmailCheckBox = (values, handleChange, handleBlur, touched, errors) => {
        if (userDetails.email != '') {
            return (
                <>
                    <Form className="register-form">
                        <InputGroup className="form-group-with-border">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-email-85" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="email"
                                placeholder="Email Address"
                                type="email"
                                value={values.email}
                                onChange={handleChange('email')}
                                onBlur={handleBlur('email')}
                                disabled={!emailEditable}
                            />
                        </InputGroup>
                        {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                        )}
                    </Form>
                </>
            );
        } else {
            if (showAddButton) {
                return (
                    <>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => showAddEmailButton()}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Add Email&nbsp;</span>
                        </Button>
                    </>
                );
            }
        }
    };

    const showAddEmailFunc = (values, handleChange, handleBlur, touched, errors) => {
        if (showAddemail) {
            return (
                <>
                    <Form className="register-form">
                        <InputGroup className="form-group-with-border">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-email-85" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="email"
                                placeholder="Email Address"
                                type="email"
                                value={values.email}
                                onChange={handleChange('email')}
                                onBlur={handleBlur('email')}
                            />
                        </InputGroup>
                        {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                        )}
                    </Form>
                </>
            );
        }
    }

    const showEmailSubmitCancel = (values, isValid, setFieldValue) => {
        if (emailSubmit) {
            let typeSubmit = 'emailsubmit';
            let typeCancel = 'emailcancel';
            if (userDetails.email == '') {
                typeSubmit = 'addemailsubmit';
                typeCancel = 'addemailcancel';
            }
            return (
                <div className="myAccountSubmit">
                    <div>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleSubmitForm(values, typeSubmit, null, isValid)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Submit&nbsp;</span>
                        </Button>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleCancel(typeCancel, setFieldValue)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Cancel&nbsp;</span>
                        </Button>
                    </div>
                </div>
            );
        } else { }
    }

    const showOtpCard = (values, handleChange, handleBlur, touched, errors, setFieldValue, isValid) => {
        if (showOtp) {
            return (
                <Card className="card-register" style={{ minHeight: "unset" }}>
                    <div className="myAccountEdit">
                        <div className="myAccountEditLeft">
                            <h4 className="title mx-auto">Otp</h4>
                        </div>
                        <div className="myAccountEditRight">
                        </div>
                    </div>
                    {formOtp(values, handleChange, handleBlur, touched, errors)}
                    {buttonOtp(values, setFieldValue, isValid)}
                </Card>
            );
        } else { }
    };

    const formOtp = (values, handleChange, handleBlur, touched, errors) => {
        if (showOtp) {
            return (
                <>
                    <Form className="register-form">
                        <InputGroup className="form-group-with-border">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-mobile" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="otp"
                                placeholder={formOtpText}
                                type="number"
                                value={values.otp}
                                onChange={handleChange('otp')}
                                onBlur={handleBlur('otp')}
                            />
                        </InputGroup>
                        {errors.otp && touched.otp && (
                            <div className="input-feedback">{errors.otp}</div>
                        )}
                    </Form>
                </>
            );
        }

    }

    const buttonOtp = (values, setFieldValue, isValid) => {
        if (showOtp) {
            return (
                <>
                    <div className="myAccountSubmit">
                        <div>
                            <Button
                                className="btn-round"
                                color="primary"
                                onClick={() => handleSubmitForm(values, 'verifyotp', setFieldValue, isValid)}
                            >
                                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Verify&nbsp;</span>
                            </Button>
                        </div>
                        <div style={{ marginLeft: "10px" }}>
                            <Button
                                className="btn-round"
                                color="primary"
                                onClick={() => handleSubmitForm(values, 'resendotp', setFieldValue, isValid)}
                            >
                                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Resend&nbsp;</span>
                            </Button>
                        </div>
                        <div style={{ marginLeft: "10px" }}>
                            <Button
                                className="btn-round"
                                color="primary"
                                onClick={() => handleCancel('otpcancel', setFieldValue)}
                            >
                                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Cancel&nbsp;</span>
                            </Button>
                        </div>
                    </div>
                </>
            );
        } else { }
    }

    const pinEdit = () => {
        setShowPin(!showPin);
    }

    const formPin = (values, handleChange, handleBlur, touched, errors) => {
        if (showPin) {
            return (
                <>
                    <Form className="register-form">

                        <InputGroup className="form-group-with-border">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-key-25" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="currentpin"
                                placeholder="4 digit current pin"
                                type="password"
                                inputMode="numeric"
                                maxLength="4"
                                value={values.currentpin}
                                onChange={handleChange('currentpin')}
                                onBlur={handleBlur('currentpin')}
                            />
                        </InputGroup>
                        {errors.currentpin && touched.currentpin && (
                            <div className="input-feedback">{errors.currentpin}</div>
                        )}

                        <InputGroup className="form-group-with-border">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-key-25" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="newpin"
                                placeholder="4 digit new pin"
                                type="password"
                                inputMode="numeric"
                                value={values.newpin}
                                maxLength="4"
                                onChange={handleChange('newpin')}
                                onBlur={handleBlur('newpin')}
                            />
                        </InputGroup>
                        {errors.newpin && touched.newpin && (
                            <div className="input-feedback">{errors.newpin}</div>
                        )}

                        <InputGroup className="form-group-with-border">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-key-25" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="confirmpin"
                                placeholder="Confirm new pin"
                                type="password"
                                inputMode="numeric"
                                maxLength="4"
                                value={values.confirmpin}
                                onChange={handleChange('confirmpin')}
                                onBlur={handleBlur('confirmpin')}
                            />
                        </InputGroup>
                        {errors.confirmpin && touched.confirmpin && (
                            <div className="input-feedback">{errors.confirmpin}</div>
                        )}
                    </Form>
                </>
            );
        }

    };

    const buttonPin = (values, setFieldValue, isValid) => {
        if (showPin) {
            return (
                <div className="myAccountSubmit">
                    <div>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleSubmitForm(values, 'changepin', setFieldValue, isValid)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Submit&nbsp;</span>
                        </Button>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                        <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleCancel('pincancel', setFieldValue)}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Cancel&nbsp;</span>
                        </Button>
                    </div>
                </div>
            );
        } else { }
    }

    const confirmDelete = () => {
        showDeleteModal('Are you sure you want to delete your account. All your data will be lost forever');
    }

    const handleCancel = (type, setFieldValue) => {
        if (type == 'namecancel') {
            setNameEditable(false);
            setNameSubmit(false);
        }
        if (type == 'phonecancel') {
            setFieldValue('otp', '');
            setPhoneEditable(false);
            setPhoneSubmit(false);
            setShowOtp(false);
        }
        if (type == 'addphonecancel') {
            setFieldValue('otp', '');
            setPhoneEditable(false);
            setPhoneSubmit(false);
            setShowOtp(false);
            setShowAddphone(false);
            setShowAddButtonPhone(true);
        }
        if (type == 'emailcancel') {
            setFieldValue('otp', '');
            setEmailEditable(false);
            setEmailSubmit(false);
            setShowOtp(false);
        }
        if (type == 'addemailcancel') {
            setFieldValue('otp', '');
            setEmailEditable(false);
            setEmailSubmit(false);
            setShowOtp(false);
            setShowAddemail(false);
            setShowAddButton(true);
        }
        if (type == 'otpcancel') {
            setFieldValue('otp', '');
            if (userDetails.email == '') {
                setPhoneEditable(false);
                setPhoneSubmit(false);
                setEmailEditable(false);
                setEmailSubmit(false);
                setShowOtp(false);
                setShowAddemail(false);
                setShowAddButton(true);
            } else {
                setPhoneEditable(false);
                setPhoneSubmit(false);
                setEmailEditable(false);
                setEmailSubmit(false);
                setShowOtp(false);
            }

            if (userDetails.phone == '') {
                setPhoneEditable(false);
                setPhoneSubmit(false);
                setEmailEditable(false);
                setEmailSubmit(false);
                setShowOtp(false);
                setShowAddphone(false);
                setShowAddButtonPhone(true);
            } else {
                setPhoneEditable(false);
                setPhoneSubmit(false);
                setEmailEditable(false);
                setEmailSubmit(false);
                setShowOtp(false);
            }

        }
        if (type == 'pincancel') {
            setFieldValue('currentpin', '');
            setFieldValue('newpin', '');
            setFieldValue('confirmpin', '');
            setShowPin(false);
        }
        fetchData();
    }

    const handleSubmitForm = async (values, type, setFieldValue = null, isValid) => {
        try {

            let url = '/updateuser';
            let data = '';
            if (type == 'namesubmit') {
                formRef.current.setTouched({ fullName: true });
                let res = await formRef.current.validateForm();
                if (res.fullName === undefined) {
                    data = {
                        data: {
                            name: values.fullName,
                        }
                    };
                }
            }
            if (type == 'phonesubmit' || (type == 'resendotp' && formOtpText == 'Enter OTP sent to old number')) {
                formRef.current.setTouched({ phoneNumber: true });
                let res = await formRef.current.validateForm();
                if (res.phoneNumber === undefined) {
                    data = {
                        data: {
                            phone: values.phoneNumber,
                            type: 'old'
                        }
                    };
                }
            }

            if (type == 'verifyotp' && formOtpText == 'Enter OTP sent to old number') {
                formRef.current.setTouched({ otp: true, phoneNumber: true });
                let res = await formRef.current.validateForm();
                if (res.otp === undefined && res.phoneNumber === undefined) {
                    data = {
                        data: {
                            phone: values.phoneNumber,
                            type: 'verifyoldphoneotp',
                            otp: values.otp
                        }
                    };
                }
            }

            if (type == 'resendotp' && formOtpText == 'Enter OTP sent to new number') {
                formRef.current.setTouched({ phoneNumber: true });
                let res = await formRef.current.validateForm();
                if (res.phoneNumber === undefined) {
                    data = {
                        data: {
                            phone: values.phoneNumber,
                            type: 'resendnewphoneotp',
                        }
                    };
                }
            }

            if (type == 'verifyotp' && formOtpText == 'Enter OTP sent to new number') {
                formRef.current.setTouched({ otp: true, phoneNumber: true });
                let res = await formRef.current.validateForm();
                if (res.otp === undefined && res.phoneNumber === undefined) {
                    data = {
                        data: {
                            phone: values.phoneNumber,
                            type: 'verifynewphoneotp',
                            otp: values.otp
                        }
                    };
                }
            }

            if (type == 'addphonesubmit') {
                formRef.current.setTouched({ phoneNumber: true });
                let res = await formRef.current.validateForm();
                if (res.phoneNumber === undefined) {
                    data = {
                        data: {
                            phone: values.phoneNumber,
                            type: 'addphone'
                        }
                    };
                }
            }

            if (type == 'addemailsubmit') {
                formRef.current.setTouched({ email: true });
                let res = await formRef.current.validateForm();

                if (res.email === undefined) {
                    data = {
                        data: {
                            email: values.email,
                            type: 'addemail'
                        }
                    };
                }
            }

            if (type == 'emailsubmit' || (type == 'resendotp' && formOtpText == 'Enter OTP sent to old email')) {
                formRef.current.setTouched({ email: true });
                let res = await formRef.current.validateForm();
                if (res.email === undefined) {
                    data = {
                        data: {
                            email: values.email,
                            type: 'old'
                        }
                    };
                }
            }

            if (type == 'verifyotp' && formOtpText == 'Enter OTP sent to old email') {
                formRef.current.setTouched({ otp: true, email: true });
                let res = await formRef.current.validateForm();
                if (res.otp === undefined && res.email === undefined) {
                    data = {
                        data: {
                            email: values.email,
                            type: 'verifyoldemailotp',
                            otp: values.otp
                        }
                    };
                }
            }

            if (type == 'resendotp' && formOtpText == 'Enter OTP sent to new email') {
                formRef.current.setTouched({ email: true });
                let res = await formRef.current.validateForm();
                if (res.email === undefined) {
                    data = {
                        data: {
                            email: values.email,
                            type: 'resendnewemailotp',
                        }
                    };
                }
            }

            if (type == 'verifyotp' && formOtpText == 'Enter OTP sent to new email') {
                formRef.current.setTouched({ otp: true, email: true });
                let res = await formRef.current.validateForm();
                if (res.otp === undefined && res.email === undefined) {
                    data = {
                        data: {
                            email: values.email,
                            type: 'verifynewemailotp',
                            otp: values.otp
                        }
                    };
                }
            }

            if (type == 'changepin') {
                formRef.current.setTouched({ currentpin: true, newpin: true, confirmpin: true });
                let res = await formRef.current.validateForm();
                if (res.currrentpin === undefined && res.newpin === undefined && res.confirmpin === undefined) {
                    data = {
                        data: {
                            currentpin: values.currentpin,
                            type: 'changepin',
                            newpin: values.newpin
                        }
                    };
                }
            }


            if (data) {
                setShowSpinner(true);
                const response = await authAxios.post(url, data);

                if (typeof response.data.error === 'undefined' && typeof response.data.data !== 'undefined') {
                    let result = response.data;
                    if (type == 'namesubmit') {
                        setNameEditable(false);
                        setNameSubmit(false);
                        setShowSpinner(false);
                        showModal("Updated name successfully!!");
                    }
                    if (type == 'phonesubmit' || (type == 'resendotp' && formOtpText == 'Enter OTP sent to old number')) {
                        setPhoneSubmit(false);
                        setShowOtp(true);
                        setFormOtpText('Enter OTP sent to old number');
                        setShowSpinner(false);
                        showModal("OTP is sent to your old number");
                    }
                    if (type == 'verifyotp' && formOtpText == 'Enter OTP sent to old number') {
                        setFieldValue('otp', '');
                        setFormOtpText('Enter OTP sent to new number');
                        setShowSpinner(false);
                        showModal("OTP verified successfully. Another OTP is sent to your new number");
                    } else if (type == 'resendotp' && formOtpText == 'Enter OTP sent to new number') {
                        setFieldValue('otp', '');
                        setFormOtpText('Enter OTP sent to new number');
                        setShowSpinner(false);
                        showModal("OTP is sent to your new number");
                    } else if (type == 'verifyotp' && formOtpText == 'Enter OTP sent to new number') {
                        setFieldValue('otp', '');
                        setPhoneSubmit(false);
                        setShowOtp(false);
                        setFormOtpText('');
                        setPhoneEditable(false);
                        showModal("Updated phone number successfully!!");
                        setShowSpinner(false);
                    }

                    if (type == 'emailsubmit' || (type == 'resendotp' && formOtpText == 'Enter OTP sent to old email')) {
                        setEmailSubmit(false);
                        setShowOtp(true);
                        setFormOtpText('Enter OTP sent to old email');
                        setShowSpinner(false);
                        showModal("OTP is sent to your old email");
                    }
                    if (type == 'verifyotp' && formOtpText == 'Enter OTP sent to old email') {
                        setFieldValue('otp', '');
                        setFormOtpText('Enter OTP sent to new email');
                        setShowSpinner(false);
                        showModal("OTP verified successfully. Another OTP is sent to your new email");
                    } else if (type == 'resendotp' && formOtpText == 'Enter OTP sent to new email') {
                        setFieldValue('otp', '');
                        setFormOtpText('Enter OTP sent to new email');
                        setShowSpinner(false);
                        showModal("OTP is sent to your new email");
                    } else if (type == 'verifyotp' && formOtpText == 'Enter OTP sent to new email') {
                        setFieldValue('otp', '');
                        setEmailSubmit(false);
                        setShowOtp(false);
                        setFormOtpText('');
                        setEmailEditable(false);
                        showModal("Updated email successfully!!");
                        setShowSpinner(false);
                    }

                    if (type == 'addphonesubmit') {
                        setPhoneSubmit(false);
                        setShowOtp(true);
                        setFormOtpText('Enter OTP sent to new number');
                        setShowSpinner(false);
                        showModal("OTP is sent to your new number");
                    }

                    if (type == 'addemailsubmit') {
                        setEmailSubmit(false);
                        setShowOtp(true);
                        setFormOtpText('Enter OTP sent to new email');
                        setShowSpinner(false);
                        showModal("OTP is sent to your new email");
                    }

                    if (type == 'changepin') {
                        setShowPin(false);
                        setShowSpinner(false);
                        showModal("Updated pin successfully!!");
                    }

                } else {
                    setShowSpinner(false);
                    throw (response.data.error === undefined) ? 'Server error. Please re-submit or re-login' : response.data.error;
                }
            } else {
                throw "Input error occurred. Please check the fields and resubmit";
            }

        } catch (error) {

            if (typeof error.message === 'undefined') {
                showModal(error);
            } else {
                showModal(error.message);
            }

        }

    }

    const fetchData = async () => {
        try {
            if (formRef.current)
                formRef.current.resetForm();

            setIsLoading(true);
            let url;

            const response = await authAxios.get('/user');
            var responseData = [];
            let result = response.data;

            if (result.data) {
                if (result.data.email != '') {
                    setUserDetails(result.data);
                    setShowAddemail(false);
                } else {
                    setUserDetails(result.data);
                }


                if (result.data.phone != '') {
                    setUserDetails(result.data);
                    setShowAddphone(false);
                } else {
                    setUserDetails(result.data);
                }


            } else {
                throw "Server error. Unable to fetch data";
            }

            setIsLoading(false);
        } catch (error) {
            showModal("Server Error. Please refresh or relogin");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <AddetailsSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            {!isLoading && (
                                <>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <Formik
                                                validationSchema={signUpValidationSchema}
                                                enableReinitialize={true}
                                                initialValues={{
                                                    fullName: (userDetails.name) ? userDetails.name : '',
                                                    email: (userDetails.email) ? userDetails.email : '',
                                                    phoneNumber: (userDetails.phone) ? userDetails.phone : '',
                                                    currentpin: '',
                                                    newpin: '',
                                                    confirmpin: '',
                                                    otp: ''
                                                }}
                                                innerRef={formRef}
                                                validateOnChange={true}
                                            >
                                                {({
                                                    handleChange,
                                                    handleSubmit,
                                                    handleBlur,
                                                    isValid,
                                                    setFieldValue,
                                                    setFieldTouched,
                                                    values,
                                                    touched,
                                                    errors
                                                }) => (
                                                    <>
                                                        <Card className="card-register" style={{ minHeight: "unset" }}>
                                                            <div className="myAccountEdit">
                                                                <div className="myAccountEditLeft">
                                                                    <h4 className="title mx-auto">Name</h4>
                                                                </div>
                                                                <div className="myAccountEditRight">
                                                                    <Button
                                                                        className="btn-round btn-createad"
                                                                        color="primary"
                                                                        onClick={() => nameEdit()}
                                                                    >
                                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Edit&nbsp;</span>
                                                                    </Button>
                                                                </div>

                                                            </div>

                                                            <Form className="register-form">
                                                                <InputGroup className="form-group-with-border">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="nc-icon nc-single-02" />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        id="fullName"
                                                                        placeholder="Full Name or Business Name"
                                                                        type="text"
                                                                        value={values.fullName}
                                                                        onChange={handleChange('fullName')}
                                                                        onBlur={handleBlur('fullName')}
                                                                        disabled={!nameEditable}
                                                                    />
                                                                </InputGroup>
                                                                {errors.fullName && touched.fullName && (
                                                                    <div className="input-feedback">{errors.fullName}</div>
                                                                )}

                                                            </Form>
                                                            <div className="myAccountSubmit">
                                                                <div>
                                                                    {showNameSubmit(values, isValid)}
                                                                </div>
                                                                <div style={{ marginLeft: "10px" }}>
                                                                    {showNameCancel(setFieldValue)}
                                                                </div>
                                                            </div>
                                                        </Card>

                                                        <Card className="card-register" style={{ minHeight: "unset" }}>
                                                            {showPhoneEdit()}
                                                            {showLoginPhoneCheckBox(values, handleChange, handleBlur, touched, errors)}
                                                            {showAddPhoneFunc(values, handleChange, handleBlur, touched, errors)}
                                                            {showPhoneSubmitCancel(values, isValid, setFieldValue)}
                                                        </Card>

                                                        {showOtpCard(values, handleChange, handleBlur, touched, errors, setFieldValue, isValid)}

                                                        <Card className="card-register" style={{ minHeight: "unset" }}>
                                                            {showEmailEdit()}
                                                            {showLoginEmailCheckBox(values, handleChange, handleBlur, touched, errors)}
                                                            {showAddEmailFunc(values, handleChange, handleBlur, touched, errors)}
                                                            {showEmailSubmitCancel(values, isValid, setFieldValue)}
                                                        </Card>

                                                        <Card className="card-register" style={{ minHeight: "unset" }}>
                                                            <div className="myAccountEdit">
                                                                <div className="myAccountEditLeft">
                                                                    <h4 className="title mx-auto">Pin</h4>
                                                                </div>
                                                                <div className="myAccountEditRight">
                                                                    <Button
                                                                        className="btn-round btn-createad"
                                                                        color="primary"
                                                                        onClick={() => pinEdit()}
                                                                    >
                                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Change Pin&nbsp;</span>
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                            {formPin(values, handleChange, handleBlur, touched, errors)}
                                                            {buttonPin(values, setFieldValue, isValid)}
                                                        </Card>

                                                        <Card className="card-register" style={{ minHeight: "unset" }}>
                                                            <div className="myAccountEdit">
                                                                <div className="myAccountEditLeft">
                                                                    <h4 className="title mx-auto">Delete Account</h4>
                                                                </div>
                                                                <div className="myAccountEditRight">
                                                                    <Button
                                                                        className="btn-round btn-createad"
                                                                        color="primary"
                                                                        onClick={() => confirmDelete()}
                                                                    >
                                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Delete Account&nbsp;</span>
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        </Card>

                                                    </>
                                                )}
                                            </Formik>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    <ADModal
                        isVisible={isDeleteModalVisible}
                        msg={deleteModalMsg}
                        children={renderDeleteButtons()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />
        </>
    );
}

export default Account;