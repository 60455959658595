import React from "react";
import {
    Modal
} from "reactstrap";

const ADModal = ({
    isVisible = false,
    msg,
    children,
    ...props
}) => {


    return (
        <Modal isOpen={isVisible} centered={true} {...props}>
            <div className="modal-header">
                <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                >
                    Adkewl
                </h5>
            </div>
            <div className="modal-body">
                {msg}
            </div>
            <div className="modal-footer Admodal">
                {children}
            </div>
        </Modal>

    );
};

export default ADModal;