import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, json } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    CustomInput,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import AddetailsSkeleton from "../skeleton-screens/AddetailsSkeleton";
import ADModal from '../components/ADModal';
import Adstatus from '../components/Adstatus';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

function Addetails() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { adid, status } = state;
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [modalLoc, setModalLoc] = useState(false);
    const [modalLocFlagged, setModalLocFlagged] = useState(false);

    const [dataAdDetails, setDataAdDetails] = useState([]);
    const [dataLocations, setDataLocations] = useState([]);
    const [dataLocationsSelected, setDataLocationsSelected] = useState([]);
    const [dataLocationsFlagged, setDataLocationsFlagged] = useState([]);
    const [dataTypeDropdown, setDataTypeDropdown] = useState([]);
    const [valueTypeDropdown, setValueTypeDropdown] = useState(null);
    const [dataAreaDropdown, setDataAreaDropdown] = useState([]);
    const [valueAreaDropdown, setValueAreaDropdown] = useState(null);
    const [dataCityDropdown, setDataCityDropdown] = useState([]);
    const [valueCityDropdown, setValueCityDropdown] = useState(null);
    const [visible, setVisible] = useState(false);
    const [locError, setLocError] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [estimatedImpressions, setEstimatedImpressions] = useState(0);
    const [cost, setCost] = useState(0);
    const [actualcost, setActualCost] = useState(0);
    const [discountMsg, setDiscountMsg] = useState(null);
    const [buttonTitle, setButtonTitle] = useState('Submit for Approval');

    const formRef = React.createRef();
    let dataLocFromServer = useRef([]);

    const signUpValidationSchema = yup.object().shape({
        adname: yup
            .string()
            .matches(/\w.+/, 'Enter ad campaign name')
            .min(3, ({ min }) => `Name must be at least ${min} characters`)
            .required('Ad campaign name is required'),
        duration: yup
            .number().test(
                "minValue",
                "Must be greater than 0",
                (number) => number > 0
            )
            .required('Enter duration period'),
        durationswitchDays: yup
            .boolean()
            .when(['durationswitchWeeks', 'durationswitchMonths'], {
                is: (durationswitchWeeks, durationswitchMonths) => !durationswitchWeeks && !durationswitchMonths,
                then: yup.boolean().oneOf([true], 'Select duration type above')
            }),
        durationswitchWeeks: yup
            .boolean()
            .when(['durationswitchDays', 'durationswitchMonths'], {
                is: (durationswitchDays, durationswitchMonths) => !durationswitchDays && !durationswitchMonths,
                then: yup.boolean().oneOf([true], 'Select duration type above')
            }),
        durationswitchMonths: yup
            .boolean()
            .when(['durationswitchWeeks', 'durationswitchDays'], {
                is: (durationswitchWeeks, durationswitchDays) => !durationswitchWeeks && !durationswitchDays,
                then: yup.boolean().oneOf([true], 'Select duration type above')
            }),
    }, [['durationswitchWeeks', 'durationswitchMonths'], ['durationswitchDays', 'durationswitchMonths'], ['durationswitchWeeks', 'durationswitchDays']])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("Addetails-page");
        return function cleanup() {
            document.body.classList.remove("Addetails-page");
        };
    });

    useEffect(() => {
    }, [dataLocations]);

    useEffect(() => {
    }, [dataLocationsSelected]);

    useEffect(() => {
    }, [estimatedImpressions]);

    useEffect(() => {
    }, [cost]);

    useEffect(() => {
    }, [actualcost]);


    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const toggleModal = () => {
        setModalLoc(!modalLoc);
    };

    const toggleModalFlagged = () => {
        setModalLocFlagged(!modalLocFlagged);
    };


    const renderButtonTitle = () => {
        if (status == "in active") {
            setButtonTitle('Restart & Submit for Approval ');
        }
        if (status == "not approved") {
            setButtonTitle('Resubmit for Approval ');
        }
        if (status == "incomplete") {
            setButtonTitle('Submit for Approval ');
        }
    };

    const calculatorDuration = (name, value, values) => {
        let numLocations = dataLocationsSelected.length;
        let numDays = 0;

        if (name == 'duration') {
            if (value != '') {
                numDays = value;
            }
            if (values.durationswitchWeeks) {
                numDays = 7 * numDays;
            } else if (values.durationswitchMonths) {
                numDays = 30 * numDays;
            }
        }

        if (name == 'durationswitchDays' || name == 'durationswitchWeeks' || name == 'durationswitchMonths') {
            if (values.duration != '') {
                numDays = values.duration;
            }
            if (name == 'durationswitchWeeks' && value) {
                numDays = 7 * numDays;
            } else if (name == 'durationswitchMonths' && value) {
                numDays = 30 * numDays;
            }
        }

        let impressions = numLocations * 420 * numDays;
        let locTotal = dataLocationsSelected.reduce((n, { price }) => n + price, 0)
        let actualcostT = locTotal * numDays;
        let costT = actualcostT;
        let discmsg = '';
        if (numDays >= 30) {
            discmsg = '25% discount applied';
            costT = actualcostT - (actualcostT * 0.25);
        } else if (numDays >= 15) {
            discmsg = '15% discount applied';
            costT = actualcostT - (actualcostT * 0.15);
        } else if (numDays >= 7) {
            discmsg = '10% discount applied';
            costT = actualcostT - (actualcostT * 0.1);
        } else {
            discmsg = '';
        }
        if (costT <= 0) {
            discmsg = '';
        }
        setEstimatedImpressions(impressions);
        setActualCost(actualcostT);
        setCost(costT);
        setDiscountMsg(discmsg);
    };

    const calculator = (values) => {
        let numLocations = dataLocationsSelected.length;
        let numDays = 0;
        if (values.duration != '') {
            numDays = values.duration;
        }
        if (values.durationswitchWeeks) {
            numDays = 7 * numDays;
        } else if (values.durationswitchMonths) {
            numDays = 30 * numDays;
        }
        let impressions = numLocations * 420 * numDays;
        let locTotal = dataLocationsSelected.reduce((n, { price }) => n + price, 0)

        let actualcostT = locTotal * numDays;
        let costT = actualcostT;
        let discmsg = '';
        if (numDays >= 30) {
            discmsg = '25% discount applied';
            costT = actualcostT - (actualcostT * 0.25);
        } else if (numDays >= 15) {
            discmsg = '15% discount applied';
            costT = actualcostT - (actualcostT * 0.15);
        } else if (numDays >= 7) {
            discmsg = '10% discount applied';
            costT = actualcostT - (actualcostT * 0.1);
        } else {
            discmsg = '';
        }
        if (costT <= 0) {
            discmsg = '';
        }
        setEstimatedImpressions(impressions);
        setActualCost(actualcostT);
        setCost(costT);
        setDiscountMsg(discmsg);
    };


    const setvalueTypeDropdownFunc = (value) => {
        setValueTypeDropdown(value);
    }

    const setvalueAreaDropdownFunc = (value) => {
        setValueAreaDropdown(value);
    }

    const setvalueCityDropdownFunc = (value) => {
        setValueCityDropdown(value);
    }

    const clearDropdowns = () => {
        setValueCityDropdown(null);
        setValueAreaDropdown(null);
        setValueTypeDropdown(null);
    }

    const renderFormSubmitLocError = () => {
        if (locError) {
            return (
                <div className="input-feedback">Please select location(s)</div>
            );
        }
    };

    const deleteSelection = (locid, values) => {
        let dataSelected = dataLocationsSelected;
        let data = dataLocations;
        let indexDataSelected = dataSelected.findIndex(x => x.locid === locid);
        dataSelected.splice(indexDataSelected, 1);

        setDataLocationsSelected(dataSelected);

        let indexData = data.findIndex(x => x.locid === locid);
        if (indexData != -1) {
            data[indexData].checked = false;
            setDataLocations(data);
        }
        calculator(values);
    };

    const selectAllFunc = (values) => {
        setSelectAll(!selectAll);
        let data = dataLocations;
        let dataSelected = dataLocationsSelected;

        if (!selectAll) {
            data.map((locData) => {
                if (locData.block == 'no') {
                    locData.checked = true;
                    let checkLocId = obj => obj.locid == locData.locid;
                    if (!dataSelected.some(checkLocId)) {
                        dataSelected.push(locData);
                    }
                }
                
            });
        }

        if (selectAll) {
            data.map((locData) => {
                if (locData.block == 'no') {
                    locData.checked = false;
                    let indexDataSelected = dataSelected.findIndex(x => x.locid === locData.locid);
                    if (indexDataSelected != -1) {
                        dataSelected.splice(indexDataSelected, 1);
                    }
                }
                
            });
        }

        setDataLocations(data);
        setDataLocationsSelected(dataSelected);
        calculator(values);
    };

    const searchLocations = (filter) => {
        let data = dataLocFromServer.current.filter(item => {
            for (let key in filter) {
                if (filter[key] !== null && item[key] != filter[key])
                    return false;
            }
            return true;
            //item.city == filter.city
        });
        let dataSelected = dataLocationsSelected;
        data.map((locData) => {
            let checkLocId = obj => obj.locid == locData.locid;
            if (dataSelected.some(checkLocId)) {
                locData.checked = true;
            } else {
                locData.checked = false;
            }
        });
        setDataLocations(data);
        setSelectAll(false);
    }

    const onCheckChanged = (id, values) => {
        const data = dataLocations;
        let dataSelected = dataLocationsSelected;
        const index = data.findIndex(x => x.locid === id);
        data[index].checked = !data[index].checked;

        if (data[index].checked) {
            const checkLocId = obj => obj.locid == data[index].locid;
            if (!dataSelected.some(checkLocId)) {
                dataSelected.push(data[index]);
            }
        }

        if (!data[index].checked) {
            let indexDataSelected = dataSelected.findIndex(x => x.locid === id);
            dataSelected.splice(indexDataSelected, 1);
        }

        if (dataSelected.length != 0) {
            setLocError(false);
        }
        setDataLocations(data);
        setDataLocationsSelected(dataSelected);
        calculator(values);

    };

    const renderReasonButton = () => {
        if (status == "not approved") {
            return (
                <>
                    <div className="adDetailsReason" onClick={() => setModalLocFlagged(true)}>
                        <span style={{ textDecorationLine: "underline", fontWeight: "bold", color: "#4d79ff", cursor: 'pointer' }}>Reasons for not approving</span>
                    </div>
                </>
            );
        }
    }

    const renderFlaggedLocations = () => {
        return dataLocationsFlagged.map((locData, index) => {
            return (
                <div className="locationItemSelected" key={index}>
                    <div className="locationItemSelectedContent">
                        <div className="locationItemSelectedtTop">
                            <span>{locData.type}   ({locData.size}&Prime;)  ({locData.price})</span>
                        </div>
                        <div className="locationItemSelectedBottom">
                            <span>{locData.area}, {locData.city}, {locData.zipcode}</span>
                        </div>
                    </div>
                    <div className="locationItemDelete">

                    </div>
                </div>
            );

        });
    };

    const renderSelectedLocations = () => {
        return dataLocationsSelected.map((locData, index) => {
            if (locData.checked) {
                return (
                    <div className="locationItemSelected" key={index}>
                        <div className="locationItemSelectedContent">
                            <div className="locationItemSelectedtTop">
                                <span>{locData.type}   ({locData.size}&Prime;)  ({locData.price})</span>
                            </div>
                            <div className="locationItemSelectedBottom">
                                <span>{locData.area}, {locData.city}, {locData.zipcode}</span>
                            </div>
                        </div>
                        <div className="locationItemDelete">
                            <div onClick={() => deleteSelection(locData.locid, formRef.current.values)}><i className="nc-icon nc-simple-remove"></i></div>
                        </div>
                    </div>
                );
            }

        });
    };

    const slideLeft = (id) => {
        var slider = document.getElementById('slider' + id);
        slider.scrollLeft = slider.scrollLeft - 210;
    }

    const slideRight = (id) => {
        var slider = document.getElementById('slider' + id);
        slider.scrollLeft = slider.scrollLeft + 210;
    }

    const renderLocationImages = (dataImages) => {
        return dataImages.map((images, indexi) => {
            return (
                <div className="locationItemContentImageDiv">
                    <img
                        src={images.img}
                        className="locationItemContentImagesItem"
                    />
                </div>
            );
        });
    }

    const renderLocations = () => {
        return dataLocations.map((locData, index) => {
            if (locData.block == 'no') {
                return (
                    <div className="locationItem" key={index}>
                        <div className="locationItemCheckbox">
                            <FormGroup check>
                                <Label check>
                                    <Input checked={locData.checked} type="checkbox" onChange={() => onCheckChanged(locData.locid, formRef.current.values)} /><span className="form-check-sign" />
                                </Label>
                            </FormGroup>
                        </div>
                        <div className="locationItemContent">
                            <div className="locationItemContentTop">
                                <span>{locData.type}  ({locData.size}&Prime;)  ({locData.price})</span>
                            </div>
                            <div className="locationItemContentBottom">
                                <span>{locData.area}, {locData.city}, {locData.zipcode}</span>
                            </div>
                            <div className="locationItemContentImagesParent">
                                {(typeof locData.images !== 'undefined' && locData.images.length > 0) && (
                                    <div className="locationImageChevron"><MdChevronLeft size={40} onClick={()=>slideLeft(index)} /></div>
                                )}
                                <div id={"slider"+index} className="locationItemContentImages">
                                    {renderLocationImages(locData.images)}
                                </div>
                                {(typeof locData.images !== 'undefined' && locData.images.length > 0) && (
                                    <div className="locationImageChevron"><MdChevronRight size={40} onClick={()=>slideRight(index)} /></div>
                                )}
    
                            </div>
                        </div>
                    </div>
                );
            }
            if (locData.block == 'yes') {
                return (
                    <div style={{opacity: '0.7'}}>
                    <div className="locationItem" key={index}>
                        <div className="locationItemCheckbox"></div>
                        <div className="locationItemContent">
                            <div className="locationItemContentTop">
                                <span>{locData.type}  ({locData.size}&Prime;)  ({locData.price})</span>
                            </div>
                            <div className="locationItemContentBottom">
                                <span>{locData.area}, {locData.city}, {locData.zipcode}</span>
                            </div>
                            <div className="locationItemContentBottom">
                                <span style={{fontSize: 'small', color: '#767676', fontWeight: '500' }}>
                                <span style={{fontSize: 'small', color: '#b33c12', fontWeight: '500' }}>Not available.&nbsp;</span>                                 
                                Running at full capacity</span>
                            </div>
                            <div className="locationItemContentBottom">
                                    <span style={{fontSize: 'small', color: '#767676', fontWeight: '500' }}>Next available date:&nbsp; 
                                        <span style={{fontSize: 'small', color: '#b33c12', fontWeight: '500' }}>{locData.avldate}</span>
                                    </span>
                            </div>
                            <div className="locationItemContentImagesParent">
                                {(typeof locData.images !== 'undefined' && locData.images.length > 0) && (
                                    <div className="locationImageChevron"><MdChevronLeft size={40} onClick={()=>slideLeft(index)} /></div>
                                )}
                                <div id={"slider"+index} className="locationItemContentImages">
                                    {renderLocationImages(locData.images)}
                                </div>
                                {(typeof locData.images !== 'undefined' && locData.images.length > 0) && (
                                    <div className="locationImageChevron"><MdChevronRight size={40} onClick={()=>slideRight(index)} /></div>
                                )}
    
                            </div>
                        </div>
                    </div>
                    </div>
                );
            }
            
        });
    };

    const renderThumbnail = () => {
        if (dataAdDetails.adtype == "img") {
            return (
                    <img
                        src={dataAdDetails.img ? dataAdDetails.img : dataAdDetails.vista_img}
                        className="adDetailsImgContain"
                    />
            );
        }
        if (dataAdDetails.adtype == "video") {
            let srcType = "video/mp4";
            // if (dataAdDetails.img == null) {
            //     srcType = "video/mp4";
            // }
            return (
                    <video className="adDetailsImgContain" controls>
                        <source src={dataAdDetails.img ? dataAdDetails.img : dataAdDetails.vista_img} type={srcType} />
                    </video>
            );
        }
    };

    const renderDesignCost = () => {
        if (dataAdDetails.designcost > 0) {
            return (
                <div className="cost">
                    <div className="costLeft">
                        <span><span style={{ color: "#b33c12" }}>+</span> Design Cost:&nbsp;</span>
                    </div>
                    <div className="costRight">
                        <span>{dataAdDetails.designcost}</span>
                    </div>
                </div>
            );
        }

    }

    const renderDesignCostNote = () => {
        if (dataAdDetails.designcost > 0) {
            return (
                <p>
                    <span style={{ color: "#b33c12" }}>*</span> Discounts do not apply on design cost
                </p>
            );
        }
    }

    const handleSubmitForm = async () => {
        let values = formRef.current.values;
        let res = await formRef.current.validateForm();
        
        try {
            if (dataLocationsSelected.length == 0) {
                setLocError(true);
            } else if (Object.keys(res).length > 0) {
                throw "Please check all input fields";
            } else {
                setShowSpinner(true);
                let url = '/ads/updatead';
                let data = {
                    data: {
                        adid: adid,
                        status: status,
                        cost: cost,
                        impressions: estimatedImpressions,
                        addata: values,
                        locations: dataLocationsSelected
                    }
                };
                
                const response = await authAxios.post(url, data);

                let result = response.data;
                if (result.data == 'success') {
                    setShowSpinner(false);
                    navigate('/approval');
                } else {
                    throw "Server error. Please resubmit";
                }
            }
        } catch (error) {
            if (typeof error.message === 'undefined') {
                showModal(error);
            } else {
                showModal(error.message);
            }
            setShowSpinner(false);
        }

    }
    const editAd = (adid, status) => {
        navigate('/vceditor', { state: { adid: adid, status: status } });
    };

    const navToHelp = () => {
        navigate('/help');
    }

    const fetchData = async () => {
        try {
            
            setIsLoading(true);
            let url;
            if (adid != '') {
                url = '/location/ad/' + adid;
            } else {
                url = '/location';
            }
            const response = await authAxios.get(url);
            var responseData = [];
            let result = response.data;
            
            if (result.data) {
                responseData = result.data;

                setDataTypeDropdown(responseData.locTypeDropdown);
                setDataAreaDropdown(responseData.locAreaDropdown);
                setDataCityDropdown(responseData.locCityDropdown);

                let data = responseData.locations;
                let dataSelected = dataLocationsSelected;

                let adDetailsObject = (responseData.adDetails) ? responseData.adDetails : [];
                let numDays = 0;

                if (adDetailsObject.durationtype == 'days' || adDetailsObject.durationtype == 'weeks' || adDetailsObject.durationtype == 'months') {
                    if (adDetailsObject.duration != '') {
                        numDays = adDetailsObject.duration;
                    }
                    if (adDetailsObject.durationtype == 'weeks' && numDays > 0) {
                        numDays = 7 * numDays;
                    } else if (adDetailsObject.durationtype == 'months' && numDays > 0) {
                        numDays = 30 * numDays;
                    }
                }
                let discmsg = '';
                if (numDays >= 30) {
                    discmsg = '25% discount applied';
                } else if (numDays >= 15) {
                    discmsg = '15% discount applied';
                } else if (numDays >= 7) {
                    discmsg = '10% discount applied';
                } else {
                    discmsg = '';
                }
                if (adDetailsObject.cost <= 0) {
                    discmsg = '';
                }
                data.map((locData) => {
                    if (locData.checked === true && locData.block == 'no') {
                        dataSelected.push(locData);
                    }
                });
                dataLocFromServer.current = data;
                setDataLocationsSelected(dataSelected);
                setDataLocationsFlagged(responseData.locationsFlagged);
                setDataAdDetails(adDetailsObject);
                setDiscountMsg(discmsg);

                let initialValues = {
                    duration: (adDetailsObject.duration) ? adDetailsObject.duration.toString() : '7',
                    durationswitchDays: (adDetailsObject.durationtype == 'days') ? true : false,
                    durationswitchWeeks: (adDetailsObject.durationtype == 'weeks') ? true : false,
                    durationswitchMonths: (adDetailsObject.durationtype == 'months') ? true : false
                };                                    
                calculator(initialValues);
                //setEstimatedImpressions(adDetailsObject.estimatedImpressions);
                //setCost(adDetailsObject.cost);
            } else {
                throw "Server error. Unable to fetch data";
            }
            //setvalueCityDropdownFunc('Visakhapatnam');
            setValueCityDropdown({ "value": "Visakhapatnam", "label": "Visakhapatnam" });
            searchLocations({
                "city": "Visakhapatnam",
                "area": null,
                "type": null
            });
            
            setIsLoading(false);
        } catch (error) {
            
            showModal("Server Error. Please refresh or relogin");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        renderButtonTitle();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);
    

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <AddetailsSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            {!isLoading && (
                                <>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <div className="adDetailsContainer">
                                                <div className="adDetailsTitle">
                                                    <h4>{dataAdDetails.name}</h4>
                                                </div>
                                                <div className="adDetailsEdit">
                                                    <div className="adDetailsEditLeft">

                                                        <Button
                                                            className="btn-round btn-createad"
                                                            color="primary"
                                                            onClick={() => editAd(dataAdDetails.adid, dataAdDetails.status)}
                                                        >
                                                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Edit&nbsp;</span>
                                                        </Button>
                                                    </div>
                                                    <div className="adDetailsEditRight"></div>
                                                </div>
                                                <div className="adDetailsImg">
                                                    {renderThumbnail()}

                                                </div>
                                                <div className="adDetailsImgWidget">
                                                    <div className="adDetailsImgWidgetWeather">
                                                        <span style={{ color: "#ffffff" }}>Mumbai 25&deg;C, Partly Cloudy</span><i style={{ color: "#fff", paddingLeft: "5px", marginTop: "3px" }} className="nc-icon nc-cloud-upload-94" />
                                                    </div>
                                                    <div className="adDetailsImgWidgetTime">
                                                        <span style={{ color: "#ffffff" }}>Jun 5, 2023 4:00 Pm</span>
                                                    </div>

                                                </div>
                                                <div className="adDetailsStatus">
                                                    <Adstatus status={status} />
                                                </div>
                                                {renderReasonButton()}
                                                <div className="adDetailsInfo">
                                                    <p>
                                                        <span style={{ color: "#b33c12" }}>*</span> Rates per location vary as per size of the display and type of Ad- image or video
                                                    </p>
                                                    <p>
                                                        <span style={{ color: "#b33c12" }}>*</span> Get 10% discount for duration more than 7 days
                                                    </p>
                                                    <p>
                                                        <span style={{ color: "#b33c12" }}>*</span> Get 15% discount for duration more than 15 days
                                                    </p>
                                                    <p>
                                                        <span style={{ color: "#b33c12" }}>*</span> Get 25% discount for duration more than 30 days
                                                    </p>
                                                    <p>
                                                        <span style={{ color: "#b33c12" }}>*</span> For any more negotiation please write to us 
                                                        <span style={{ fontWeight: "bold", color: "#4d79ff", cursor: 'pointer' }} onClick={() => navToHelp()}> &nbsp;Help </span>
                                                                
                                                    </p>
                                                    {renderDesignCostNote()}
                                                </div>
                                                <div className="centerTextDivider">
                                                    <div className="centerTextDividerLine" />
                                                    <div className="centerTextDividerText">
                                                        <span>Campaign name & Duration</span>
                                                    </div>
                                                    <div className="centerTextDividerLine" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ml-auto mr-auto">
                                            <Formik
                                                validationSchema={signUpValidationSchema}
                                                enableReinitialize={true}
                                                initialValues={{
                                                    adname: (dataAdDetails.name) ? dataAdDetails.name : '',
                                                    duration: (dataAdDetails.duration) ? dataAdDetails.duration.toString() : '7',
                                                    durationswitchDays: (dataAdDetails.durationtype == 'days') ? true : false,
                                                    durationswitchWeeks: (dataAdDetails.durationtype == 'weeks') ? true : false,
                                                    durationswitchMonths: (dataAdDetails.durationtype == 'months') ? true : false
                                                }}
                                                innerRef={formRef}
                                                validateOnChange={true}
                                            >
                                                {({
                                                    handleChange,
                                                    handleSubmit,
                                                    handleBlur,
                                                    isValid,
                                                    setFieldValue,
                                                    setFieldTouched,
                                                    values,
                                                    touched,
                                                    errors
                                                }) => (
                                                    <>
                                                        <div className="AddetailsDurationContainer">
                                                            <Form className="register-form">
                                                                <div className="AddetailsDurationInput">

                                                                    <InputGroup className="form-group-with-border" style={{ width: "30%" }}>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="nc-icon nc-single-02" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            id="adname"
                                                                            placeholder="Enter Ad campaign name"
                                                                            type="text"
                                                                            maxLength="70"
                                                                            value={values?.adname}
                                                                            onChange={(value) => {
                                                                                setFieldValue('adname', value.target.value);
                                                                            }}
                                                                            onBlur={handleBlur('adname')}
                                                                        />
                                                                    </InputGroup>
                                                                    {errors.adname && touched.adname && (
                                                                        <div className="input-feedback">{errors.adname}</div>
                                                                    )}


                                                                    <InputGroup className="form-group-with-border" style={{ width: "30%" }}>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="nc-icon nc-time-alarm" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            id="duration"
                                                                            placeholder="Duration"
                                                                            type="number"
                                                                            maxLength="3"
                                                                            value={values?.duration}
                                                                            onChange={(value) => {
                                                                                setFieldValue('duration', value.target.value);
                                                                                calculatorDuration('duration', value.target.value, values);
                                                                            }}
                                                                            onBlur={handleBlur('duration')}
                                                                        />
                                                                    </InputGroup>
                                                                    {errors.duration && touched.duration && (
                                                                        <div className="input-feedback">{errors.duration}</div>
                                                                    )}

                                                                </div>
                                                                <div className="AddetailsDurationSwitch">
                                                                    <CustomInput
                                                                        type="switch"
                                                                        id="durationswitchDays"
                                                                        name="durationswitchDays"
                                                                        checked={values?.durationswitchDays}
                                                                        label="Days"
                                                                        onChange={(value) => {
                                                                            let targetValue = value.target.checked;
                                                                            setFieldValue('durationswitchDays', targetValue);
                                                                            setFieldValue('durationswitchWeeks', targetValue ? !targetValue : false);
                                                                            setFieldValue('durationswitchMonths', targetValue ? !targetValue : false);
                                                                            calculatorDuration('durationswitchDays', targetValue, values);
                                                                            setTimeout(() => setFieldTouched('durationswitchDays', true));
                                                                        }}
                                                                    />
                                                                    <CustomInput
                                                                        type="switch"
                                                                        id="durationswitchWeeks"
                                                                        name="durationswitchWeeks"
                                                                        checked={values?.durationswitchWeeks}
                                                                        label="Weeks"
                                                                        onChange={(value) => {
                                                                            let targetValue = value.target.checked;
                                                                            setFieldValue('durationswitchDays', targetValue ? !targetValue : false);
                                                                            setFieldValue('durationswitchWeeks', targetValue);
                                                                            setFieldValue('durationswitchMonths', targetValue ? !targetValue : false);
                                                                            calculatorDuration('durationswitchWeeks', targetValue, values);
                                                                            setTimeout(() => setFieldTouched('durationswitchWeeks', true));
                                                                        }}
                                                                    />
                                                                    <CustomInput
                                                                        type="switch"
                                                                        id="durationswitchMonths"
                                                                        name="durationswitchMonths"
                                                                        checked={values?.durationswitchMonths}
                                                                        label="Months"
                                                                        onChange={(value) => {
                                                                            let targetValue = value.target.checked;
                                                                            setFieldValue('durationswitchDays', targetValue ? !targetValue : false);
                                                                            setFieldValue('durationswitchWeeks', targetValue ? !targetValue : false);
                                                                            setFieldValue('durationswitchMonths', targetValue);
                                                                            calculatorDuration('durationswitchMonths', targetValue, values);
                                                                            setTimeout(() => setFieldTouched('durationswitchMonths', true));
                                                                        }}
                                                                    />
                                                                </div>
                                                                {((errors.durationswitchDays) ||
                                                                    (errors.durationswitchWeeks) ||
                                                                    (errors.durationswitchMonths)) && (
                                                                        <div className="input-feedback" style={{ textAlign: "left" }}>
                                                                            {
                                                                                errors.durationswitchDays ||
                                                                                errors.durationswitchWeeks ||
                                                                                errors.durationswitchMonths
                                                                            }
                                                                        </div>
                                                                    )}

                                                                <div className="centerTextDivider">
                                                                    <div className="centerTextDividerLine" />
                                                                    <div className="centerTextDividerText">
                                                                        <span>Locations</span>
                                                                    </div>
                                                                    <div className="centerTextDividerLine" />
                                                                </div>
                                                            </Form>
                                                        </div>
                                                        <div className="AddetailsLocationContainer">
                                                            <div className="AddetailsLocationDropdowns">
                                                                <Select
                                                                    className="reactSelectDropdown"
                                                                    classNamePrefix="reactSelectDropdownData"
                                                                    value={valueCityDropdown}
                                                                    onChange={setvalueCityDropdownFunc}
                                                                    options={dataCityDropdown}
                                                                    isSearchable={true}
                                                                    placeholder="City"
                                                                />
                                                                <Select
                                                                    className="reactSelectDropdown"
                                                                    classNamePrefix="reactSelectDropdownData"
                                                                    value={valueAreaDropdown}
                                                                    onChange={setvalueAreaDropdownFunc}
                                                                    options={dataAreaDropdown}
                                                                    isSearchable={true}
                                                                    placeholder="Area"
                                                                />
                                                                <Select
                                                                    className="reactSelectDropdown"
                                                                    classNamePrefix="reactSelectDropdownData"
                                                                    value={valueTypeDropdown}
                                                                    onChange={setvalueTypeDropdownFunc}
                                                                    options={dataTypeDropdown}
                                                                    isSearchable={true}
                                                                    placeholder="Type"
                                                                />
                                                            </div>
                                                            <div className="AddetailsLocationFilters">
                                                                <div style={{ width: "20%" }}>
                                                                    <Button
                                                                        className="btn-round btn-createad"
                                                                        color="primary"
                                                                        onClick={() => searchLocations({
                                                                            "city": valueCityDropdown ? valueCityDropdown.value : null,
                                                                            "area": valueAreaDropdown ? valueAreaDropdown.value : null,
                                                                            "type": valueTypeDropdown ? valueTypeDropdown.value : null
                                                                        })}
                                                                    >
                                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Filter&nbsp;</span>
                                                                    </Button>
                                                                </div>
                                                                <div style={{ width: "20%", marginLeft: "10px" }}>
                                                                    <Button
                                                                        className="btn-round btn-createad"
                                                                        color="primary"
                                                                        onClick={() => clearDropdowns()}
                                                                    >
                                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Clear&nbsp;</span>
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                            <div className="AddetailsLocationAllModal">
                                                                <div className="AddetailsLocationSelectall">
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input checked={selectAll} type="checkbox" onChange={() => selectAllFunc(values)} /><span className="form-check-sign" />
                                                                            <span style={{ color: '#0b1011' }}>Select All</span>
                                                                        </Label>
                                                                    </FormGroup>

                                                                </div>
                                                                <div className="AddetailsLocationsModal" onClick={() => setModalLoc(true)}>
                                                                    <span style={{ textDecorationLine: "underline", fontWeight: "bold", color: "#4d79ff", cursor: 'pointer' }}>View or remove selection</span>
                                                                </div>
                                                            </div>
                                                            <div className="AddetailsLocations">
                                                                {renderLocations()}
                                                            </div>

                                                        </div>

                                                    </>
                                                )}
                                            </Formik>
                                        </Col>
                                    </Row>
                                    <Modal isOpen={modalLoc} centered={true} toggle={toggleModal}>
                                        <div className="modal-header">
                                            <button
                                                aria-label="Close"
                                                className="close"
                                                type="button"
                                                onClick={toggleModal}
                                            >
                                                <span aria-hidden={true}>×</span>
                                            </button>
                                            <h5
                                                className="modal-title text-center"
                                                id="exampleModalLabel"
                                            >
                                                Selected locations
                                            </h5>
                                        </div>
                                        <div className="modal-body">
                                            {renderSelectedLocations()}
                                        </div>
                                    </Modal>
                                    <Modal isOpen={modalLocFlagged} centered={true} toggle={toggleModalFlagged}>
                                        <div className="modal-header">
                                            <button
                                                aria-label="Close"
                                                className="close"
                                                type="button"
                                                onClick={toggleModalFlagged}
                                            >
                                                <span aria-hidden={true}>×</span>
                                            </button>
                                            <h5
                                                className="modal-title text-center"
                                                id="exampleModalLabel"
                                            >
                                                Not Approved
                                            </h5>
                                        </div>
                                        <div className="modal-body">
                                            <div style={{ marginBottom: "5px" }}>
                                                <span style={{ fontWeight: "bold" }}>Reason:</span>
                                            </div>
                                            <div style={{ marginBottom: "15px" }}>
                                                <span>
                                                    {dataAdDetails.reason}
                                                </span>
                                            </div>

                                            {renderFlaggedLocations()}
                                        </div>
                                    </Modal>
                                    <div className="AddetailsSubmitContainer">
                                        <div className="submitContainerLeft">
                                            {renderFormSubmitLocError()}
                                            <Button
                                                className="btn-round btn-createad"
                                                color="primary"
                                                onClick={() => handleSubmitForm()}
                                            >
                                                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;{buttonTitle}&nbsp;</span>
                                            </Button>
                                        </div>
                                        <div className="submitContainerRight">
                                            <div className="impressions">
                                                <div className="impressionsLeft">
                                                    <span>Estimated <br />Impressions:&nbsp;</span>
                                                </div>
                                                <div className="impressionsRight">
                                                    <span>{estimatedImpressions} - {estimatedImpressions * 6}</span>
                                                </div>
                                            </div>
                                            <div className="cost">
                                                <div className="costLeft">
                                                    <span>Subtotal:&nbsp;</span>
                                                </div>
                                                <div className="costRight">
                                                    <span>{actualcost}</span>
                                                </div>
                                            </div>
                                            <div className="cost">
                                                <div className="costLeft">
                                                    <span>Total:&nbsp;</span>
                                                </div>
                                                <div className="costRight">
                                                    <span>{cost}</span>
                                                </div>
                                            </div>
                                            {renderDesignCost()}
                                            <div className="discountMsg">
                                                <span style={{ color: '#44c47d' }}>{discountMsg}</span>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            )}
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default Addetails;
