import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    CustomInput,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../../AuthContext';
import { AxiosContext } from '../../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../../skeleton-screens/HomeSkeleton";
import ADModal from '../../components/ADModal';

function AdminDesign() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);

    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [modalButtonStatus, setModalButtonStatus] = useState('');

    const [dataAds, setDataAds] = useState([]);
    const [username, setUsername] = useState(''),
        onUserNameInput = ({ target: { value } }) => setUsername(value);
    const [userMsg, setUserMsg] = useState([]);
    const [userD, setUserD] = useState('');
    const [adminDesign, setadminDesign] = useState("");

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("admindesign-page");
        return function cleanup() {
            document.body.classList.remove("admindesign-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg, status) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
        setModalButtonStatus(status);
    };

    const handleModal = () => {
        if (modalButtonStatus == "success") {
            showModal('','');
            navigate('/admin');
        } else {
            showModal('','');
        }
    }

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const fetchData = async () => {
        try {
            
            setIsLoading(true);
            let url = '/admindesign';
            let data = {
                data: {
                    type: 'fetch'
                }
            };
            
            const response = await authAxios.post(url, data);
            var responseData = [];
            let result = response.data;
            
            if (result.data) {
                responseData = result.data;
                setDataAds(responseData);
            } else {
                throw "Server error. Unable to fetch data";
            }

            setIsLoading(false);

        } catch (error) {
            showModal("Server Error. Please refresh or relogin", "fail");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const submitDesign = async () => {
        try {
            if (userD != '' && adminDesign !='') {
                setShowSpinner(true);
                let url = '/admindesign';
                let data = {
                    data: {
                        type: 'submitdesign',
                        user: userD,
                        admindesign: adminDesign
                    }
                };
                
                const response = await authAxios.post(url, data);
                let result = response.data;
                if (typeof response.data.error === 'undefined' && typeof response.data.data !== 'undefined') {
                    if (result.data.adid != '') {
                        setShowSpinner(false);
                        showModal("Created ad successfully for " + username, "success");
                    } else {
                        setShowSpinner(false);
                        throw "Server error. Please retry";
                    }
                } else {
                    setShowSpinner(false);
                    throw (response.data.error === undefined) ? 'Server error. Please retry' : response.data.error;
                }

                setShowSpinner(false);
            } else {
                showModal("Please verify username and select design", "fail");
            }

        } catch (error) {
            if (typeof error.message === 'undefined') {
                showModal(error, "fail");
            } else {
                showModal(error.message, "fail");
            }
            setShowSpinner(false);
        }
    }

    const verifyUser = async () => {
        try {
            
            if (username != '') {
                setShowSpinner(true);
                let url = '/admindesign';
                let data = {
                    data: {
                        type: 'verifyuser',
                        user: username
                    }
                };
                
                const response = await authAxios.post(url, data);
                let result = response.data;
                if (typeof response.data.error === 'undefined' && typeof response.data.data !== 'undefined') {
                    setUserMsg('User exists. Name is: ' + result.data.name);
                    setUserD(result.data.userid);
                } else {
                    setShowSpinner(false);
                    throw (response.data.error === undefined) ? 'Server error. Please retry' : response.data.error;
                }

                setShowSpinner(false);
            } else {
                showModal("Please enter username", "fail");
            }

        } catch (error) {
            if (typeof error.message === 'undefined') {
                showModal(error, "fail");
            } else {
                showModal(error.message, "fail");
            }
            setShowSpinner(false);
        }
    }

    const renderThumbnail = (adData) => {
        if (adData.adtype == "img") {
            return (
                <img
                    src={adData.img ? adData.img : adData.vista_img}
                    width="100%"
                    height="250"
                />
            );
        }
        if (adData.adtype == "video") {
            return (
                <video width="100%" height="250" controls>
                    <source src={adData.img ? adData.img : adData.vista_img} type="video/mp4" />
                </video>
            );
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setadminDesign(value);
    };

    const renderDesigns = () => {
        
        if (dataAds?.length > 0) {
            return dataAds.map((adData, index) => {
                return (
                    <Col className="ml-auto mr-auto" md="6" key={index}>
                        <Card className="card-register">
                            <div className="card-avatar">
                                <FormGroup check>
                                    <Input
                                        type="radio"
                                        name="adminDesign"
                                        value={adData.adid}
                                        checked={adminDesign == adData.adid ? true : false}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <CardTitle tag="h4">{adData.name}</CardTitle>
                            </div>
                            <CardBody>
                                {renderThumbnail(adData)}
                            </CardBody>
                            <CardFooter>
                                <div className="homecardfooter">
                                    <div className="homecardfooterleft">
                                        {
                                            (adData.modified != "") ?
                                                (<div> {adData.modified}</div>) :
                                                (<div> {adData.created}</div>)
                                        }
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>


                );
            });
        } else {
            return (
                <Col className="ml-auto mr-auto" md="6">
                    <Card className="card-register">
                        <CardBody>
                            <div>No designs in your account</div>
                        </CardBody>
                    </Card>

                </Col>
            );
        }
    }

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <HomeSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            {!isLoading && (
                                <Row>
                                    <Col className="ml-auto mr-auto">
                                        <div className="AdminDesignContainer">
                                            <div className="AdminDesignHeader">
                                                <h3>Create Design for the user</h3>
                                            </div>
                                            <div className="AdminDesignText">
                                                <p style={{ marginTop: '20px' }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;Enter the userid (email or phone) for whom you want to create a design
                                                </p>
                                                <p style={{ marginTop: '20px' }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;Verify user or you won't be able to proceed
                                                </p>
                                                <p style={{ marginTop: '15px' }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;Select the designs you want to add to user from the list of designs in your account
                                                </p>
                                                <p style={{ marginTop: '20px' }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;Submit Design to create a design for the verified user
                                                </p>
                                            </div>
                                            <div className="AdminDesignForm">
                                                <Card className="card-register" style={{ minHeight: "unset" }}>
                                                    <Form className="discount-form">
                                                        <div className="AdminDesignForm">
                                                            <div style={{ paddingTop: '10px' }}>
                                                                <InputGroup className="form-group-with-border">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="nc-icon nc-email-85" />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        placeholder="Enter User Id"
                                                                        type="text"
                                                                        value={username}
                                                                        onChange={onUserNameInput}
                                                                    />
                                                                </InputGroup>
                                                            </div>
                                                            <div style={{ paddingLeft: '10px' }}>
                                                                <Button
                                                                    className="btn-round"
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => verifyUser()}
                                                                >
                                                                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Verify User&nbsp;</span>
                                                                </Button>
                                                            </div>

                                                        </div>
                                                    </Form>
                                                    <div style={{ paddingTop: '10px', color: '#44c47d' }}>
                                                        {userMsg}
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="AdminDesignSelTxt">
                                                <p style={{ marginTop: '10px' }}>
                                                    Select a design:
                                                </p>
                                            </div>
                                            <div className="AdminDesignAds">
                                                {renderDesigns()}
                                            </div>
                                            <div className="AdminDesignSubmit">
                                                <Button
                                                    className="btn-round"
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => submitDesign()}
                                                >
                                                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Submit Design for user&nbsp;</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            )}
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default AdminDesign;
