import React from 'react';
import {
    ToastHeader
} from "reactstrap";

const Adstatus = ({
    status = ''
}) => {

    const renderStatus = () => {
        if (status == "active") {
            return (
                <>
                    <ToastHeader icon="success">Active</ToastHeader>
                </>
            );
        } else if (status == "in active") {
            return (
                <>
                    <ToastHeader icon="secondary">In Active</ToastHeader>
                </>
            );
        } else if (status == "pending payment") {
            return (
                <>
                    <ToastHeader icon="warning">Pending Payment</ToastHeader>
                </>
            );
        } else if (status == "pending approval") {
            return (
                <>
                    <ToastHeader icon="info">Pending Approval</ToastHeader>
                </>
            );
        } else if (status == "not approved") {
            return (
                <>
                    <ToastHeader icon="danger">Not Approved</ToastHeader>
                </>
            );
        } else if (status == "incomplete") {
            return (
                <>
                    <ToastHeader icon="warning">Incomplete</ToastHeader>
                </>
            );
        } else {
            return (
                <>
                    <ToastHeader icon="primary">{status}</ToastHeader>
                </>
            );
        }
    };

    return (
        <>{renderStatus()}</>
    );
};

export default Adstatus;