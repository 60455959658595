import React from "react";
import { useNavigate } from 'react-router-dom';
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Spinner,
    Modal
} from "reactstrap";
import { AuthContext } from '../../AuthContext';
import { AxiosContext } from '../../AxiosContext';

function LoggedinNavbar() {
    const navigate = useNavigate();
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [navbarCollapse, setNavbarCollapse] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const axiosContext = React.useContext(AxiosContext);
    const authContext = React.useContext(AuthContext);

    const toggleNavbarCollapse = () => {
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
    };

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 99 ||
                document.body.scrollTop > 99
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 100 ||
                document.body.scrollTop < 100
            ) {
                setNavbarColor("navbar-transparent");
            }
        };

        window.addEventListener("scroll", updateNavbarColor);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };

    const logout = async () => {
        try {
            setShowSpinner(true);
            const response = await axiosContext.authAxios.get('/logout/userlogout');
            let result = response.data;
            if (result.data) {
                localStorage.removeItem('adyantraat');
                authContext.logout();
                navigate('/');
            } else {
                throw "Error occurred. Please logout again";
            }
            setShowSpinner(false);
        } catch (error) {
            localStorage.removeItem('adyantraat');
            authContext.logout();
            setShowSpinner(false);
        }
    };

    const renderAdminNav = () => {
        if (authContext?.authState?.roles && (authContext.authState.roles.includes("ADYANTRA_SUPER_ADMIN"))) {
            return (
                <>
                    <NavItem>
                        <NavLink
                            data-placement="bottom"
                            href="/admin"
                        >
                            <i className="nc-icon nc-single-02"></i>&nbsp; Admin
                        </NavLink>
                    </NavItem>
                </>
            );
        }
    };

    const renderOwnerNav = () => {
        if (authContext?.authState?.roles && (authContext.authState.roles.includes("ADYANTRA_SUPER_ADMIN") || authContext.authState.roles.includes("ADYANTRA_FRANCHISE_ADMIN"))) {
            return (
                <>
                    <NavItem>
                        <NavLink
                            data-placement="bottom"
                            href="/owner"
                        >
                            <i className="nc-icon nc-single-02"></i>&nbsp; Owner
                        </NavLink>
                    </NavItem>
                </>
            );
        }
    };


    return (
        <Navbar className={classnames("fixed-top", navbarColor)} color-on-scroll="300" expand="lg">
            <Container>
                <div className="navbar-translate">
                    <NavbarBrand
                        data-placement="bottom"
                        href="/home"
                        title="Adkewl"
                    >
                        <img alt="..." src={require("../../assets/img/brandlogo.png")} width="100" height="40" style={{ objectFit:'contain'}}/>
                    </NavbarBrand>
                    <button
                        aria-expanded={navbarCollapse}
                        className={classnames("navbar-toggler navbar-toggler", {
                            toggled: navbarCollapse,
                        })}
                        onClick={toggleNavbarCollapse}
                    >
                        <span className="navbar-toggler-bar bar1" />
                        <span className="navbar-toggler-bar bar2" />
                        <span className="navbar-toggler-bar bar3" />
                    </button>
                </div>
                <Collapse
                    className="justify-content-end"
                    navbar
                    isOpen={navbarCollapse}
                >
                    <Nav navbar>
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="/account"
                            >
                                <i className="nc-icon nc-single-02"></i>&nbsp; <span style={{color:"#000000"}}>My Account</span>
                            </NavLink>
                        </NavItem>
                        {renderOwnerNav()}
                        {renderAdminNav()}
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="/help"
                            >
                                <i className="nc-icon nc-send" /> &nbsp;<span style={{color:"#000000"}}>Help</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                onClick={() => logout()}
                            >
                                <i className="nc-icon nc-button-power" /> &nbsp;<span style={{color:"#000000"}}>Logout</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
                {renderSpinner()}
            </Container>
        </Navbar>
    );
}

export default LoggedinNavbar;
