import { motion } from "framer-motion";
import Skeleton from "../components/Skeleton";
import Shimmer from "../components/Shimmer";

const HomeSkeleton = ({ ...props }) => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="skeleton-wrapper"
      {...props}
    >
      <h2 className="skeleton-section-title">
        <Skeleton type="title" />
      </h2>

      <ul className="skeleton-list">
        {Array(9)
          .fill()
          .map((item, index) => (
            <li className="skeleton-card" key={index}>
              <Skeleton type="thumbnail" />
              <h4 className="skeleton-card-title">
                <Skeleton type="title" />
              </h4>
              <div className="skeleton-card-channel">
                <Skeleton type="text" />
              </div>
            </li>
          ))}
      </ul>
      <Shimmer />
    </motion.section>
  );
};
export default HomeSkeleton;
