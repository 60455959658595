import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../skeleton-screens/HomeSkeleton";
import ADModal from '../components/ADModal';
import { State }  from "country-state-city";
import Select from 'react-select';

function MyCart() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { adid } = state;
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const [discMsg, setDiscMsg] = useState('');
    const [usrStateMsg, setUsrStateMsg] = useState('');
    const [discountcode, setDiscountcode] = useState(''),
        onDiscountInput = ({ target: { value } }) => setDiscountcode(value);
    const [gstin, setGstin] = useState(''),
        onGstinInput = ({ target: { value } }) => setGstin(value);
    const [usrstate, setUsrstate] = useState(''),
        onStateInput = (value) => setUsrstate(value);
    const [adDetails, setAdDetails] = useState([]);
    const [discDetails, setDiscDetails] = useState([]);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("CreateAd-page");
        return function cleanup() {
            document.body.classList.remove("CreateAd-page");
        };
    });


    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const renderCurrency = (currency) => {
        if (currency == 'INR') {
            return <>&#8377;</>;
        } else {
            return <></>;
        }
    };

    const renderTax = (cost, tax, designcost) => {
        if (tax) {
            let taxp = 0;
            let newtaxp = 0;
            if (designcost > 0) {
                newtaxp = cost + designcost;
                taxp = newtaxp * (tax / 100);
            } else {
                taxp = cost * (tax / 100);
            }
            return taxp.toFixed(1);
        } else {
            return '0';
        }
    };

    const renderTotal = (cost, tax, designcost) => {
        if (tax) {
            let tot = 0;
            let newtot = 0;
            if (designcost > 0) {
                newtot = cost + designcost;
                tot = newtot + newtot * (tax / 100);
            } else {
                tot = cost + cost * (tax / 100);
            }
            return tot.toFixed(1);;
        } else {
            if (designcost > 0) {
                return cost + designcost;
            } else {
                return cost;
            }

        }
    };

    const renderDiscMsg = () => {
        if (discMsg == 'Please enter discount code' || discMsg == 'Server error. Please retry' || discMsg == 'Invalid discount code' || discMsg == 'error applying discount') {
            return (<span style={{ color: '#b33c12', textTransform: 'none' }}>{discMsg}</span>);
        } else {
            return (<span style={{ color: '#44c47d', textTransform: 'none' }}>{discMsg}</span>);
        }

    };
    
    const renderUsrStateMsg = () => {
        return (<span style={{ color: '#b33c12', textTransform: 'none' }}>{usrStateMsg}</span>);
    };
    
    const renderDesignCost = () => {
        if (adDetails.designcost > 0) {
            return (
                <div className="myCartField">
                    <div className="myCartLeft">Design cost: </div>
                    <div className="myCartRight">{renderCurrency(adDetails.currency)} {adDetails.designcost} </div>
                </div>
            );
        }

    }

    const renderDesignCostNote = () => {
        if (adDetails.designcost > 0) {
            return (<span style={{ color: '#767676', textTransform: 'none' }}>* discounts do not apply on design cost</span>);
        }
    }

    const applyDiscount = async () => {
        try {
            if (discountcode != '') {
                setShowSpinner(true);
                let url = '/discount';
                let data = {
                    data: {
                        code: discountcode,
                        adid: adid
                    }
                };
                const response = await authAxios.post(url, data);
                if (typeof response.data.error === 'undefined' && typeof response.data.data !== 'undefined') {
                    let result = response.data;
                    if (result.data.status == 'success') {
                        setDiscDetails(result.data);
                        let msg = '';
                        if (result.data.type == 'percentage') {
                            msg = result.data.codevalue + "% discount is applied";
                        }
                        if (result.data.type == 'flat') {
                            msg = "discount of flat " + result.data.codevalue + " is applied";
                        }
                        setDiscMsg(msg);
                    } else {
                        setDiscMsg(result.data.status);
                        setDiscDetails([]);
                    }
                } else {
                    setShowSpinner(false);
                    throw (response.data.error === undefined) ? 'Server error. Please retry' : response.data.error;
                }


                setShowSpinner(false);
            } else {
                setDiscMsg('Please enter discount code');
                setDiscDetails([]);
            }
        } catch (error) {
            if (typeof error.message === 'undefined') {
                setDiscMsg(error);
            } else {
                setDiscMsg(error.message);
            }
            setDiscDetails([]);
        }

    };

    const triggerPayment = async () => {
        try {
            if (usrstate === 'undefined' || usrstate == '') {
                setUsrStateMsg('Please select state');
            } else {
                setShowSpinner(true);
                let url = '/stategst';
                let data = {
                    data: {
                        state: usrstate,
                        gstin: gstin
                    }
                };
                const response = await authAxios.post(url, data);
                if (typeof response.data.error === 'undefined' && typeof response.data.data !== 'undefined') {
                    let result = response.data;                    
                    setShowSpinner(false);
                    let discountCode = '';
                    let newCost = '';
                    if (discDetails?.code && discDetails?.status == 'success') {
                        discountCode = discDetails.discountcodeid;
                        newCost = discDetails.newcost;
                    }
                    navigate('/paymentcf', { state: { adid: adid, discountcodeid: discountCode, newcost: newCost } });
                    
                } else {
                    throw (response.data.error === undefined) ? 'Server error. Please retry' : response.data.error;
                }
            }                

        } catch (error) {
            setShowSpinner(false);
            if (typeof error.message === 'undefined') {
                showModal(error);
            } else {
                showModal(error.message);
            }
        }        
    }

    const updatedStates = (countryId) =>
        State
            .getStatesOfCountry(countryId)
            .map((state) => ({ label: state.name, value: state.id, ...state }));

    const fetchData = async () => {
        
        if (adid) {
            try {
                setIsLoading(true);
                let url = '/ad/' + adid;
                const response = await authAxios.get(url);
                var responseData = '';
                let result = response.data;
                let designid = null;
                if (result.data) {
                    setAdDetails(result.data);
                } else {
                    throw "Server error. Unable to fetch data";
                }
                setIsLoading(false);
            } catch (error) {
                showModal(error);
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <HomeSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            <Row>
                                {!isLoading && (
                                    <Col className="ml-auto mr-auto">
                                        <Card className="card-register">
                                            <h3 className="title mycartHeader">{adDetails.name}</h3>
                                            <div className="myCartContainer">
                                                <div className="myCartField">
                                                    <div className="myCartLeft">Campaign cost: </div>
                                                    <div className="myCartRight">{renderCurrency(adDetails.currency)} {discDetails?.newcost ? discDetails?.newcost : adDetails.cost} </div>
                                                </div>
                                                {renderDesignCost()}
                                                <div className="myCartField">
                                                    <div className="myCartLeft">{adDetails.taxtype} ({adDetails.tax}%) :</div>
                                                    <div className="myCartRight">{renderCurrency(adDetails.currency)} {renderTax(parseInt(discDetails?.newcost ? discDetails?.newcost : adDetails.cost), parseInt(adDetails.tax), parseInt(adDetails.designcost))}</div>
                                                </div>
                                                <div className="myCartField">
                                                    <div className="myCartLeft">Total :</div>
                                                    <div className="myCartRight">{renderCurrency(adDetails.currency)} {renderTotal(parseInt(discDetails?.newcost ? discDetails?.newcost : adDetails.cost), parseInt(adDetails.tax), parseInt(adDetails.designcost))}</div>
                                                </div>
                                            </div>
                                            <Form className="discount-form">
                                                <div className="myCartGstContainer">
                                                    <div className="myCartGstLeft">
                                                        <p>Need GST invoice?</p>
                                                    </div>
                                                    <div className="myCartGstRight">
                                                        <InputGroup className="form-group-with-border-nomargintop">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-badge" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="GSTIN #"
                                                                type="text"
                                                                value={gstin}
                                                                onChange={onGstinInput}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                                <div className="myCartGstContainer">
                                                    <div className="myCartGstLeft">
                                                        <p>State :</p>
                                                    </div>
                                                    <div className="myCartGstRight">
                                                        <Select
                                                            className="reactSelectDropdownState"
                                                            id="usrstate"
                                                            name="usrstate"
                                                            options={updatedStates('IN')}
                                                            value={usrstate}
                                                            onChange={onStateInput}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="myCartDiscTextContainer">
                                                    {renderUsrStateMsg()}
                                                </div>
                                                <div className="myCartDiscContainer">
                                                    <div style={{ paddingTop: '10px' }}>
                                                        <InputGroup className="form-group-with-border">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-scissors" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Discount code"
                                                                type="text"
                                                                value={discountcode}
                                                                onChange={onDiscountInput}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    <div style={{ paddingLeft: '10px' }}>
                                                        <Button
                                                            className="btn-round"
                                                            color="primary"
                                                            type="button"
                                                            onClick={() => applyDiscount()}
                                                        >
                                                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Apply&nbsp;</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                            <div className="myCartDiscTextContainer">
                                                {renderDiscMsg()}
                                            </div>
                                            <div className="myCartPay">
                                                <Button
                                                    className="btn-round"
                                                    color="primary"
                                                    onClick={() => triggerPayment()}
                                                >
                                                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Proceed to pay&nbsp;</span>
                                                </Button>
                                            </div>
                                            <div className="myCartPay">
                                                {renderDesignCostNote()}
                                            </div>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default MyCart;
