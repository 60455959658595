import React, {createContext, useState} from 'react';

const AuthContext = createContext(null);
const {Provider} = AuthContext;

const AuthProvider = ({children}) => {
  const [authState, setAuthState] = useState({
    accessToken: null,
    authenticated: null,
    roles: []
  });

  const logout = async () => {
    localStorage.removeItem('adyantraat');
    setAuthState({
      accessToken: null,
      authenticated: false,
      roles: []
    });
  };

  const getAccessToken = () => {
    return authState.accessToken;
  };


  return (
    <Provider
      value={{
        authState,
        getAccessToken,
        setAuthState,
        logout,
      }}>
      {children}
    </Provider>
  );
};

export {AuthContext, AuthProvider};