import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    CustomInput,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../../AuthContext';
import { AxiosContext } from '../../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../../skeleton-screens/HomeSkeleton";
import ADModal from '../../components/ADModal';
import Select from 'react-select';

function OwnerLocAd() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const { state } = useLocation();
    const { locid } = state;
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);


    const [dataAdUserDetails, setDataAdUserDetails] = useState([]);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("owneradloc-page");
        return function cleanup() {
            document.body.classList.remove("owneradloc-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const navigateToAddetails = (Adid, Status) => {
        navigate('/adminad', { state: { adid: Adid, status: Status } });
    };

    const convertDate = (dataDate) => {
        if (dataDate) {
            const utcDate = new Date(dataDate);
            const myLocalDate = new Date(Date.UTC(
                utcDate.getFullYear(),
                utcDate.getMonth(),
                utcDate.getDate(),
                utcDate.getHours(),
                utcDate.getMinutes()
            ));
            return myLocalDate.toLocaleString("en-IN");
        } else {
            return '';
        }
        
    }

    const renderAds = () => {
        return dataAdUserDetails.map((adData, index) => {
            return (
                <div className="AdItem" key={adData.adid}>
                    <div className="AdItemContentTop">
                        <div className="AdItemContentTopItems">
                            <span style={{ fontWeight: "bold" }}>Ad Name: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.name} </span>
                        </div>
                        <div className="AdItemContentTopItems">
                            <span style={{ fontWeight: "bold" }}>Status: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.status} </span>
                        </div>
                        <div className="AdItemContentTopItems">
                            <span style={{ fontWeight: "bold" }}>Created Date: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{convertDate(adData.created)} </span>
                        </div>
                        <div className="AdItemContentTopItems">
                            <span style={{ fontWeight: "bold" }}>Modified Date: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{convertDate(adData.modified)} </span>
                        </div>
                    </div>
                    <div className="AdItemContentMiddle">
                        <div className="AdItemContentMiddleItems">
                            <span style={{ fontWeight: "bold" }}>User: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.firstname}&nbsp;{adData.lastname} </span>
                        </div>
                        <div className="AdItemContentMiddleItems">
                            <span style={{ fontWeight: "bold" }}>Email: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.email} </span>
                        </div>
                        <div className="AdItemContentMiddleItems">
                            <span style={{ fontWeight: "bold" }}>Phone: &nbsp;</span>
                            <span style={{ fontWeight: "normal" }}>{adData.phone} </span>
                        </div>
                        <div className="AdItemContentMiddleItems">
                            <span style={{ fontWeight: "bold" }}></span>
                        </div>
                    </div>
                    <div className="AdItemContentBottom">
                        <div className="AdItemContentBottomItemsLeft">
                            <span style={{ fontWeight: "normal", fontSize:10 }}>{index + 1} </span>
                        </div>
                        <div className="AdItemContentBottomItemsRight">
                            <Button
                                className="btn-round"
                                color="primary"
                                type="button"
                                onClick={() => navigateToAddetails(adData.adid, adData.status)}
                            >
                                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; View... &nbsp;&nbsp;</span>
                            </Button>
                        </div>

                    </div>
                </div>

            );
        });
    };
    const fetchData = async () => {
        try {
            
            setIsLoading(true);
            let url = '/ownerlocads/' + locid;

            const response = await authAxios.get(url);
            var responseData = [];
            let result = response.data;
            
            if (result.data) {
                responseData = result.data;
                setDataAdUserDetails(responseData.aduserDetails);

            } else {
                throw "Server error. Unable to fetch data";
            }

            setIsLoading(false);
        } catch (error) {
            showModal("Server Error. Please refresh or relogin");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <HomeSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            {!isLoading && (
                                <Row>
                                    <Col className="ml-auto mr-auto">
                                        <div className="AdminHomeContainer">
                                            <div className="AdminHomeFilterDropdowns">
                                                
                                            </div>
                                            
                                            <div className="AdminAdDetails">
                                                {renderAds()}
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default OwnerLocAd;
