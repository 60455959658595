import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../skeleton-screens/HomeSkeleton";
import ADModal from '../components/ADModal';
import Adstatus from '../components/Adstatus';

function CreateAd() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("CreateAd-page");
        return function cleanup() {
            document.body.classList.remove("CreateAd-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const launchEditor = () => {
        navigate('/vceditor', { state: { adid: '', status: '' } });
    };

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">
                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <HomeSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            <Row>
                                {!isLoading && (
                                    <Col className="ml-auto mr-auto">
                                        <Card className="card-register">
                                            <CardBody>
                                                <div>
                                                    <p>
                                                        Create your own Ad using our easy to use drag and drop designer tool
                                                    </p>
                                                    <p style={{marginTop:'15px'}}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;We offer over a million templates and images to choose from
                                                    </p>
                                                    <p>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;No design experience needed at all
                                                    </p>
                                                    <p>
                                                        &nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;<span style={{fontSize: 'small', color: '#b33c12', fontWeight: '500' }}>Note:</span> Any design whether image or video 
                                                        will be played for 10 seconds on the screens
                                                    </p>
                                                    <p>
                                                        &nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;<span style={{fontSize: 'small', color: '#b33c12', fontWeight: '500' }}>Note:</span> Video- designs with animations or multiple pages 
                                                    </p>
                                                    <p>
                                                        &nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;<span style={{fontSize: 'small', color: '#b33c12', fontWeight: '500' }}>Note:</span> To play a video on the screens, it should be stored as mp4 in the editor 
                                                    </p>
                                                    <p>
                                                        &nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;<span style={{fontSize: 'small', color: '#b33c12', fontWeight: '500' }}>Note:</span> Please make sure of "play timer" in the editor for each page in case of multiple pages. 
                                                    </p>
                                                    <p>
                                                        &nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;<span style={{fontSize: 'small', color: '#b33c12', fontWeight: '500' }}>Note:</span> "play timer" shows up only when there is some animation in any of the pages 
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <Button
                                                            className="btn-round"
                                                            color="primary"
                                                            onClick={() => launchEditor()}
                                                        >
                                                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Launch Editor&nbsp;</span>
                                                        </Button>
                                                    </p>
                                                </div>
                                                <div style={{display:"flex", flexDirection:"row", justifyContent: "flex-start", marginTop:'30px'}}>
                                                    <p>(or)</p>
                                                </div>
                                                <div style={{marginTop:'15px'}}>
                                                    <p>
                                                        We can create an Ad for you
                                                    </p>
                                                    <p style={{marginTop:'15px'}}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;Our designers can help you with great designs
                                                    </p>
                                                    <p>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;<i className="nc-icon nc-check-2"></i>&nbsp;&nbsp;Extra cost applies, due when you are ready to publish
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <Button
                                                            className="btn-round"
                                                            color="primary"
                                                        >
                                                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Call at +919618455334&nbsp;</span>
                                                        </Button>
                                                    </p>
                                                </div>
                                            </CardBody>
                                            <CardFooter>

                                            </CardFooter>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}

                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default CreateAd;
