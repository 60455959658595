import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} color-on-scroll="300" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/index"
            title="Adkewl"
          >
            <img alt="..." src={require("../../assets/img/brandlogo.png")} width="100" height="40" style={{ objectFit:'contain'}}/>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="/index#adkewlproduct"
              >
                <i className="d-lg-none nc-icon nc-badge" />
                <p className="d-lg-none"> &nbsp;<span style={{color:"#000000", fontWeight:"bolder"}}>About Us</span> </p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="/index#adkewlcontact"
              >
                <i className="d-lg-none nc-icon nc-email-85" />
                <p className="d-lg-none"> &nbsp;<span style={{color:"#000000", fontWeight:"bolder"}}>Contact Us</span> </p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/index#adkewlabout"
              >
                <i className="nc-icon nc-mobile large" /> &nbsp;<span style={{color:"#000000", fontWeight:"bolder"}}>Mobile App</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <Button
                className="btn-round"
                style={{zIndex:"1000"}}
                color="danger"
                href="/login"
              >
                <i className="nc-icon nc-single-02"></i>&nbsp; Login / Signup
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
