import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import ADModal from '../components/ADModal';

function Approval() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("approval-page");
        return function cleanup() {
            document.body.classList.remove("approval-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };



    const goToHome = () => {
        navigate('/home', { replace: true });
    };

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">

                    <div className="text-center">
                        <Container>
                            <Row>
                                <Col className="ml-auto mr-auto">
                                    <Card className="card-register">
                                        <CardBody>
                                            <div style={{margin:"0px auto", width:"100px"}}>
                                                <p>
                                                    Great Job!!
                                                </p>
                                            </div>
                                            <div style={{margin:"20px auto 0px"}}>
                                                <p>
                                                    Your Ad is submitted for approval successfully
                                                </p>
                                            </div>
                                            <div style={{ marginTop: "20px", textAlign: "left" }}>
                                                <p>
                                                    Next Steps:
                                                </p>
                                                <ul>
                                                    <li>Our team verifies the Ad content and choosen locations</li>
                                                    <li>Few location owners might have preferences to the Ad's they allow</li>
                                                    <li>Will notify you once ready on via sms and e-mail</li>
                                                    <li>or, you can always check on the app for the status to change to "Pending Payment"</li>
                                                    <li>Make the payment on the app and your Ad is all set to rollout</li>
                                                </ul>
                                            </div>
                                            <div style={{ marginTop: "20px" }}>
                                                <div className="btncenter">
                                                    <Button
                                                        className="btn-round"
                                                        color="primary"
                                                        onClick={() => goToHome()}
                                                    >
                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Home&nbsp;</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}
                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default Approval;
