/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("../../assets/img/adkewl-banner-bkgd-dark.png") + ")",
        }}
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">Adkewl</h1>
              <div className="fog-low">
                <img alt="..." src={require("../../assets/img/fog-low.png")} />
              </div>
              <div className="fog-low right">
                <img alt="..." src={require("../../assets/img/fog-low.png")} />
              </div>
            </div>
            <h2 className="presentation-subtitle text-center">
              Build your business with our digital signage solution!
            </h2>
            <h4 className="presentation-subtitle text-center">
              Affordable & conviniently "<span style={{ color: "red" }}>D</span>o <span style={{ color: "red" }}>I</span>t <span style={{ color: "red" }}>Y</span>ourself"
            </h4>
            <div className="homeLoginButton">
              <Button
                className="btn-round"
                color="danger"
                href="/login"
              >
                <i className="nc-icon nc-single-02"></i>&nbsp; Login / Signup
              </Button>
            </div>

          </Container>
        </div>
        <div
          className="characterHome"
        />
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("../../assets/img/clouds.png") + ")",
          }}
        />
      </div>
    </>
  );
}

export default IndexHeader;
