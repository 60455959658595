import React, { useContext, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Modal,
    Row,
    Col,
    Spinner
} from "reactstrap";

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import DemoFooter from "../components/Footers/DemoFooter.js";
import { Formik } from 'formik';
import * as yup from 'yup';
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import ADModal from '../components/ADModal';
import ReCAPTCHA from "react-google-recaptcha";

function Signup() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { publicAxios } = useContext(AxiosContext);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalButtonType, setModalButtonType] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);
    const [privacypolicy, setPrivacypolicy] = useState('');
    const [termsofservice, setTermsofservice] = useState('');
    const [ppId, setPpId] = useState('');
    const [tosId, settTosId] = useState('');
    const [modal, setModal] = React.useState(false);
    const captchaRef = useRef(null);

    const signUpValidationSchema = yup.object().shape({
        fullName: yup
            .string()
            .matches(/\w.+/, 'Enter full name')
            .min(3, ({ min }) => `Name must be at least ${min} characters`)
            .required('Full name is required'),
        phoneNumber: yup
            .string()
            .when('email', {
                is: (email) => !email || email.length === 0,
                then: yup.string().test(
                    "maxDigits",
                    "Phone number must be 10 digits",
                    (number) => String(number).length === 10
                )
                    .required('Phone number or email is required')
            }),
        email: yup
            .string()
            .email("Please enter valid email")
            .when('phoneNumber', {
                is: (phoneNumber) => !phoneNumber || phoneNumber.length === 0,
                then: yup.string().required('Phone number or email is required')
            }),
        pin: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(4, 'Pin must be 4 digits')
            .max(4, 'Pin must be 4 digits')
            .required('Pin is required'),
        confirmPin: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .oneOf([yup.ref('pin')], 'Pin do not match')
            .required('Confirm pin is required'),
        accept: yup
            .boolean()
            .oneOf([true], 'Please accept the agreement')
    }, ['phoneNumber', 'email']);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("signup");
        return function cleanup() {
            document.body.classList.remove("signup");
        };
    });

    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth < 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    const fetchPrivacyPolicyInfo = async () => {
        try {

            setShowSpinner(true);

            const response = await publicAxios.get('/pptos');
            let result = response.data;

            if (result.data) {
                setPrivacypolicy(result.data.pp.privacypolicy);
                setTermsofservice(result.data.tos.terms);
                setPpId(result.data.pp.privacypolicy_id);
                settTosId(result.data.tos.termsofservice_Id);

            } else {
                throw "Server error. Unable to fetch policy data";
            }
            setShowSpinner(false);
        } catch (error) {
            if (typeof error.message === 'undefined') {
                showModal(error, null);
            } else {
                showModal(error.message, null);
            }
            setShowSpinner(false);
        }
    };

    React.useEffect(() => {
        fetchPrivacyPolicyInfo();
    }, []);


    const toggleModal = () => {
        setModal(!modal);
    };

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg, type = null) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
        setModalButtonType(type);
    };

    const handleModal = () => {
        if (modalButtonType == "success") {
            showModal('', '');
            navigate('/login', { replace: true });
        } else {
            showModal('', '');
        }
    };

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const handleSubmit = async (values) => {
        try {

            if ((values.email || values.phoneNumber > 0) && values.pin > 0) {

                setShowSpinner(true);


                const token = await captchaRef.current.executeAsync();
                captchaRef.current.reset();

                const captchadata = {
                    token: token
                };
                const captchaResponse = await publicAxios.post('/verifycaptcha', captchadata, {
                    headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' }
                });

                let captchaResult = captchaResponse.data;
                if (captchaResult?.data == 'human' || captchaResult?.data == 'no token') {

                    let url = '/signup';
                    let data = {
                        data: {
                            accept: values.accept,
                            confirmPin: values.confirmPin,
                            email: values.email,
                            fullName: values.fullName,
                            phoneNumber: values.phoneNumber,
                            pin: values.pin
                        },
                        ppId: ppId,
                        tosId: tosId,
                        role: 'user'
                    };

                    const response = await publicAxios.post(url, data, {
                        headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' }
                    });
                    let result = response.data;

                    if (result.data) {
                        if (result.data.userid != "User Already Exists") {
                            showModal("User created successfully. Please login with your userid- registered email or phone", 'success');
                        } else {
                            showModal("User Already Exists", null);
                        }
                    } else {
                        throw "Server error. Unable to create user";
                    }

                } else {
                    throw "Captcha verification failed";
                }

                setShowSpinner(false);
            } else {
                showModal("Please check the input fields", null);
            }

        } catch (error) {
            if (typeof error.message === 'undefined') {
                showModal(error, null);
            } else {
                showModal(error.message, null);
            }
            setShowSpinner(false);
        }
    };

    return (
        <>
            <IndexNavbar />
            <div
                className="page-header-other"
                style={{
                    backgroundImage:
                        "url(" + require("../assets/img/adkewl-banner-bkgd-dark.png") + ")",
                }}
                data-parallax={true}
                ref={pageHeader}
            />
            <div className="main">
                <div className="homeNavSection text-center">
                    <Container>
                        <Row>
                            <Col className="mx-auto" lg="4" md="6">
                                <Card className="card-register">
                                    <h3 className="title mx-auto">Sign up</h3>
                                    <Formik
                                        validationSchema={signUpValidationSchema}
                                        initialValues={{
                                            fullName: '',
                                            email: '',
                                            phoneNumber: '',
                                            pin: '',
                                            confirmPin: '',
                                            accept: false
                                        }}
                                        onSubmit={(values) => { handleSubmit(values) }}
                                    >
                                        {({
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            isValid,
                                            setFieldValue,
                                            values,
                                            touched,
                                            errors
                                        }) => (
                                            <>
                                                <Form className="register-form">
                                                    <InputGroup className="form-group-with-border">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-single-02" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            id="fullName"
                                                            placeholder="Full Name or Business Name"
                                                            type="text"
                                                            value={values.fullName}
                                                            onChange={handleChange('fullName')}
                                                            onBlur={handleBlur('fullName')}
                                                        />
                                                    </InputGroup>
                                                    {errors.fullName && touched.fullName && (
                                                        <div className="input-feedback">{errors.fullName}</div>
                                                    )}

                                                    <InputGroup className="form-group-with-border">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-email-85" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            id="email"
                                                            placeholder="Email Address"
                                                            type="email"
                                                            value={values.email}
                                                            onChange={handleChange('email')}
                                                            onBlur={handleBlur('email')}
                                                        />
                                                    </InputGroup>
                                                    {errors.email && touched.email && (
                                                        <div className="input-feedback">{errors.email}</div>
                                                    )}

                                                    <InputGroup className="form-group-with-border">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-mobile" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            id="phoneNumber"
                                                            placeholder="Phone Number"
                                                            type="number"
                                                            maxLength="10"
                                                            value={values.phoneNumber}
                                                            onChange={handleChange('phoneNumber')}
                                                            onBlur={handleBlur('phoneNumber')}
                                                        />
                                                    </InputGroup>
                                                    {errors.phoneNumber && touched.phoneNumber && (
                                                        <div className="input-feedback">{errors.phoneNumber}</div>
                                                    )}

                                                    <InputGroup className="form-group-with-border">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-key-25" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            id="pin"
                                                            placeholder="4 digit pin to use for login"
                                                            type="password"
                                                            inputMode="numeric"
                                                            maxLength="4"
                                                            value={values.pin}
                                                            onChange={handleChange('pin')}
                                                            onBlur={handleBlur('pin')}
                                                        />
                                                    </InputGroup>
                                                    {errors.pin && touched.pin && (
                                                        <div className="input-feedback">{errors.pin}</div>
                                                    )}

                                                    <InputGroup className="form-group-with-border">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-key-25" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            id="confirmPin"
                                                            placeholder="Confirm Pin"
                                                            type="password"
                                                            inputMode="numeric"
                                                            maxLength="4"
                                                            value={values.confirmPin}
                                                            onChange={handleChange('confirmPin')}
                                                            onBlur={handleBlur('confirmPin')}
                                                        />
                                                    </InputGroup>
                                                    {errors.confirmPin && touched.confirmPin && (
                                                        <div className="input-feedback">{errors.confirmPin}</div>
                                                    )}
                                                    <div style={{ marginTop: '10px' }}>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input defaultValue={values?.accept} type="checkbox" onClick={() => setFieldValue('accept', !values?.accept)} /><span className="form-check-sign" />
                                                                <span style={{ color: '#0b1011' }}>I have read and agreed to <a onClick={toggleModal}>  Adkewl's terms of service and privacy policy</a>
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                        <Modal isOpen={modal} toggle={toggleModal}>
                                                            <div className="modal-header">
                                                                <button
                                                                    aria-label="Close"
                                                                    className="close"
                                                                    type="button"
                                                                    onClick={toggleModal}
                                                                >
                                                                    <span aria-hidden={true}>×</span>
                                                                </button>
                                                                <h5
                                                                    className="modal-title text-center"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    Adkewl
                                                                </h5>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div style={{ color: '#4d79ff', marginBottom: '10px', fontWeight: 'bold' }}>Privacy Policy: </div>
                                                                <div style={{ textAlign: 'justify', marginBottom: '40px', whiteSpace:"pre-line"  }}>{privacypolicy}</div>
                                                                <div style={{ color: '#4d79ff', marginBottom: '10px', marginTop: '10px', textAlign: 'justify', fontWeight: 'bold' }}>Terms of Service: </div>
                                                                <div style={{ textAlign: 'justify', whiteSpace:"pre-line" }}>{termsofservice}</div>
                                                            </div>
                                                        </Modal>
                                                    </div>

                                                    <div className="btncenter">
                                                        <Button
                                                            className="btn-round"
                                                            color="primary"
                                                            disabled={!isValid}
                                                            onClick={handleSubmit}
                                                        >
                                                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Sign Up&nbsp;</span>
                                                        </Button>
                                                    </div>
                                                    <ReCAPTCHA
                                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                                        ref={captchaRef}
                                                        size="invisible"
                                                    />
                                                </Form>
                                            </>
                                        )}
                                    </Formik>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
                <ADModal
                    isVisible={isModalVisible}
                    msg={modalMsg}
                    children={renderOkButton()}
                />
                {renderSpinner()}
            </div>
            <DemoFooter />

        </>
    );
}

export default Signup;
