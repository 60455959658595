import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../skeleton-screens/HomeSkeleton";
import ADModal from '../components/ADModal';
import Adstatus from '../components/Adstatus';

function Home() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [dataAds, setDataAds] = useState([]);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("home-page");
        return function cleanup() {
            document.body.classList.remove("home-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const renderThumbnail = (adData) => {
        if (adData.adtype == "img") {
            return (
                    <img
                        src={adData.img ? adData.img : adData.vista_img}
                        width="100%"
                        height="250"
                    />

            );
        }
        if (adData.adtype == "video") {
            return (
                    <video width="100%" height="250" controls>
                        <source src={adData.img ? adData.img : adData.vista_img} type="video/mp4" />
                    </video>
            );
        }
    };

    const fetchData = async () => {
        try {
            
            setIsLoading(true);
            const response = await authAxios.get('/ads');
            var responseData = '';
            let result = response.data;

            if (result.data) {
                responseData = result.data;
                setDataAds(responseData);
            } else {
                throw "Server error. Unable to fetch data";
            }
            setIsLoading(false);
        } catch (error) {
            
            showModal(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
        fetchData();
    }, []);

    const renderStatus = (status) => {
        return (
            <>
                <Adstatus status={status} />
            </>
        );

    };

    const renderCards = () => {
        if (dataAds?.length > 0) {
            return dataAds.map((adData, index) => {
                const dataDate = adData.modified != "" ? adData.modified : adData.created;
                const utcDate = new Date(dataDate);
                const myLocalDate = new Date(Date.UTC(
                    utcDate.getFullYear(),
                    utcDate.getMonth(),
                    utcDate.getDate(),
                    utcDate.getHours(),
                    utcDate.getMinutes()
                 ));
                return (
                    <Col className="ml-auto mr-auto" md="6" key={index}>
                        <Card className="card-register">
                            <div className="card-avatar">
                                <CardTitle tag="h4">{adData.name}</CardTitle>
                            </div>
                            <CardBody>
                                {renderThumbnail(adData)}

                                <div className="homestatus">{renderStatus(adData.status)}</div>
                            </CardBody>
                            <CardFooter>
                                <div className="homecardfooter">
                                    <div className="homecardfooterleft">
                                        <div>
                                            {myLocalDate.toLocaleString("en-IN")}
                                        </div>
                                    </div>
                                    <div className="homecardfooterright">
                                        <Button
                                            className="btn-round"
                                            color="primary"
                                            onClick={() => navigateToAddetails(adData.adid, adData.status)}
                                        >
                                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;View more...&nbsp;</span>
                                        </Button>
                                    </div>


                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                );
            });
        } else {
            return (
                <Col className="ml-auto mr-auto" md="6">
                    <Card className="card-register">
                        <div className="card-avatar">
                            <CardTitle tag="h4"></CardTitle>
                        </div>
                        <CardBody>
                            <div>No ad campaigns started yet</div>
                        </CardBody>
                    </Card>
                </Col>
            );
        }
    }

    const createAd = () => {
        navigate('/CreateAd', { replace: true });
    };

    const navigateToAddetails = (Adid, Status) => {
        if (Status == "pending payment" || Status == "active" || Status == "pending approval") {
            navigate('/addetailsnoedit', { state: { adid: Adid, status: Status } });
        }
        if (Status == "in active" || Status == "not approved" || Status == "incomplete") {
            navigate('/addetails', { state: { adid: Adid, status: Status } });
        }

    };

    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">

                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <HomeSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            <Row>
                                {!isLoading && (
                                    renderCards()
                                )}
                            </Row>
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}
                    {!isLoading && (<div className="createAdButton">
                        <Button
                            className="btn-round btn-createad"
                            color="primary"
                            onClick={() => createAd()}
                        >
                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Create Ad&nbsp;</span>
                        </Button>
                    </div>)}
                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default Home;
