import React, { useContext, useState, useEffect, useRef } from "react";
import { json, useNavigate } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";
// core components
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import DemoFooter from "../components/Footers/DemoFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../skeleton-screens/HomeSkeleton";
import ADModal from '../components/ADModal';
import ReCAPTCHA from "react-google-recaptcha"

function LoginPage() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { publicAxios } = useContext(AxiosContext);
    const [username, setUsername] = useState(''),
        onUserNameInput = ({ target: { value } }) => setUsername(value);
    const [password, setPassword] = useState(''),
        onPwdInput = ({ target: { value } }) => setPassword(value);
    const [disablesignin, setDisablesignin] = useState(true);
    const [disablesendotp, setDisablesendotp] = useState(false);
    const [countdown, setCountdown] = useState('');
    const [otpMsg, setOtpMsg] = useState('');
    const [otpButtonTitle, setOtpButtonTitle] = useState('Send Otp');
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const captchaRef = useRef(null);

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };
    const showModal = (msg) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
    };

    const handleModal = () => showModal('');

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const userLogin = async () => {
        try {
            setIsLoading(true);
            const data = {
                data: {
                    user: username,
                    pwd: password,
                    type: 'web'
                }
            };

            const response = await publicAxios.post('/login', data, {
                headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' },
                withCredentials: true
            });

            let result = response.data;

            if (result.data.status == "login successful") {
                let accessToken = result.data.accesstoken;
                auth.setAuthState({
                    accessToken,
                    authenticated: true,
                    roles: result.data.roles
                });
                let obj = {
                    accessToken: accessToken,
                    authenticate: true
                };
                localStorage.setItem('adyantraat', JSON.stringify(obj));
                setIsLoading(false);
                navigate('/home', { replace: true });
            } else {
                setIsLoading(false);
                showModal('Invalid Login');

            }


        } catch (error) {
            setIsLoading(false);
            showModal('Login Failed');

        }

    };

    useEffect(() => {
        renderTimer();
    }, [disablesendotp]);

    const renderTimer = () => {
        if (disablesendotp) {
            var timeleft = 30;
            var downloadTimer = setInterval(() => {
                timeleft--;
                setCountdown("Seconds left: " + timeleft);
                if (timeleft <= 0) {
                    clearInterval(downloadTimer);
                    setDisablesendotp(false);
                    setCountdown("");
                }
            }, 1000);
        }
    }

    const sendOtp = async () => {

        if (username != '') {
            const data = {
                data: username
            };
            try {
                setShowSpinner(true);

                const token = await captchaRef.current.executeAsync();
                captchaRef.current.reset();

                const captchadata = {
                    token: token
                };
                const captchaResponse = await publicAxios.post('/verifycaptcha', captchadata, {
                    headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' }
                });

                let captchaResult = captchaResponse.data;
                if (captchaResult?.data == 'human' || captchaResult?.data == 'no token') {
                    const response = await publicAxios.post('/sendotp', data, {
                        headers: { 'Authorization': 'Basic YWR5YW50cmE6YWR5YW50cmFwd2Qh' }
                    });
                    let result = response.data;
                    if (result?.data?.status) {
                        setDisablesignin(false);
                        setOtpMsg("Otp Sent");
                        setOtpButtonTitle("Resend Otp");
                        setDisablesendotp(true);
                        //renderTimer();
                    } else {
                        setOtpMsg((response.data.error === undefined) ? 'Server error. Please retry' : response.data.error);
                    }
                } else {
                    setOtpMsg("Captcha verification failed");
                }

                setShowSpinner(false);
            } catch (error) {
                setOtpMsg("Otp failed");
                setShowSpinner(false);
            }
        } else {
            setOtpMsg("Otp failed");
        }
    }


    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("login-page");
        return function cleanup() {
            document.body.classList.remove("login-page");
        };
    });

    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth < 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    const renderOtpMsg = () => {
        if (otpMsg == "Otp failed") {
            return (<span style={{ color: '#b33c12', textTransform: 'none' }}>{otpMsg}</span>);
        } else if (otpMsg == "Otp Sent") {
            return (<span style={{ color: '#44c47d', textTransform: 'none' }}>{otpMsg}</span>);
        } else {
            return (<span style={{ color: '#b33c12', textTransform: 'none' }}>{otpMsg}</span>);
        }
    };

    return (
        <>
            <IndexNavbar />
            <div
                className="page-header-other"
                style={{
                    backgroundImage:
                        "url(" + require("../assets/img/adkewl-banner-bkgd-dark.png") + ")",
                }}
                data-parallax={true}
                ref={pageHeader}
            />
            <AnimatePresence>
                <div className="main">
                    {isLoading && (
                        <Container>
                            <HomeSkeleton exit={{ opacity: 0 }} />
                        </Container>
                    )}
                    <div className="homeNavSection text-center">
                        <Container>
                            <Row>
                                <Col className="mx-auto" lg="4" md="6">
                                    {!isLoading && (
                                        <Card className="card-register">
                                            <h3 className="title mx-auto">Login</h3>
                                            {renderSpinner()}
                                            <Form className="register-form">
                                                <InputGroup className="form-group-with-border">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="nc-icon nc-email-85" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder="Enter Phone number or Email"
                                                        type="text"
                                                        value={username}
                                                        onChange={onUserNameInput}
                                                    />
                                                </InputGroup>
                                                <div className="loginOtpContainer">
                                                    <div className="loginOtpLeft">
                                                        {renderOtpMsg()}
                                                    </div>
                                                    <div className="loginOtpRight">
                                                        <Button
                                                            className="btn-round"
                                                            color="primary"
                                                            type="button"
                                                            disabled={disablesendotp}
                                                            onClick={() => sendOtp()}
                                                        >
                                                            <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;{otpButtonTitle}&nbsp;</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <label><span style={{ color: "#767676", fontWeight: '500', fontSize: 'small' }}> {countdown} </span></label>
                                                <InputGroup className="form-group-with-border">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="nc-icon nc-key-25" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder="Enter 4 digit Pin + OTP"
                                                        type="password"
                                                        value={password}
                                                        onChange={onPwdInput}
                                                    />
                                                </InputGroup>
                                                <div className="btnright">
                                                    <Button
                                                        className="btn-round"
                                                        color="primary"
                                                        type="button"
                                                        disabled={disablesignin}
                                                        onClick={() => userLogin()}
                                                    >
                                                        <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Sign In&nbsp;</span>
                                                    </Button>
                                                </div>
                                                <ReCAPTCHA
                                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                                    ref={captchaRef}
                                                    size="invisible"
                                                />
                                            </Form>
                                            <div className="signup">
                                                <span style={{ marginTop: '35px', color: '#0b1011' }}>Don't have an account? </span>
                                                <a href="/signup">&nbsp; Sign Up</a>
                                            </div>
                                            <div className="signup">
                                                <span style={{ marginTop: '5px', color: '#0b1011' }}>Forgot pin? </span>
                                                <a href="/resetpin">&nbsp; Reset Pin</a>
                                            </div>
                                        </Card>
                                    )}
                                </Col>
                            </Row>

                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />

                </div>
            </AnimatePresence>
            <DemoFooter />

        </>
    );
}

export default LoginPage;
