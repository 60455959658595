import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Spinner,
    Modal
} from "reactstrap";

// core components
import LoggedinHeader from "../components/Headers/LoggedinHeader.js";
import LoggedinNavbar from "../components/Navbars/LoggedinNavbar.js";
import LoggedinFooter from "../components/Footers/LoggedinFooter.js";
import { AuthContext } from '../AuthContext';
import { AxiosContext } from '../AxiosContext';
import { AnimatePresence, motion } from "framer-motion";
import HomeSkeleton from "../skeleton-screens/HomeSkeleton";
import ADModal from '../components/ADModal';
import { Formik } from 'formik';
import * as yup from 'yup';
import Adstatus from '../components/Adstatus';

function Help() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { authAxios } = useContext(AxiosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalButtonStatus, setModalButtonStatus] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("help-page");
        return function cleanup() {
            document.body.classList.remove("help-page");
        };
    });

    const renderSpinner = () => {
        if (showSpinner) {
            return (
                <Modal isOpen={showSpinner} centered={true} className="spinnerModal">
                    <div className="modal-body">
                        <Spinner color="primary">
                            Loading...
                        </Spinner>
                    </div>
                </Modal>

            );
        }
    };

    const signUpValidationSchema = yup.object().shape({
        name: yup
            .string()
            .matches(/\w.+/, 'Enter full name')
            .min(3, ({ min }) => `Name must be at least ${min} characters`)
            .required('Full name is required'),
        msg: yup
            .string()
            .required('Please enter message'),
    });


    const showModal = (msg, status) => {
        setModalMsg(msg);
        setIsModalVisible(!isModalVisible);
        setModalButtonStatus(status);
    };

    const handleModal = () => {
        if (modalButtonStatus == "success") {
            showModal('','');
            navigate('/home', { replace: true });
        } else {
            showModal('','');
        }
    }

    const renderOkButton = () => {
        return (
            <Button
                className="btn-round"
                color="primary"
                type="button"
                onClick={() => handleModal()}
            >
                <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;&nbsp; ok &nbsp;&nbsp;</span>
            </Button>
        );
    };

    const handleSubmit = async (values) => {
        try {
            if (values.name && values.msg) {
                
                setShowSpinner(true);
                let url = '/sendenquiryhelp';
                let data = {
                    data: {
                        name: values.name,
                        msg: values.msg
                    }
                };
                const response = await authAxios.post(url, data);
                let result = response.data;
                setShowSpinner(false);
                if (result.data) {
                    if (result.data.status == 'success') {
                        showModal("Enquiry sent successfully", 'success');
                    } else {
                        showModal("Failed to send the enquiry. Please retry", null);
                    }
                } else {
                    showModal("Failed to send the enquiry. Please retry", null);
                }
                
            } else {
                showModal("Please check the input fields", null);
            }

        } catch (error) {
            showModal('Server error. Please retry', null);
            setShowSpinner(false);
        }
    };



    return (
        <>
            <LoggedinHeader />
            <LoggedinNavbar />
            <AnimatePresence>
                <div className="main">

                    <div className="text-center">
                        {isLoading && (
                            <Container>
                                <HomeSkeleton exit={{ opacity: 0 }} />
                            </Container>
                        )}
                        <Container>
                            <Row>
                                {!isLoading && (
                                    <Col className="ml-auto mr-auto">
                                        <Formik
                                            validationSchema={signUpValidationSchema}
                                            initialValues={{
                                                name: '',
                                                msg: ''
                                            }}
                                            onSubmit={(values) => { handleSubmit(values) }}
                                        >
                                            {({
                                                handleChange,
                                                handleSubmit,
                                                handleBlur,
                                                isValid,
                                                setFieldValue,
                                                setFieldTouched,
                                                values,
                                                touched,
                                                errors
                                            }) => (
                                                <>
                                                    <Card className="card-register" style={{ minHeight: "unset" }}>
                                                        <div>
                                                            <h4 className="title mx-auto">Contact Us!</h4>
                                                        </div>

                                                        <Form className="register-form">
                                                            <InputGroup className="form-group-with-border">
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="nc-icon nc-single-02" />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    id="name"
                                                                    placeholder="Full Name"
                                                                    type="text"
                                                                    value={values.name}
                                                                    onChange={handleChange('name')}
                                                                    onBlur={handleBlur('name')}
                                                                />
                                                            </InputGroup>
                                                            {errors.name && touched.name && (
                                                                <div className="input-feedback">{errors.name}</div>
                                                            )}

                                                            <InputGroup className="form-group-with-border">
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="nc-icon nc-email-85" />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    id="msg"
                                                                    placeholder="Your enquiry here..."
                                                                    type="textarea"
                                                                    rows="4"
                                                                    value={values.msg}
                                                                    onChange={handleChange('msg')}
                                                                    onBlur={handleBlur('msg')}
                                                                />
                                                            </InputGroup>
                                                            {errors.msg && touched.msg && (
                                                                <div className="input-feedback">{errors.msg}</div>
                                                            )}

                                                            <div className="btncenter">
                                                                <Button
                                                                    className="btn-round"
                                                                    color="primary"
                                                                    onClick={handleSubmit}
                                                                >
                                                                    <span style={{ color: '#0b1011', textTransform: 'none' }}>&nbsp;Send Message&nbsp;</span>
                                                                </Button>
                                                            </div>

                                                        </Form>


                                                    </Card>
                                                </>
                                            )}
                                        </Formik>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </div>
                    <ADModal
                        isVisible={isModalVisible}
                        msg={modalMsg}
                        children={renderOkButton()}
                    />
                    {renderSpinner()}
                </div>
            </AnimatePresence>
            <LoggedinFooter />

        </>
    );
}

export default Help;
