import { useCallback, useContext, useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { jwtDecode } from 'jwt-decode' 
import { AuthContext } from './AuthContext';
import LoginPage from "./views/LoginPage.js";
import Signup from "./views/Signup.js";
import Resetpin from "./views/Resetpin.js";
import Home from "./views/Home.js";
import CreateAd from "./views/CreateAd.js";
import Addetails from "./views/Addetails.js";
import Index from "./views/Index.js";
import Approval from "./views/Approval.js";
import AddetailsNoEdit from "./views/AddetailsNoEdit.js";
import Paymentcf from "./views/Paymentcf.js";
import MyCart from "./views/MyCart.js";
import Account from "./views/Account.js";
import VcEditor from "./views/VcEditor.js";
import Admin from "./views/admin/Admin.js";
import Owner from "./views/admin/Owner.js";
import AdminAd from "./views/admin/AdminAd.js";
import OwnerLocAd from "./views/admin/OwnerLocAd.js";
import AdminDesign from "./views/admin/AdminDesign.js";
import AdminUnblockvc from "./views/admin/AdminUnblockvc.js";
import Help from "./views/Help.js";

export default function App() {
  const authContext = useContext(AuthContext);

  const loadJWT = useCallback(async () => {
    try {
      const value = localStorage.getItem("adyantraat");
      const jwt = JSON.parse(value);
      const accessTokenDecoded = jwtDecode(jwt.accessToken);
      
      authContext.setAuthState({
        ...authContext.authState,
        accessToken: jwt.accessToken || null,
        authenticated: jwt.accessToken !== null,
        roles: accessTokenDecoded.roles
      });
    } catch (error) {
      authContext.setAuthState({
        accessToken: null,
        authenticated: false,
        roles: []
      });
    }
  }, []);

  useEffect(() => {
    loadJWT();
  }, [loadJWT]);


  
  return (
    <Routes>
      <Route path="/index" element={<Index />} />
      <Route path="*" element={<Index />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/resetpin" element={<Resetpin />} />
      <Route path="/home" element={authContext?.authState?.authenticated === true ? <Home /> : <Index />} />
      <Route path="/CreateAd" element={authContext?.authState?.authenticated === true ? <CreateAd /> : <Index />} />
      <Route path="/addetails" element={authContext?.authState?.authenticated === true ? <Addetails /> : <Index />} />
      <Route path="/approval" element={authContext?.authState?.authenticated === true ? <Approval /> : <Index />} />
      <Route path="/addetailsnoedit" element={authContext?.authState?.authenticated === true ? <AddetailsNoEdit /> : <Index />} />
      <Route path="/mycart" element={authContext?.authState?.authenticated === true ? <MyCart /> : <Index />} />
      <Route path="/paymentcf" element={authContext?.authState?.authenticated === true ? <Paymentcf /> : <Index />} />
      <Route path="/account" element={authContext?.authState?.authenticated === true ? <Account /> : <Index />} />
      <Route path="/vceditor" element={authContext?.authState?.authenticated === true ? <VcEditor /> : <Index />} />
      <Route path="/help" element={authContext?.authState?.authenticated === true ? <Help /> : <Index />} />
      <Route 
        path="/adminad" 
        element={((authContext?.authState?.authenticated === true) && (authContext?.authState?.roles) &&
        (authContext?.authState?.roles.includes("ADYANTRA_SUPER_ADMIN"))) ?
        <AdminAd /> : 
        <Index />} 
      />
      <Route 
        path="/admin" 
        element={((authContext?.authState?.authenticated === true) && (authContext?.authState?.roles) &&
        (authContext?.authState?.roles.includes("ADYANTRA_SUPER_ADMIN"))) ?
        <Admin /> : 
        <Index />} 
      />
      <Route 
        path="/owner" 
        element={((authContext?.authState?.authenticated === true) && (authContext?.authState?.roles) &&
        (authContext?.authState?.roles.includes("ADYANTRA_SUPER_ADMIN") || authContext?.authState?.roles.includes("ADYANTRA_FRANCHISE_ADMIN"))) ?
        <Owner /> : 
        <Index />} 
      />
      <Route 
        path="/ownerlocad" 
        element={((authContext?.authState?.authenticated === true) && (authContext?.authState?.roles) &&
        (authContext?.authState?.roles.includes("ADYANTRA_SUPER_ADMIN"))) ?
        <OwnerLocAd /> : 
        <Index />} 
      />
      <Route 
        path="/admindesign" 
        element={((authContext?.authState?.authenticated === true) && (authContext?.authState?.roles) &&
        (authContext?.authState?.roles.includes("ADYANTRA_SUPER_ADMIN"))) ?
        <AdminDesign /> : 
        <Index />} 
      />
      <Route 
        path="/adminunblockvc" 
        element={((authContext?.authState?.authenticated === true) && (authContext?.authState?.roles) &&
        (authContext?.authState?.roles.includes("ADYANTRA_SUPER_ADMIN"))) ?
        <AdminUnblockvc /> : 
        <Index />} 
      />
    </Routes>
  );
}
